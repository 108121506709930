import { AfterContentChecked, Component } from '@angular/core';
import { CommonService } from './provider/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterContentChecked {

  ngAfterContentChecked() {
    console.clear()
  }
  
  title = 'site-development-admin';
  constructor(public service : CommonService){

  }
}
