import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customPipe'
})
export class CustomPipePipe implements PipeTransform {

  transform(value) {
    if((value?.length)){
      value = value.replaceAll("_", " ")
    return value;
    }
    else{
      return value
    }
  }

}
