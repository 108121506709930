import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-add-faq',
  templateUrl: './add-faq.component.html',
  styleUrls: ['./add-faq.component.scss']
})
export class AddFaqComponent implements OnInit {
  addForm: FormGroup;
  constructor(private router: Router , public mainService:CommonService) { }

  ngOnInit(): void {
    this.addForm = new FormGroup({
      "question": new FormControl('', Validators.required),
      "answer": new FormControl('', Validators.required),
    });
  }

  //--------- add faq functionality-----//
  addFaq(){
    let url="api/v1/static/Faq"
  const data = {
    question: this.addForm.value.question,
    answer: this.addForm.value.answer
  }
  this.mainService.showSpinner();
  this.mainService.postApi(url, data, 1).subscribe((res: any) => {
    console.log("add faq response ==>", res)
    if (res['statusCode'] == 200) {
      this.mainService.hideSpinner();
      this.mainService.successToast(res['responseMessage']);
      this.router.navigate(['/faq-list'])

    } else {
      this.mainService.hideSpinner();
      this.mainService.errorToast(res['responseMessage'])
    }
  })
}
  }

