<main class="middle-content">
    <!----Page Title Start--->
    <div *ngIf="activeTab=='Applicant'" class="page_title_block">
        <h1 class="page_title" style="color:black">Job Management </h1>
    </div>
    <div *ngIf="activeTab=='3rd_Party_Contractor'" class="page_title_block">
        <h1 class="page_title" style="color:black">Job Management </h1>
    </div>
    <div *ngIf="activeTab=='All_job'" class="page_title_block">
        <h1 class="page_title" style="color:black">Job Management </h1>
    </div>
    <!----Page Title End--->

    <div class="row tab-container">
        <div class="col-md-3 change-content-tab" (click)="changeTab('All_job')" [ngClass]="{'success': activeTab == 'All_job'}">
            <span>All Jobs</span>
        </div>
        <div class="col-md-3 change-content-tab" (click)="changeTab('Applicant')" [ngClass]="{'success': activeTab == 'Applicant'}">
            <span>Applicants</span>
        </div>
        <div class="col-md-3 change-content-tab" (click)="changeTab('3rd_Party_Contractor')" [ngClass]="{'success': activeTab == '3rd_Party_Contractor' }">
            <span>Sub-Contractor</span>
        </div>

    </div>


    <div *ngIf="activeTab=='All_job'" class="content-section" style="margin-bottom: 50px;">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <form [formGroup]="searchForm" (change)="changeStat()">

                        <div class="tab-pane1">

                            <div class="sec_head_new sec_head_new_after">
                                <div class="row align-items-center">
                                    <div class="col-md-3 col-lg-3 pr-0">
                                        <div class="filter_search mb20 width100">

                                            <span class="d-flex align-items-center bold">Search:</span>
                                            <div class="input-group filter_search_group">
                                                <input type="text" placeholder="Search by city" class="form-control" formControlName="city" (keypress)="service.restrictSpace($event)" style="height: 37px;">

                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-2 col-md-4 pr-0">
                                        <div class="filter_search mb20 width100">
                                            <span class="d-flex align-items-center bold">From Date:</span>
                                            <mat-form-field appearance="outline">
                                                <input matInput [matDatepicker]="picker" placeholder="mm/dd/yyyy" autocomplete="off" readonly formControlName="fromDate" [max]="service.today()">
                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>

                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-4 pr-0">
                                        <div class="filter_search mb20 width100">
                                            <span class="d-flex align-items-center bold">To Date:</span>
                                            <mat-form-field appearance="outline">
                                                <input matInput [matDatepicker]="picker1" placeholder="mm/dd/yyyy" formControlName="toDate" autocomplete="off" [disabled]="!searchForm.value.fromDate" [min]="searchForm.value.fromDate" readonly>
                                                <mat-datepicker-toggle matSuffix [for]="picker1">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker1></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>

                                <div class="text-right">
                                    <button type="submit" (click)="searchAllJob()" class="btn  btn-button mr-2">Search</button>
                                    <button type="submit" (click)="reset()" class="btn  btn-button mr-2">Reset</button>
                                    <button type="submit" (click)="exportAsXLSX()" class="btn  btn-button mr-2">Export
                                        Excel</button>
                                    <button type="button" class="btn btn-button" routerLink="/reported-job">Reported Job
                                    </button>
                                </div>

                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="trade table">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th id="">S.No.</th>
                                        <th id="">Job Name</th>
                                        <th id="">Account Type</th>
                                        <th id="">City</th>
                                        <th id="">Number of Applicant</th>
                                        <th id="">Created Date</th>
                                        <th id="">Job Status</th>
                                        <th id="">Action</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    <tr *ngFor="let data of JobList| paginate: { itemsPerPage:itemsPerPage, currentPage: currentPage, totalItems:total} ;let i = index">
                                        <td>{{itemsPerPage * (currentPage - 1) + i+1}}</td>
                                        <td style="white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        max-width: 30em;">{{data?.jobType?.categoryName||'--'}}</td>
                                        <td>{{(data?.accountType | split)||'--'}}</td>
                                        <td>{{data?.city||'--'}}</td>
                                        <td>{{data?.noOfApplicants}}</td>
                                        <td>{{data?.createdAt| date : 'MM/dd/yyyy, hh:mm a'||'--'}}</td>
                                        <td>{{data?.jobStatus||'--'}}</td>

                                        <td class="action_td_btn3" style="cursor: pointer">

                                            <a>
                                                <em class="fa fa-eye" (click)="viewAllJob(data?.id,data?.accountType)" title="View">
                                                </em>
                                            </a>
                                            <a *ngIf="data?.status == 'ACTIVE'" data-toggle="modal" data-target="#BlockModal" (click)="getJobId(data?.id,'BLOCK')" title="Active">
                                                <em class="fa fa-ban" aria-hidden="true" style="color: green;"></em>
                                            </a>
                                            <a *ngIf="data?.status == 'BLOCK'" data-toggle="modal" data-target="#BlockModal" (click)="getJobId(data?.id,'ACTIVE')" title="Block">
                                                <em class="fa fa-ban" aria-hidden="true" style="color: red;"></em>
                                            </a>
                                            <a data-target="#deleteModal" data-toggle="modal">
                                                <em class="fa fa-trash" (click)="deleteModal(data?.id)" title="Delete"></em></a>

                                        </td>

                                    </tr>
                                    <tr *ngIf="total==0">
                                        <td colspan="11" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5>No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="custom-pagination mt20 text-center" style="float: right;" *ngIf="total>itemsPerPage">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>

                    </form>
                </div>
            </div>

        </div>
    </div>


    <!------------Applicant start-------------->
    <div *ngIf="activeTab=='Applicant'" class="content-section" style="margin-bottom: 50px;">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <form [formGroup]="searchForm" (change)="changeStat()">

                        <div class="tab-pane1">

                            <div class="sec_head_new sec_head_new_after">
                                <div class="row align-items-center">
                                    <div class="col-md-3 col-lg-3 pr-0">
                                        <div class="filter_search mb20 width100">

                                            <span class="d-flex align-items-center bold">Search:</span>
                                            <div class="input-group filter_search_group">
                                                <input type="text" placeholder="Search by city" class="form-control" formControlName="city" (keypress)="service.restrictSpace($event)" style="height: 37px;">

                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-2 col-md-4 pr-0">
                                        <div class="filter_search mb20 width100">
                                            <span class="d-flex align-items-center bold">From Date:</span>
                                            <mat-form-field appearance="outline">
                                                <input matInput [matDatepicker]="picker" placeholder="mm/dd/yyyy" autocomplete="off" readonly formControlName="fromDate" [max]="service.today()">
                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>

                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-4 pr-0">
                                        <div class="filter_search mb20 width100">
                                            <span class="d-flex align-items-center bold">To Date:</span>
                                            <mat-form-field appearance="outline">
                                                <input matInput [matDatepicker]="picker1" placeholder="mm/dd/yyyy" formControlName="toDate" autocomplete="off" [disabled]="!searchForm.value.fromDate" [min]="searchForm.value.fromDate" readonly>
                                                <mat-datepicker-toggle matSuffix [for]="picker1">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker1></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>

                                <div class="text-right">
                                    <button type="submit" (click)="search()" class="btn  btn-button mr-2">Search</button>
                                    <button type="submit" (click)="reset()" class="btn  btn-button mr-2">Reset</button>
                                    <button type="submit" (click)="exportAsXLSX()" class="btn  btn-button mr-2">Export
                                        Excel</button>
                                    <button type="button" class="btn btn-button" routerLink="/reported-job">Reported Job
                                    </button>
                                </div>

                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="trade table">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th id="">S.No.</th>
                                        <th id="">Job Name</th>
                                        <th id="">Account Type</th>
                                        <th id="">City</th>
                                        <th id="">Number of Applicant</th>
                                        <th id="">Created Date</th>
                                        <th id="">Job Status</th>
                                        <th id="">Action</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    <tr *ngFor="let data of JobList| paginate: { itemsPerPage:itemsPerPage, currentPage: currentPage, totalItems:total} ;let i = index">
                                        <td>{{itemsPerPage * (currentPage - 1) + i+1}}</td>
                                        <td style="white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        max-width: 30em;">{{data?.jobType?.categoryName||'--'}}</td>
                                        <td>{{data?.accountType||'--'}}</td>
                                        <td>{{data?.city||'--'}}</td>
                                        <td>{{data?.noOfApplicants}}</td>
                                        <td>{{data?.createdAt| date : 'MM/dd/yyyy, hh:mm a'||'--'}}</td>
                                        <td>{{data?.jobStatus||'--'}}</td>

                                        <td class="action_td_btn3" style="cursor: pointer">

                                            <a>
                                                <em class="fa fa-eye" (click)="viewApplicant(data?.id)" title="View">
                                                </em>
                                            </a>
                                            <a *ngIf="data?.status == 'ACTIVE'" data-toggle="modal" data-target="#BlockModal" (click)="getJobId(data?.id,'BLOCK')" title="Active">
                                                <em class="fa fa-ban" aria-hidden="true" style="color: green;"></em>
                                            </a>
                                            <a *ngIf="data?.status == 'BLOCK'" data-toggle="modal" data-target="#BlockModal" (click)="getJobId(data?.id,'ACTIVE')" title="Block">
                                                <em class="fa fa-ban" aria-hidden="true" style="color: red;"></em>
                                            </a>
                                            <a data-target="#deleteModal" data-toggle="modal">
                                                <em class="fa fa-trash" (click)="deleteModal(data?.id)" title="Delete"></em></a>

                                        </td>

                                    </tr>
                                    <tr *ngIf="total==0">
                                        <td colspan="11" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5>No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="custom-pagination mt20 text-center" style="float: right;" *ngIf="total>itemsPerPage">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>

                    </form>
                </div>
            </div>

        </div>
    </div>
    <!-----------individual End------------->

    <!--------------3rd_Party_Contractor start------------->
    <div *ngIf="activeTab=='3rd_Party_Contractor'" class="content-section" style="margin-bottom: 50px;">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <form [formGroup]="searchForm" (change)="changeStat()">

                        <div class="tab-pane1">

                            <div class="sec_head_new sec_head_new_after">
                                <div class="row align-items-center">
                                    <div class="col-md-3 col-lg-3 pr-0">
                                        <div class="filter_search mb20 width100">

                                            <span class="d-flex align-items-center bold">Search:</span>
                                            <div class="input-group filter_search_group">
                                                <input type="text" placeholder="Search by city" class="form-control" formControlName="city" (keypress)="service.restrictSpace($event)" style="height: 37px;">

                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-2 col-md-4 pr-0">
                                        <div class="filter_search mb20 width100">
                                            <span class="d-flex align-items-center bold">From Date:</span>
                                            <mat-form-field appearance="outline">
                                                <input matInput [matDatepicker]="picker" placeholder="mm/dd/yyyy" autocomplete="off" readonly formControlName="fromDate" [max]="service.today()">
                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>

                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-4 pr-0">
                                        <div class="filter_search mb20 width100">
                                            <span class="d-flex align-items-center bold">To Date:</span>
                                            <mat-form-field appearance="outline">
                                                <input matInput [matDatepicker]="picker1" placeholder="mm/dd/yyyy" formControlName="toDate" autocomplete="off" [disabled]="!searchForm.value.fromDate" [min]="searchForm.value.fromDate" readonly>
                                                <mat-datepicker-toggle matSuffix [for]="picker1">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker1></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                </div>

                                <div class="text-right">
                                    <button type="submit" (click)="search()" class="btn  btn-button mr-2">Search</button>
                                    <button type="submit" (click)="reset()" class="btn  btn-button mr-2">Reset</button>
                                    <button type="submit" (click)="exportAsXLSX()" class="btn  btn-button mr-2">Export
                                        Excel</button>
                                    <button type="button" class="btn btn-button" routerLink="/reported-job">Reported Job
                                    </button>
                                </div>


                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="trade table">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th id="">S.No.</th>
                                        <th id="">Job Name</th>
                                        <th id="">Account Type</th>
                                        <th id="">City</th>
                                        <th id="">Number of Applicant</th>
                                        <th id="">Created Date</th>
                                        <th id="">Job Status</th>
                                        <th id="">Action</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    <tr *ngFor="let data of JobList| paginate: { itemsPerPage:itemsPerPage, currentPage: currentPage,  totalItems:total} ;let i = index">
                                        <td>{{itemsPerPage * (currentPage - 1) + i+1}}</td>
                                        <td style="white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        max-width: 30em;">{{data?.jobType?.categoryName||'--'}}</td>
                                        <td>{{(data?.accountType | split)||'--'}}</td>
                                        <td>{{data?.city||'--'}}</td>
                                        <td>{{data?.noOfApplicants}}</td>
                                        <td>{{data?.createdAt| date : 'MM/dd/yyyy, hh:mm a'||'--'}}</td>
                                        <td>{{data?.jobStatus||'--'}}</td>
                                        <td class="action_td_btn3" style="cursor: pointer">

                                            <a><em class="fa fa-eye" (click)="viewThirdParty(data?.id)"
                                                    title="View"></em>
                                            </a>
                                            <a *ngIf="data?.status == 'ACTIVE'" data-toggle="modal" data-target="#BlockModal" (click)="getJobId(data?.id,'BLOCK')" title="Active">
                                                <em class="fa fa-ban" aria-hidden="true" style="color: green;"></em>
                                            </a>
                                            <a *ngIf="data?.status == 'BLOCK'" data-toggle="modal" data-target="#BlockModal" (click)="getJobId(data?.id,'ACTIVE')" title="Block">
                                                <em class="fa fa-ban" aria-hidden="true" style="color: red;"></em>
                                            </a>
                                            <a data-target="#deleteModal" data-toggle="modal">
                                                <em class="fa fa-trash" (click)="deleteModal(data?.id)" title="Delete"></em></a>
                                        </td>

                                    </tr>
                                    <tr *ngIf="total ==0">
                                        <td colspan="11" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5>No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="custom-pagination mt20 text-center" style="float: right;" *ngIf="total>itemsPerPage">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>

                    </form>
                </div>
            </div>

        </div>
    </div>
</main>
<!--------3rd_Party_Contractor End--------->

<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h5 class="modal-title d-inline-block text-center">Delete Job </h5>
                        <hr>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p *ngIf="activeTab=='All_job'" style="font-size: 20px;">Are you sure you want to delete this Job?</p>
                                <p *ngIf="activeTab=='Applicant'" style="font-size: 20px;">Are you sure you want to delete this applicant?</p>
                                <p *ngIf="activeTab=='3rd_Party_Contractor'" style="font-size: 20px;">Are you sure you want to delete this Sub-Contractor?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2" (click)="deleteJob()" data-dismiss="modal">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->

<!--- block_modal Start-->
<div class="modal fade global-modal reset-modal" id="BlockModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">

                    <h5 class="modal-title d-inline-block" style="text-transform: capitalize;">{{status | lowercase}}
                    </h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p *ngIf="activeTab=='All_job'" style="font-size: 20px;">Are you sure you want to {{status | lowercase}} this Job?</p>
                                <p *ngIf="activeTab=='Applicant'">Are you sure you want to {{status | lowercase}} this individual?</p>

                                <p *ngIf="activeTab=='3rd_Party_Contractor'">Are you sure you want to {{status | lowercase}} this Sub-Contractor?</p>

                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-button" (click)="blockUnblockJob()" data-dismiss="modal">Yes</button>
                                    <button type="button" class="btn btn-button  ml-2" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!----Block_modal End-->