<main class="middle-content">
 <!----page title start ---->   
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">Add Faq's</h1>
    </div>
 <!----page title end ---->   

 <!----table responsive start ---->   
      <div class="content-section">
        <div class="order-view  setting-page">
          <form [formGroup]="addForm">
          <div class="mb40">
            <div class=" input-style mb20 ">
              <div class="form-group row  view-label-align">
                <label class="col-md-3 bold">Question
                  </label>
                  <span  class="col-md-1">:</span>
                  <div class="col-md-7">
                      <input type="text" class="form-control" maxlength="100"
                      formControlName="question">
                  <div *ngIf="addForm.get('question').invalid && (addForm.get('question').dirty || addForm.get('question').touched)" class="text-danger">
                      <span *ngIf="addForm.get('question').hasError('required')"> *Please enter question.</span>
                      <span *ngIf="addForm.get('question').hasError('pattern')"> *Please enter valid question.</span>
                  </div>
                </div>
              </div>
              <div class="form-group row align-items-baseline view-label-align">
                <label class="col-md-3 bold">Answer
                  </label><span  class="col-md-1">:</span>
                  <div class="col-md-7">
                   <textarea class="form-control" formControlName="answer" name="" id="" maxlength="350" cols="30" rows="5"></textarea>


                  <div *ngIf="addForm.get('answer').invalid && (addForm.get('answer').dirty || addForm.get('answer').touched)" class="text-danger">

                    <span *ngIf="addForm.get('answer').hasError('required')"> *Please enter answer.</span>
                </div>
                </div>
              </div>
            </div>
            <div style="text-align: center ;margin-top:20px">
              <button class="btn  btn-button" type="button" routerLink="/faq-list">Back</button>
              <button class="btn  btn-button ml-4" type="button" [disabled]="!addForm.valid" (click)="addFaq()">Add</button>
          </div>
            
          </div>
          </form>
        </div>
      </div>
 <!----table responsive end ---->   
  </main>



