import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-reported-job',
  templateUrl: './reported-job.component.html',
  styleUrls: ['./reported-job.component.scss']
})
export class ReportedJobComponent implements OnInit {

  total:any
  itemsPerPage: number = 10;
  currentPage: number = 1
  totalItems: number;
  id: any;
  searchForm:FormGroup
  status: any;
  flip: boolean=false;
  isSearched: boolean=false;
  city: string;
  resetPage : boolean= false
  reportedjoblist: any;
  data: any;

  constructor(private router: Router, public service: CommonService,private datepipe : DatePipe) { }

  ngOnInit() {
    this. searchFormValidation()
   this.getReportedJobList()
  }
 
  searchFormValidation() {
    this.searchForm = new FormGroup({
      text:new FormControl(''),
      email: new FormControl(''),
      fromDate: new FormControl(""),
      toDate: new FormControl(""),
    });
  }
  
//-----------------Get List Reported Job ---------------//
  getReportedJobList(){
  let url="api/v1/admin/reportedList?page="+this.currentPage+"&limit="+this.itemsPerPage
  this.service.showSpinner();
  this.service.getApi(url,1).subscribe((res) => {
    this.reportedjoblist = res['result']['rows']
    
    this.service.hideSpinner();
    if (res['statusCode'] == 200) {
      this.reportedjoblist = res['result']['rows']
      this.total = res['result']['count']
      this.service.successToast(res["responseMessage"])
    } else {
     this.service.hideSpinner()
     this.service.errorToast(res["responseMessage"])
    }
  }, err => {
    this.reportedjoblist = []
    this.total = 0
    this.service.hideSpinner();
  })
 }

 
//-----------------Search Reported Job ---------------//

search() { 
  if(!this.searchForm.value.email && !this.searchForm.value.fromDate && !this.searchForm.value.toDate && !this.searchForm.value.text){
    return
  }
  if(this.currentPage != 1 && !this.isSearched){
    this.currentPage = 1
  }
  this.isSearched = true
  this.flip =  true  
  
  const fromDate = new Date(this.searchForm.value.fromDate)
  const toDate = new Date(this.searchForm.value.toDate)
  let url=""
  if(this.searchForm.value.email && !this.searchForm.value.fromDate && !this.searchForm.value.toDate && !this.searchForm.value.text){
   url="api/v1/admin/reportedList?email="+this.searchForm.value.email+"&page="+this.currentPage+"&limit="+this.itemsPerPage
   
  }
  else if(!this.searchForm.value.email && this.searchForm.value.fromDate && !this.searchForm.value.toDate && this.searchForm.value.text){
    url = "api/v1/admin/reportedList?page="+this.currentPage+"&pageSize="+this.itemsPerPage+"&startDate="+fromDate+"&status="+this.searchForm.value.text
   
  }
  else if(!this.searchForm.value.email && this.searchForm.value.fromDate && this.searchForm.value.toDate && this.searchForm.value.text){
    url = "api/v1/admin/reportedList?page="+this.currentPage+"&pageSize="+this.itemsPerPage+"&startDate="+fromDate+"&endDate="+toDate+"&status="+this.searchForm.value.text
   
  }
  else if(!this.searchForm.value.email && !this.searchForm.value.fromDate && !this.searchForm.value.toDate && this.searchForm.value.text){
    url = "api/v1/admin/reportedList?page="+this.currentPage+"&pageSize="+this.itemsPerPage+"&status="+this.searchForm.value.text
   
  }
  else if(this.searchForm.value.email && this.searchForm.value.fromDate && !this.searchForm.value.toDate && this.searchForm.value.text){
    url = "api/v1/admin/reportedList?email="+this.searchForm.value.email+"&page="+this.currentPage+"&pageSize="+this.itemsPerPage+"&startDate="+fromDate+"&status="+this.searchForm.value.text
   
  } 
  else if(this.searchForm.value.email && !this.searchForm.value.fromDate && !this.searchForm.value.toDate && this.searchForm.value.text){
    url = "api/v1/admin/reportedList?email="+this.searchForm.value.email+"&page="+this.currentPage+"&pageSize="+this.itemsPerPage+"&status="+this.searchForm.value.text
   
  } 
  else{ 
    url = "api/v1/admin/reportedList?email="+this.searchForm.value.email+"&page="+this.currentPage+"&pageSize="+this.itemsPerPage+"&startDate="+fromDate+"&endDate="+toDate+"&status="+this.searchForm.value.text
  }
 this.service.showSpinner();
  this.service.getApi(url, 1).subscribe(
    (res) => {
      if (res['statusCode'] == 200) {
        this.reportedjoblist = res['result']['rows']
        this.total = res['result']['count']
        this.service.hideSpinner();
        this.service.successToast(res['responseMessage']);
      } 
      else {
        this.service.hideSpinner(); 
        this.service.errorToast(res['responseMessage']);
      }
    },
    (err) => {
      this.reportedjoblist=[]
      this.total=0
      this.service.hideSpinner();
    }
  );
}

//-----------------Reset Reported Job ---------------//
reset() {
  if(!this.isSearched){
    return
  }
this.flip = false
this.isSearched = false
this.getReportedJobList()
this.searchForm.reset()
this.searchForm.patchValue({
  email:"",
  text : ""
})
}

changeStat(){
  this.isSearched = false
 }

//------------------ Active/Block Reported Job --------------//
getJobId(id, status,data) {
this.id = id;
this.status = status;
this.data=data
}
blockUnblockJob() {
  let url = ""
  if (this.status == "BLOCK") {
    url = "api/v1/admin/blockUnblockReport?id="+this.id
  }else{
    url = "api/v1/admin/blockUnblockReport?id="+this.id
  }
  this.service.showSpinner()
  this.service.putApi(url, {}, 1).subscribe((res) => {
    if (res["statusCode"] == 200) {
      this.getReportedJobList()
      this.service.hideSpinner();
      this.service.successToast(res["responseMessage"]);
    } else {
      this.service.hideSpinner();
      this.service.errorToast(res["responseMessage"]);
    }
  });
  }


pagination(event) {
  this.currentPage = event
  
}

viewReportedJob(id){
  this.router.navigate(['/view-reported-job'], { queryParams: { id : id } })
}
  
//-----------------Export As Excel ---------------//
  exportAsXLSX() {
    let dataArr = [];
    if(this.reportedjoblist.length == 0){
      let obj  = {}
      obj = {
        "S.No.": 0,
        "Job Title":  'N/A',
        "Reported To":  'N/A',
        "Reported By": 'N/A',
        "Reported Date & Time": 'N/A',
        "Status": 'N/A',

      }
      dataArr.push(obj)
      this.service.exportAsExcelFile(dataArr, 'Reported Job Management');
      return
    }
    this.reportedjoblist.forEach((element, ind) => {
      let obj = {};
      obj = {
        "S.No.": ind + 1,
        "Job Title" : element.jobTitle ? element.jobTitle : 'N/A',
        "Reported To": element.toEmail ? element.toEmail : 'N/A',
        "Reported By": element.fromEmail? element.fromEmail: 'N/A',
        "Reported Date & Time": element.createdAt ?this.datepipe.transform(element.createdAt ,'MM/dd/yyyy, hh:mm a')  : 'N/A',
        "Status": element.status ? element.status : 'N/A',

      }
      dataArr.push(obj)
    })

    this.service.exportAsExcelFile(dataArr, 'Reported Job Management');
  }

}
