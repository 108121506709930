import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-view-applicant',
  templateUrl: './view-applicant.component.html',
  styleUrls: ['./view-applicant.component.scss']
})
export class ViewApplicantComponent implements OnInit {

    
 activeTab :any
  id:any
  hirerView: any;
  tab: string;
  constructor(public service:CommonService, private router:Router,private activatedroute : ActivatedRoute) { 
    this.activatedroute.queryParams.subscribe((res) => {
      this.id = res.id;
      console.log(this.id);

    })
  }

  ngOnInit(): void {
    this.getViewHirer()
  }

//------- view individual data of applicant-----//
getViewHirer(){
  let url = "api/v1/admin/viewApplicant?id="+this.id
  this.service.showSpinner()
  this.service.getApi(url,1).subscribe((res)=>{
    if(res['statusCode'] == 200){
      this.hirerView = res['result']
      this.service.hideSpinner()
      this.service.successToast(res['responseMessage'])
    }
    else{
      this.service.hideSpinner()
      this.service.errorToast(res['responseMessage'])
    }
  })
 }

 back(){
  localStorage.setItem("tab","Applicant")
  this.router.navigate(['/company-management'])

}

}