import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
declare var $
@Component({
  selector: 'app-advertisement-list',
  templateUrl: './advertisement-list.component.html',
  styleUrls: ['./advertisement-list.component.scss']
})
export class AdvertisementListComponent implements OnInit {

  advertisementList=[]
  itemsPerPage=10
  currentPage=1
  totalItems:any
  advertisementId: any;
  myid: any;
  total: any;
  id: any;
  status: any;
  isActive : any = []
  constructor( private router: Router, public service: CommonService) { }

  ngOnInit(): void {
    this.getAdvertisementList();
  }

  //-----pagination-----//
  pagination(event) {
    this.currentPage = event   
  }

  //-----get list of advertisement-----//
  getAdvertisementList() {
    let i=0
    let url = "api/v1/admin/getAdvertisementList?page="+this.currentPage+"&limit="+this.itemsPerPage
    this.service.showSpinner()
    this.service.getApi(url,1).subscribe((res)=>{
      if(res["statusCode"] == 200){
        this.advertisementList = res["result"]['rows']
        for (let item of res["result"]['rows']) {
          if (item.status == "ACTIVE") {
            this.isActive[i] = true
          } 
          if (item.status == "BLOCK")
          {
            this.isActive[i] = false
          }
         
         i++
        }
        this.total=res["result"]['count']
        this.service.hideSpinner()
        this.service.successToast(res["responseMessage"])
      }
      else{
        this.service.hideSpinner()
        this.service.errorToast(res["responseMessage"])
      }
    },(err)=>{
      this.advertisementList=[]
      this.total=0
      this.service.hideSpinner()

    })
  }
  editAdvertisement(advertisementId) {
    console.log('id', advertisementId);
    this.router.navigate(['/edit-advertisement'], { queryParams: { advertisementId: advertisementId } })
  }
  viewAdvertisement(advertisementId) {
    console.log('id', advertisementId);
    this.router.navigate(['/view-advertisement'], { queryParams: { advertisementId: advertisementId } })
  }

    //-----delete individual data of advertisement-----//
  openDeleteBlogModal(advertisementId) {
    // $('#deleteBlog').modal('show')
    this.advertisementId = advertisementId
  }
  deleteAdvertisement() {
    let url = "api/v1/admin/deleteAdvertisement?id="+this.advertisementId
    this.service.showSpinner();
    this.service.deleteApi(url, {}, 1).subscribe((res: any) => {
      // $('#deleteBlog').modal('hide');
      if (res["statusCode"] == 200) {
        this.getAdvertisementList()
        this.service.successToast(res["responseMessage"]);
      } else {
        this.service.hideSpinner();
        this.service.errorToast(res["responseMessage"])
      }
    })
  }
  
  //------ Active and block advertisement-----//
  changeBannerStatus(status,id){
    let advertisementStatus = status
    if(status){
      advertisementStatus = "ACTIVE"
      this.getActive(advertisementStatus,id)
    }
    else{
      advertisementStatus = "BLOCK"
      this. getBlock(advertisementStatus,id)
    }
  }
  getActive(advertisementStatus,id){
      let url = "api/v1/admin/blockUnblockAdvertisement?id="+id
      const data = {
        
        "id": id,
          "status" : advertisementStatus,
        
      }
      this.service.putApi(url,data,1).subscribe((res)=>{
        if (res["statusCode"] ==200) {
          this.service.hideSpinner()
          this.service.successToast(res["responseMessage"])
        } else {
          this.service.hideSpinner()
          this.service.errorToast(res["responseMessage"])
        }
      })
    }
    
    getBlock(advertisementStatus,id){
        let url = "api/v1/admin/blockUnblockAdvertisement?id="+id
        const data = {
         
          "id": id,
          "status" : advertisementStatus,
           
        }
        this.service.putApi(url,data,1).subscribe((res)=>{
          if (res["statusCode"]==200) {
            this.service.hideSpinner()
            this.service.successToast(res["responseMessage"])
          } else {
            this.service.hideSpinner()
            this.service.errorToast(res["responseMessage"])
          }
        })
      } 
}
