<div class="login-wrapper">
    <div class="container-common">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <form class="login_box_outer" [formGroup]="forgotPassword" >
                    <div class="login-box max-WT-520">
                        <div class="login-right-block">
                            <div class="text-center">
                                <!-- <img src="/assets/img/ivnlogo.png" class="login-logo2" alt=""> -->
                            </div>
                            <div class="login-heading">
                                <h4 style="color:#064d74">FORGOT PASSWORD?</h4>
                            </div>
                            <div class="login-box-body">
                                <p class="common-paragrph text-center">Enter the account details to reset the password.</p>
                                <div class="form-group">
                                    <input type="email" formControlName="email" class="form-control" placeholder="Email address" required/>
                                    <span class="error">
                                      <p *ngIf="forgotPassword.get('email').hasError('pattern') && forgotPassword.get('email').dirty" class="error"
                                      padding>*Please enter valid email.</p>
                                      </span>
                                </div>
                                <div class="form-group text-center">
                                    <button type="submit"  class="btn btn-login btn-small  width100 font-100 mr-2" (click)="onForgot()" [disabled]="!forgotPassword.valid">SUBMIT</button>
                                    <button type="submit"  class="btn btn-login btn-small  width100 font-100" [routerLink]="['/login']">BACK</button>
                                </div>
                            </div>
                            <a (click)="onResend()" class="text-center mt20 reset-link" >Resend</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
  </div>
  