import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  resetPasswordForm:FormGroup
  oldPasswordType:any="password"
  newPasswordType: any="password"

  constructor(public service:CommonService,private route:Router) { }

  ngOnInit(): void {
    this.resetPasswordForm = new FormGroup({
      oldPassword: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required,Validators.pattern(/^(?=^.{8,32}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*?[#?!@$%^&*-])(?!.*\s).*$/)]),
      confirmPassword: new FormControl('', Validators.required)
    })
  }
  
 // Reset Password Functionality
 resetPasswordFunc() {
  var apireq = {
    'newPassword': this.resetPasswordForm.value.password,
    'oldPassword': this.resetPasswordForm.value.oldPassword
  }
  this.service.showSpinner();
  this.service.putApi('api/v1/admin/adminChangePassword',apireq ,1).subscribe(res => {
    console.log(res);
    this.service.hideSpinner();
    if (res['statusCode'] == 200) {
      this.service.successToast(res['responseMessage']);
      this.service.onLogout()

    }
    else{
      this.service.hideSpinner()
      this.service.errorToast(res['responseMessage'])
    }
  }, err => {
    this.service.hideSpinner();
    if (err['statusCode'] == '401') {
      this.service.errorToast('Unauthorized access.');
    } else {
      this.service.errorToast('Something went wrong.');
    }
  })
}
viewNew(val){
  if(val)
  {
    this.newPasswordType="text"
  }
  else{
    this.newPasswordType="password"
  }
  }
viewOld(val){
  if(val)
  {
    this.oldPasswordType="text"
  }
  else{
    this.oldPasswordType="password"
  }
}

}
