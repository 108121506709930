import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-static-content',
  templateUrl: './static-content.component.html',
  styleUrls: ['./static-content.component.scss']
})
export class StaticContentComponent implements OnInit {
  staticData: any=[];
  pageName: any=[];
  staticList:any
  constructor(private router:Router,public service: CommonService) { }
 
  ngOnInit(): void {
    this.getStaticContentList() 
  }

  //---------view static content data ----------//
viewStaticData(id, type) {
  this.router.navigate(['edit-static-content'], { queryParams: { id: id, type: type } })
}

//---------view static content list ----------//
  getStaticContentList(){
  let url ="api/v1/static/staticContentList"
  this.service.showSpinner()
  this.service.getApi(url , 1).subscribe((res)=>{
    if(res['statusCode'] == 200){
      this.staticData = res['result']['rows']      
      let i = 0
     for (let item of this.staticData) {
      this.pageName[i] = String(item["type"]).split(/(?=[A-Z])/).join(" ")
       i++;
     }
      this.service.hideSpinner()
      this.service.successToast(res['responseMessage'])
    }
     else{
       this.service.hideSpinner()
      this.service.errorToast(res['responseMessage'])
    }

  })
}

// viewSocialNetwork(){
//   let url =`api/v1/admin/viewSocialLink?id`
//   this.service.showSpinner()
//   this.service.getApi(url , 1).subscribe((res)=>{
//     if(res['statusCode'] == 200){
//       this.staticData = res['result']     
//       let i = 0
//      for (let item of this.staticData) {
//       this.pageName[i] = String(item["type"]).split(/(?=[A-Z])/).join(" ")
//        i++;
//      }
//       this.service.hideSpinner()
//       this.service.successToast(res['responseMessage'])
//     }
//      else{
//        this.service.hideSpinner()
//       this.service.errorToast(res['responseMessage'])
//     }

//   })
// }
// socialName : any
// socialLinks : any

navigateToSocialNetwork(){
  this.router.navigate(['/social-network'])
}
}
