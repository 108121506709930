import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
@Component({
  selector: 'app-add-subcategory',
  templateUrl: './add-subcategory.component.html',
  styleUrls: ['./add-subcategory.component.scss']
})
export class AddSubcategoryComponent implements OnInit {

  addForm: FormGroup
  imgSrc : any
  categoryData: any=[];

  
    constructor(private route: Router, public mainservice: CommonService ) { }
  
    ngOnInit() {
      this.addFormvalidation()
      this.getCategoryData()
  
    }
    addFormvalidation() {
      this.addForm = new FormGroup({
        name: new FormControl("", [Validators.required]),
        cat_id:new FormControl("" , [Validators.required]),

      })
    }
  
  
//------ add category -------//
    addNewCategory(){
     let url = "api/v1/admin/addSubcategory"
     const data = {
       "cat_id":this.addForm.value.cat_id,
      "subCatName": this.addForm.value.name
    }
    console.log(data);
    
     this.mainservice.showSpinner()
     this.mainservice.postApi(url,data,1).subscribe((res)=>{
       if(res['statusCode'] == 200){
        this.mainservice.hideSpinner()
        this.mainservice.successToast(res["responseMessage"])
        this.route.navigate(['/subCategory-list'])

       }
       else{
        this.mainservice.hideSpinner()
        this.mainservice.errorToast(res["responseMessage"])
       }
     },(err)=>{
      this.mainservice.hideSpinner()
     })

    }
  
//------ get category list-------//
    getCategoryData(){
      let url = "api/v1/jobs/categoryList"
      this.mainservice.showSpinner()
      this.mainservice.getApi(url,0).subscribe((res)=>{
        if(res['statusCode'] == 200){
          this.categoryData=res['result']
         this.mainservice.hideSpinner()
         this.mainservice.successToast(res["responseMessage"])
        
        }
        else{
         this.mainservice.hideSpinner()
         this.mainservice.errorToast(res["responseMessage"])
        }
      })
 
     }
}