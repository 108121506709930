import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-edit-job',
  templateUrl: './edit-job.component.html',
  styleUrls: ['./edit-job.component.scss']
})
export class EditJobComponent implements OnInit {
  JobList: any;
  id: any;
  myeditForm:FormGroup
  constructor(public service:CommonService, private router:Router,private activated : ActivatedRoute) { 
    this.activated.queryParams.subscribe((res)=>{
      this.id = res.id
      console.log(this.id)
    })
  }

  ngOnInit(): void {
    this.getViewJob()
    this.editJobForm()
    
  }
  editJobForm(){
    this.myeditForm=new FormGroup({
      jobType : new FormControl('',[Validators.required]),
      jobSubType : new FormControl('',[Validators.required]),
      applyType:new FormControl('',[Validators.required]),
      duration:new FormControl('',[Validators.required]),
      durationType:new FormControl('',[Validators.required]),
      equipmentType:new FormControl('',[Validators.required]),
      city:new FormControl('',[Validators.required]),
      paymentType:new FormControl('',[Validators.required]),
      notes:new FormControl('',[Validators.required]),
      companyName:new FormControl('',[Validators.required]),
      email: new FormControl('',[Validators.required,Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/i)]),
      ownerName:new FormControl('',[Validators.required]),
      address:new FormControl('',[Validators.required]),

    })

  }
  //--------------- for individual patch company detail for view ------------------//
getViewJob(){
  let url = "api/v1/admin/viewJob?id="+this.id
  // this.service.showSpinner()
  this.service.getApi(url,1).subscribe((res)=>{
    if(res['statusCode'] == 200){
      this.JobList= res['result']
      this.myeditForm.patchValue({
        jobType:res['result']['city'],
        jobSubType:res['result']['city'],
        applyType:res['result']['applyType'],
        durationType:res['result']['duration'],
        equipmentType:res['result']['equipmentType'],
        notes:res['result']['notes'],
        companyName:res['result']['companyName'],
        email:res['result']['email'],
        ownerName:res['result']['ownerName'],
        address:res['result']['address'],
        city:res['result']['city'],
        paymentType:res['result']['payment'],
        duration:res['result']['duration']
      })
      this.service.hideSpinner()
      this.service.successToast(res['responseMessage'])
    }
    else{
      this.service.hideSpinner()
      this.service.errorToast(res['responseMessage'])
    }
  })
}

updateJob(){
   let url="api/v1/admin/editJob?id="+this.id
  const data = {
    "jobType":this.myeditForm.value.jobType,
    "jobSubType": this.myeditForm.value.jobSubType,
    "applyType": this.myeditForm.value.applyType,
    "duration": this.myeditForm.value.duration,
    "durationType":this.myeditForm.value.durationType,
    "equipmentType": this.myeditForm.value.equipmentType,
    "city": this.myeditForm.value.city,
    "paymentType":this.myeditForm.value.paymentType,
    "notes":this.myeditForm.value.notes,
    "companyName":this.myeditForm.value.companyName,
    "email":this.myeditForm.value.email,
    "ownerName":this.myeditForm.value.ownerName,
    "address": this.myeditForm.value.address
  }

  this.service.putApi(url,data,1).subscribe((res)=>{
    if (res['statusCode'] ==200) {

      this.service.hideSpinner()
      this.service.successToast(res['responseMessage'])
      this.router.navigate(['/job-management'])
    } else {
      this.service.hideSpinner()
      this.service.errorToast(res['responseMessage'])
    }
  })
}

back(){
  this.router.navigate(['/job-management'])
}

}
