import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-view-advertisement',
  templateUrl: './view-advertisement.component.html',
  styleUrls: ['./view-advertisement.component.scss']
})
export class ViewAdvertisementComponent implements OnInit {

id:any
advertisementData:any=[]
myimage:any
  constructor(private activatedRoute: ActivatedRoute, private router: Router, public mainService: CommonService) { 
    this.activatedRoute.queryParams.subscribe((res: any) => {
      this.id = res.advertisementId
    }
    )
  }

  ngOnInit(): void {
    this.viewAdvertisement()
  }

  back() {
    this.router.navigate(['/advertisement-list'])
  }

  //------ view individual data of advertisement------//
  viewAdvertisement(){
    let url="api/v1/admin/getAdvertisementParticular?id="+this.id
    this.mainService.getApi(url, 1).subscribe((res: any) => {
      this.mainService.showSpinner()
      if (res["statusCode"] == 200) {
        this.advertisementData = res["result"]
        this.mainService.hideSpinner();
        this.mainService.successToast("Success");
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast("Something went wrong");
      }
    })
    
  }
}

