<main class="middle-content">
<!---Page Title Start-->
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">Add Logo</h1>
    </div>
<!---Page Title End-->

    <div class="content-section">
        <form [formGroup]="addForm">
            <div class="outer-box">
                <div class="custom_tabs common-tabs">
                    <div class="tab-content card" style="padding: 2%; width: 850px; margin: auto;">
                     
                        <div class="myAlign">

                            <div class="form-group row" >
                                <label class="col-md-5 bold">Title</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-4">
                                    <input type="text" class="form-control" placeholder="Title"
                                 formControlName="title" maxlength="100">
                                 <div
                                    *ngIf="addForm.controls['title'].hasError('required') && (addForm.controls['title'].touched ||addForm.controls['title'].dirty)">
                                    <span class="error">*Please enter title.</span>
                                </div>
                                
                                </span>
                                
                            </div>  

                                <div class="form-group row" >
                                    <label class="col-md-5 bold">Upload Image</label>
                                    <span class="col-md-1">:</span>
                                    <span class="col-md-6">
                                        <input #file type="file"   formControlName="image"
                                        accept='image/*'  (change)="uploadImg($event)"/>
                                    <div class="row">
                                        <div class="col-md-5 col-lg-5">  
                                             <img [src]="imgSrc"  [ngClass]="{advertizeImage: (imageuploaded == true)}"
                                             style="width: 100%; margin-top: 10px;" alt=""/>
                                        </div>
                                    </div>
                                    </span>
                                    
                                </div>   
                            </div>
                        <div style="text-align: center ;margin-top:20px ;position: relative;right: 50px;">
                            <button class="btn  btn-button ml-10" type="button" (click)="back()">Back</button>
                            <button class="btn  btn-button ml-4" type="button"  [disabled]="!uploadedImage"
                            (click)="addLogo()">Save</button>
                        </div> 
                    </div>
                </div>
            </div>
        </form>
    </div>
</main>


