<main class="middle-content">
    <!-----page title start---->
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">Add Category</h1>
    </div>
    <!-----page title end ----->
    <!-----table responsive start--->
    <div class="content-section">
        <form [formGroup]="addSocialForm">
            <div class="outer-box">
                <div class="custom_tabs common-tabs">
                    <form [formGroup]="addSocialForm">
                        <div class="tab-content card" style="padding: 2%; max-width: 850px; margin: auto;">

                            <div class="myAlign">
                                <div class="form-group row ">
                                    <label class="col-md-5 bold">Social Name</label>
                                    <span class="col-md-1">:</span>
                                    <span class="col-md-6">
                                        <input type="text" class="form-control"  placeholder="Social Name" formControlName="socialName" maxlength="100">
                                        <div
                                        *ngIf="addSocialForm.controls['socialName'].hasError('required') && (addSocialForm.controls['socialName'].touched ||addSocialForm.controls['socialName'].dirty)">
                                        <span class="error">*Please enter social name.</span>
                                </div>
                                </span>

                            </div>
                        </div>
                        <div class="myAlign">
                            <div class="form-group row ">
                                <label class="col-md-5 bold">Social Link</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">
                                        <input type="text" class="form-control"  placeholder="Social Link" formControlName="socialLink" maxlength="100">
                                        <div
                                        *ngIf="addSocialForm.controls['socialLink'].hasError('required') && (addSocialForm.controls['socialLink'].touched ||addSocialForm.controls['socialLink'].dirty)">
                                        <span class="error">*Please enter social link.</span>
                            </div>
                            </span>

                        </div>
                </div>

                <div style="text-align: center ;margin-top:20px;position: relative;right: 50px;">
                    <button class="btn  btn-button ml-10" type="button" routerLink="/social-network">Back</button>
                    <button class="btn  btn-button ml-4" type="button" [disabled]="addSocialForm['invalid']" (click)="addSocialLink()">Add</button>
                </div>

            </div>
            </form>
    </div>
    </div>
    </form>

    </div>
    <!-----table responsive end--->
</main>