<main class="middle-content">
<!----page title start--->
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">Help Management</h1>
    </div>
<!----page title End---->

<!----Table responsive start--->
    <div class="content-section" style="margin-bottom: 50px;">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <form [formGroup]="searchForm" (change)="changeStat()">

                    <div class="tab-pane1">

                            <div class="sec_head_new sec_head_new_after">
                                <div class="row align-items-center">
                                     <div class="col-md-6 col-lg-3 pr-0">
                                        <div class="filter_search mb20 width100">

                                            <span class="d-flex align-items-center bold">Search:</span>
                                            <div class="input-group filter_search_group">
                                            <input type="text" placeholder="Search by name" class="form-control" formControlName="name"
                                            (keypress)="service.restrictSpace($event)" 
                                            style="height: 37px;">
                                            
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-2 col-md-4 pr-0" >
                                    <div class="filter_search mb20 width100">
                                    <span class="d-flex align-items-center bold">From Date:</span>
                                      <mat-form-field appearance="outline">
                                    <input  matInput [matDatepicker]="picker" placeholder="mm/dd/yyyy" autocomplete="off" readonly  formControlName="fromDate" [max]="service.today()">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                  </mat-form-field>

                                </div>
                                </div>
                                <div class="col-lg-2 col-md-4 pr-0">
                                    <div class="filter_search mb20 width100">
                                        <span class="d-flex align-items-center bold">To Date:</span>
                                        <mat-form-field appearance="outline">
                                        <input  matInput [matDatepicker]="picker1" placeholder="mm/dd/yyyy" formControlName="toDate" autocomplete="off"  [disabled] = "!searchForm.value.fromDate" [min]="searchForm.value.fromDate" readonly >
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                      </mat-form-field>
                                </div>
                                </div>
                             
                                <div class="col-md-5 col-lg-5 user-pl" style="margin-bottom:20px;">
                                    <div class="user-frmbtn" style="display: flex;">
                                        <button type="submit" (click)="search()"
                                            class="btn  btn-button mr-2">Search</button>
                                        <button type="submit" (click)="reset()" class="btn  btn-button">Reset</button>
                                      
                                    </div>
                                </div>
                            </div>

                           
                        </div>
                        </div>

                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="trade table">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th id="">S.No.</th>
                                        <th id="">Name</th>
                                        <th id="">Email</th>
                                        <th id="">Subject</th>
                                        <th id="">Response Status</th>  
                                        <th id="">Created Date</th>
                                        <th id=""> Status</th>  
                                        <th id="">Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                 
                                    <tr *ngFor="let data of helplist| paginate: { itemsPerPage:itemsPerPage, currentPage: currentPage, totalItems:total} ;let i = index">
                                        <td>{{itemsPerPage * (currentPage - 1) + i+1}}</td>
                                        <td style="white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        max-width: 30em;">{{data?.name||'--'}}</td>
                                        <td style="white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        max-width: 30em;">{{data?.email||'--'}}</td>
                                        <td style="white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;">{{data?.subject||'--'}}</td>
                                        <td>{{data?.responseStatus||'--'}}</td>  
                                        <td>{{data?.createdAt| date : 'MM/dd/yyyy, hh:mm a'||'--'}}</td>
                                        <td>{{data?.status||'--'}}</td>  

                                             <td class="action_td_btn3" style="cursor: pointer">
                                         
                                            <a><em class="fa fa-eye" (click)="viewHelp(data?.id)" title="View"></em></a>

                                            
                                    </tr>
                                    <tr *ngIf="total==0">
                                        <td colspan="11" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5>No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                     
                        <div class="custom-pagination mt20 text-center" style="float: right;" *ngIf="total>itemsPerPage">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>
                    
          </form>
                </div>
            </div>

        </div>
    </div>
<!----Table responsive end--->

</main>


   