import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.scss']
})
export class EditCategoryComponent implements OnInit {

  addForm: FormGroup
  imgSrc : any
  catId: string;
  subCategoryData: any;
  
  
    constructor(private route: Router, public service: CommonService, public activatedRouting:ActivatedRoute) { 
      this.activatedRouting.queryParams.subscribe((res:any)=>{
        this.catId=res.id
      })
    }
  
    ngOnInit() {
      this.addFormvalidation()
      this. getCategory()

     
  
    }
    addFormvalidation() {
      this.addForm = new FormGroup({
        'name': new FormControl("", [Validators.required]),

      })
    }

  //------ view individual data of category------//
    getCategory(){
      let url ="api/v1/admin/viewCategory?id="+this.catId;
      this.service.showSpinner()
      this.service.getApi(url,1).subscribe((res)=>{
        if (res['statusCode'] ==200) {
          this.subCategoryData=res['result']
          this.addForm.patchValue({
            name:this.subCategoryData.categoryName,
            status:this.subCategoryData.status
          })
          this.service.hideSpinner()
          this.service.successToast(res['responseMessage'])
        } else {
          this.service.hideSpinner()
          this.service.errorToast(res['responseMessage'])
        }
      })
    }
  
  //------ update individual data of category------//
    updateCategory(){
      let url = "api/v1/admin/editCategory?id="+this.catId
      const data = {
        "categoryName"  :this.addForm.value.name, 
      }
    
      this.service.putApi(url,data,1).subscribe((res)=>{
        if (res['statusCode'] ==200) {
          this.service.hideSpinner()
          this.service.successToast(res['responseMessage'])
          this.route.navigate(['/list-category'])
        } else {
          this.service.hideSpinner()
          this.service.errorToast(res['responseMessage'])
        }
      },
      (err)=>{
        this.service.hideSpinner()
        this.service.errorToast("This category already exists.")
        
      }
      )
    }
  
   
   
    
   

}