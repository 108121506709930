<div class="wrapper">
    <!----header Start ---->
    <header class="header">
        <nav class="navbar clearfix">
            <div class="logo-box">
                <a class="navbar-brand logo" [routerLink]="['/dashboard']" routerLinkActive="router-link-active">
                    <img src="assets/img/bluecol.png" alt="logo" class="Blue Collor Connect">
                </a>
            </div>
            <div class="header-right-part">
                <button class="btn btn-toggle" id="toggle_Menu" type="button" style="color: black">
                    <em class="fas fa-bars" style="color:aliceblue"></em>
                </button>

            </div>
        </nav>
    </header>
    <!----header End---->

    <!----side bar Start ---->
    <aside class="sidebar" aria-labelledby="">
        <div class="sidebar-scroller">
            <div id="accordion" class="sidebar-menu">
                <div class="user-panel header-image clearfix">
                    <div class="pull-left image">
                        <img [src]="profileData?.profilePic|| 'assets/img/testimonial-img1.jpg'" class="img-circle" alt="User Image" (click)="adminProfile()">
                        <a class="online-icon"><em
                                class="fa fa-circle text-success"></em></a>
                    </div>
                    <div class="pull-left info mt10">
                        <a>
                            <p>Welcome</p>
                            <h4 (click)="adminProfile()" style="word-break: break-word;">{{profileData?.firstName+' '+profileData?.lastName}}</h4>
                            <h4 (click)="adminProfile()">{{profileData?.email || 'admin@gmail.com' }}</h4>
                        </a>
                    </div>
                </div>
                <div class="menu-box" [ngClass]="{'active':currUrl == 'dashboard'}">
                    <div class="card-header clearfix" id="headingOne">
                        <a class="side_menu" [routerLink]="['/dashboard']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon"><i class="fa fa-dashboard" aria-hidden="true" style="font-size: 18px;"></i></span>
                            <span class="side_page_name">Dashboard</span>
                        </a>
                    </div>
                </div>

                <div class="menu-box" [ngClass]="currUrl?.includes('company') || currUrl?.includes('view-applicant') || currUrl?.includes('view-hirer') ? 'active': ''">
                    <div class="card-header clearfix" id="headingTwo">
                        <a class="side_menu" [routerLink]="['/company-management']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <i class="fas fa-user " aria-hidden="true" style="font-size: 18px;"></i>
                            </span>
                            <span class="side_page_name">User Management</span>
                        </a>
                    </div>
                </div>

                <div class="menu-box" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                    <div class="card-header clearfix" id="headingTwo">
                        <a class="side_menu" routerLinkActive="router-link-active" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                            <span class="side_menu_icon">
                                <i class="fa fa-list-alt fa-3x" aria-hidden="true" style="font-size: 18px;"></i>
                            </span>
                            <span class="side_page_name">Category Management <em class="fa fa-caret-down"></em></span>
                        </a>
                    </div>
                </div>
                <div class="collapse" id="collapseExample">
                    <div class="menu-box " [ngClass]="{'active':currUrl?.includes('list-category') || currUrl?.includes('add-category') || currUrl?.includes('view-category')|| currUrl?.includes('edit-category')}">
                        <div class="card-header clearfix" id="headingTwo">
                            <a class="side_menu" [routerLink]="['/list-category']" routerLinkActive="router-link-active">
                                <span class="side_menu_icon">
                                    <i class="fa fa-list-alt fa-3x" aria-hidden="true" style="font-size: 18px;"></i>
                                </span>
                                <span class="side_page_name">Category Management</span>
                            </a>
                        </div>
                    </div>

                    <div class="menu-box " [ngClass]="{'active':currUrl?.includes('subCategory-list')|| currUrl?.includes('sub-category') }">
                        <div class="card-header clearfix" id="headingFour">
                            <a class="side_menu" [routerLink]="['/subCategory-list']" routerLinkActive="router-link-active">
                                <span class="side_menu_icon">
                                    <i class="fa fa-list-alt fa-3x" aria-hidden="true" style="font-size: 18px;"></i>
                                </span>
                                <span class="side_page_name">Sub Category Management</span>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="menu-box" [ngClass]="currUrl?.includes('job-seeker-management') || currUrl?.includes('view-seeker') ? 'active': ''">
                    <div class="card-header clearfix" id="headingTwo">
                        <a class="side_menu" [routerLink]="['/job-seeker-management']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <img src="assets/img/Background.png"  alt="icon"  />
                            </span>
                            <span class="side_page_name">Job Seeker Management</span>
                        </a>
                    </div>
                </div>

                <div class="menu-box" [ngClass]="currUrl?.includes('job-management') || currUrl?.includes('view-job') || currUrl?.includes('edit-job')|| currUrl?.includes('reported-job')  ? 'active': ''">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/job-management']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <em class="fa fa-briefcase" style="font-size: 18px;"></em>
                            </span>
                            <span class="side_page_name">Jobs Management</span>
                        </a>
                    </div>
                </div>

                <div class="menu-box" [ngClass]="currUrl?.includes('advertisement-list') || currUrl?.includes('advertisement') ? 'active': ''">
                    <div class="card-header clearfix" id="headingTwo">
                        <a class="side_menu" [routerLink]="['/advertisement-list']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <i class="fa fa-bullhorn" aria-hidden="true" style="font-size: 18px;"></i>
                            </span>
                            <span class="side_page_name">Advertisement Management</span>
                        </a>
                    </div>
                </div>

                <div class="menu-box" [ngClass]="currUrl?.includes('logo-list') || currUrl?.includes('logo') ? 'active': ''">
                    <div class="card-header clearfix" id="headingTwo">
                        <a class="side_menu" [routerLink]="['/logo-list']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <i class="fa fa-gear" aria-hidden="true" style="font-size: 18px;"></i>
                            </span>
                            <span class="side_page_name">Logo Management</span>
                        </a>
                    </div>
                </div>

                <div class="menu-box" [ngClass]="{'active':currUrl?.includes('help-list') || currUrl?.includes('view-help')}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/help-list']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">
                                <em class="fa fa-hand-paper-o" style="font-size:18px"></em>                            
                            </span>
                            <span class="side_page_name">Help Management</span>
                        </a>
                    </div>
                </div>



                <div class="menu-box" [ngClass]="{'active':currUrl?.includes('static-content') }">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/static-content']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon"><i class="fa fa-file" aria-hidden="true"  style="font-size: 18px;"></i></span>
                            <span class="side_page_name">Static Content Management</span>
                        </a>
                    </div>
                </div>

                <div class="menu-box" [ngClass]="{'active':currUrl?.includes('faq') }">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/faq-list']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon"><i class="fa fa-question-circle"
                                aria-hidden="true" style="font-size: 18px;"></i></span>
                            <span class="side_page_name">FAQs</span>
                        </a>
                    </div>
                </div>

                <div class="menu-box" [ngClass]="{'active':currUrl == 'change-password'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/change-password']" routerLinkActive="router-link-active">
                            <span class="side_menu_icon">   
                                <i class="fa fa-key" aria-hidden="true" style="font-size: 18px;"></i></span>
                            <span class="side_page_name">Change Password</span>
                        </a>
                    </div>
                </div>


                <div class="menu-box" style="border-bottom: 1px solid rgba(255, 255, 255);">
                    <div class="card-header clearfix">
                        <a class="side_menu" data-target="#signout_modal" data-toggle="modal" style="cursor: pointer;">
                            <span class="side_menu_icon">
                                <img src="assets/img/logout.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Logout</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </aside>
    <!---- side bar End---->

    <!---- Logout Modal Start---->
    <div class="modal fade global-modal reset-modal" id="signout_modal">
        <div class="modal-dialog max-WT-500">
            <div class="modal-content">
                <div>
                    <div class="modal-body  text-center">
                        <div class="row align-items-center modal_flax_height">
                            <div class="col">
                                <div class="box-title mb40 d-inline-block">
                                    <em class="fas fa-power-off off-icon "></em>
                                    <p class="mt40">Are you sure you want to logout?</p>
                                </div>
                                <div class="text-center">
                                    <button class="btn btn-button" data-dismiss="modal" (click)="onLogout()">YES</button>
                                    <button type="button" class="btn btn-button ml-2" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!---- Logout Modal End---->



    <!---- footer Start---->
    <footer>
        <div class="container-fluid">
            <p class="copyright">Copyright © 2021 <strong class="theme-text-color">Site Development</strong> All Rights Reserved.</p>
        </div>
    </footer>
    <!---- footer End---->
</div>