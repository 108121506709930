import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-view-reported-job',
  templateUrl: './view-reported-job.component.html',
  styleUrls: ['./view-reported-job.component.scss']
})
export class ViewReportedJobComponent implements OnInit {
  id: any;
  reportedJobData: any;

  constructor( private router: Router, public service: CommonService,private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      this.id = res.id
    }
    )
   }

  ngOnInit(): void {
    this.getReportedJobList()
  }

   //-------get individual data of reported job-------//
 getReportedJobList(){
  let url="api/v1/admin/viewReport?id="+this.id
  this.service.getApi(url, 1).subscribe((res: any) => {
    this.service.showSpinner()
    if (res["statusCode"] == 200) {
      this.reportedJobData = res["result"]
      this.service.hideSpinner();
      this.service.successToast("Success");
    } else {
      this.service.hideSpinner();
      this.service.errorToast("Something went wrong");
    }
  })
  

 }
 back() {
  this.router.navigate(['/reported-job'])
}
}
