import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
declare var $
@Component({
  selector: 'app-faq-list',
  templateUrl: './faq-list.component.html',
  styleUrls: ['./faq-list.component.scss']
})
export class FaqListComponent implements OnInit {
  faqList:any=[]
  faqResult: any;
  id: any;
  total:any
  itemsPerPage: number = 10;
  currentPage: number = 1
  totalItems: number;
  constructor(private router: Router , public mainService:CommonService) {
    
  }
  
  ngOnInit(): void {
    this.getFaqData()
  }

  //----- get list of faq -----//
   getFaqData(){
     var url = 'api/v1/static/faqList?page='+this.currentPage+"&limit="+this.itemsPerPage
     this.mainService.showSpinner()
      this.mainService.getApi(url,1).subscribe(res=>{
        if(res['statusCode']==200){
        this.faqResult = res['result']['rows']
        this.total=res['result']['count']
        this.mainService.hideSpinner()
        this.mainService.successToast(res["responseMessage"]) 
      } 
      else{
      this.mainService.hideSpinner()
      this.mainService.errorToast(res['responseMessage'])
      }
     }, err => {
      this.faqResult = []
      this.total = 0
      this.mainService.hideSpinner();
    })
    }
    
  
  addFaq() {
    this.router.navigate(['/add-faq'])
  }
  viewUser(id) {
    this.router.navigate(['view-faq'], { queryParams: { id: id } })
  }
  editFaq(id) {
    this.router.navigate(['/edit-faq'], { queryParams: { id: id } })

  }

   //----delete faq ----//
  deleteFaqModal(id){
    this.id=  id
  }

  hideModal(){
    $("#delete").modal('hide');
  }

  deleteFaq(){
    this.mainService.showSpinner()
    this.mainService.deleteApi('api/v1/static/deletefaq?id='+this.id ,{} ,1).subscribe(res=>{
      if(res["statusCode"] == 200){
        this.mainService.successToast(res["responseMessage"])
        this.getFaqData()
      }
      else{
        this.mainService.hideSpinner()
        this.mainService.errorToast(res["responseMessage"])
      }
    })
  }

  //-----pagination----//
  pagination(event) {
    this.currentPage = event;
    this.getFaqData()    
  }
}
