<main class="middle-content">
    <!-----page title start----->
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">Category Management</h1>
    </div>
    <!-----page title end----->

    <!-----table responsive start----->
    <div class="content-section" style="margin-bottom: 50px;">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <form [formGroup]="addForm" (change)="changeStat()">

                    <div class="tab-content1">
                        <div class="tab-pane1">

                            <div class="sec_head_new sec_head_new_after">
                                <div class="row align-items-center">
                                    <div class="col-md-4 col-lg-3 pr-0">
                                        <div class="filter_search mb20 width100">
                                            <span class="d-flex align-items-center bold">Search:</span>
                                            <div class="input-group filter_search_group">
                                                <input type="text" placeholder="search by category name" class="form-control" (keypress)="service.restrictSpace($event)" formControlName="name" style="height: 37px;">

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-4 pr-0">
                                        <div class="filter_search mb20 width100">
                                            <span class="d-flex align-items-center bold">From Date:</span>
                                            <mat-form-field appearance="outline">
                                                <input matInput [matDatepicker]="picker" placeholder="mm/dd/yyyy" autocomplete="off" readonly formControlName="fromDate" [max]="service.today()">
                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>

                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-4 pr-0">
                                        <div class="filter_search mb20 width100">
                                            <span class="d-flex align-items-center bold">To Date:</span>
                                            <mat-form-field appearance="outline">
                                                <input matInput [matDatepicker]="picker1" placeholder="mm/dd/yyyy" formControlName="toDate" autocomplete="off" [disabled]="!addForm.value.fromDate" [min]="addForm.value.fromDate" readonly>
                                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                                <mat-datepicker #picker1></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>


                                    <div class="col-md-8 col-lg-4 user-pl" style="margin-bottom:20px;">
                                        <div class="user-frmbtn" style="display: flex;">
                                            <button type="button" class="btn  btn-button" (click)="search()">Search</button>

                                            <button type="button" class="btn  btn-button ml-2 " (click)="reset()">Reset</button>
                                            <button type="button" class="btn btn-button  mr-2 col-md-6 col-lg-6" style="margin-left: 2%; font-size: 4px;" [routerLink]="['/add-category']">Add Category</button>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap" aria-describedby="user management list">
                                <thead class="kv-align-center kv-align-middle kv-merged-header">
                                    <tr class="no_wrap_th text-center">
                                        <th scope="col" class="Sorting-img">S.no</th>

                                        <th scope="col" class="Sorting-img">Category Name</th>

                                        <th scope="col" class="Sorting-img">Created At</th>
                                        <th scope="col" class="Sorting-img">Status</th>
                                        <th scope="col" class="action_td_btn3 Sorting-img">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="text-center" *ngFor="let item of categoryList  | paginate: { itemsPerPage:itemPerPage, currentPage: currentPage, totalItems: total}  ;let i = index">


                                        <td>{{itemPerPage * (currentPage - 1) + i+1}}</td>

                                        <td class="content" style="white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        max-width: 30em;">{{item?.categoryName || '--'}} </td>


                                        <td class="content">{{(item?.createdAt | date : 'MM/dd/yyyy, hh:mm a' ) || '--'}} </td>
                                        <td class="content">{{item?.status || '--'}}
                                        </td>
                                        <td class="action_td_btn3" style="cursor: pointer">

                                            <a><em class="fa fa-eye" (click)="viewCategory(item?.id)" title="View"></em></a>
                                            <a *ngIf="item?.status == 'ACTIVE'" data-toggle="modal" data-target="#BlockModal" (click)="getCategoryId(item?.id,'BLOCK')" title="Active">
                                                <em class="fa fa-ban"  aria-hidden="true" style="color: green;"></em>
                                            </a>
                                            <a *ngIf="item?.status == 'BLOCK'" data-toggle="modal" data-target="#BlockModal" (click)="getCategoryId(item?.id,'ACTIVE')" title="Block">
                                                <em class="fa fa-ban" aria-hidden="true" style="color: red;"></em>
                                            </a>
                                        </td>


                                    </tr>
                                    <tr *ngIf="total == 0">
                                        <td class="table-no-record" colspan="6">No Record Found</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="custom-pagination mt20 text-center" style="float: right;" *ngIf="total >itemPerPage">
                                <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                            </div>
                        </div>

                    </div>

                </form>
            </div>
        </div>
    </div>
    <!-----table responsive end----->
</main>


<!-------Block Modal Start------->
<div class="modal fade global-modal reset-modal" id="BlockModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">

                    <h5 class="modal-title d-inline-block" style="text-transform: capitalize;">{{status | lowercase}}</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">

                                <p>Are you sure you want to {{status | lowercase}} this category?</p>

                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-button" data-dismiss="modal" (click)="blockUnblockCategory()">Yes</button>
                                    <button type="button" class="btn btn-button  ml-2" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-------Block Modal End------>