import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { DatePipe } from "@angular/common";

declare var $

@Component({
  selector: 'app-job-management',
  templateUrl: './job-management.component.html',
  styleUrls: ['./job-management.component.scss']
})
export class JobManagementComponent implements OnInit {

  total:any
  itemsPerPage: number = 10;
  currentPage: number = 1
  totalItems: number;
  JobList: any;
  id: any;
  searchForm:FormGroup
  status: any;
  flip: boolean=false;
  
  Applicant:boolean= false
  Thirdpartycontractor:boolean=false
 activeTab :any ="All_job"

  isSearched: boolean=false;
  city: string;
  resetPage: boolean=false;
  constructor(private router: Router, public service: CommonService,private datepipe : DatePipe) { }

  ngOnInit() {
    this. searchFormValidation()
    this.changeTab(this.activeTab)
    if(localStorage.getItem("tab")){
    this.changeTab(localStorage.getItem("tab"))
    localStorage.removeItem("tab")
    }
  }
 
  searchFormValidation() {
    this.searchForm = new FormGroup({
      city: new FormControl(''),
      fromDate: new FormControl(""),
      toDate: new FormControl(""),
      text:new FormControl("")
    });
  }
  
  //------change tab functionality-----//
  changeTab(tab){
  this.activeTab='All_job';
  this.activeTab= tab
  this.getJobList()
  this.currentPage= 1
  }

  

  viewThirdParty(id){
    this.router.navigate(['/view-job-third-party'], { queryParams: { id : id} })
  }
  
  viewApplicant(id){
    this.router.navigate(['/view-job'], { queryParams: { id : id} })
  }
  viewAllJob(id,accountType){
    console.log(accountType);
    
     if(accountType=='Applicant'){
      this.router.navigate(['/view-job'], { queryParams: { id : id} })
     }
     else{
      this.router.navigate(['/view-job-third-party'], { queryParams: { id : id} })
     }
  }

//-------get list of job -------//
 getJobList(){
  let url=""
  if (this.activeTab == 'Applicant') {
     url="api/v1/admin/listJobs?page="+this.currentPage+"&limit="+this.itemsPerPage+"&applyType="+this.activeTab
  }
  else if(this.activeTab == '3rd_Party_Contractor'){
    url="api/v1/admin/listJobs?page="+this.currentPage+"&limit="+this.itemsPerPage+"&applyType="+this.activeTab
  }
  else {
    url="api/v1/admin/listJobs?page="+this.currentPage+"&limit="+this.itemsPerPage
  }
    this.service.showSpinner();

  this.service.getApi(url,1).subscribe((res) => {
    if (res['statusCode'] == 200) {
      this.JobList = res['result']['rows']
      this.total = res['result']['count']
      this.service.hideSpinner()
     this.service.successToast(res["responseMessage"])
    } else {
      this.service.hideSpinner()
      this.service.errorToast(res['responseMessage'])
    }
  },(err)=>{
    this.JobList=[]
    this.total=0
    this.service.hideSpinner()
  })
 }

 //-----------------delete Job ---------------//
 deleteModal(id){
  this.id = id
}
deleteJob(){
  let url = "api/v1/admin/deleteJob?id="+this.id
  this.service.showSpinner()
  this.service.deleteApi(url, {}, 1).subscribe((res) => {
    if (res["statusCode"] == 200) {
      this.getJobList()
      this.service.hideSpinner();
      this.service.successToast(res["responseMessage"]);
      this.router.navigate(['/job-management'])
    } else {
      this.service.hideSpinner();
      this.service.errorToast(res["responseMessage"]);
    }
  });
}

//------search job--------//
search() {
  if(!this.searchForm.value.city && !this.searchForm.value.fromDate && !this.searchForm.value.toDate){
    return
  }
  if(this.currentPage != 1 && !this.isSearched){
    this.currentPage = 1
  }
  this.isSearched = true
  this.flip =  true  
  
  const fromDate = new Date(this.searchForm.value.fromDate)
  const toDate = new Date(this.searchForm.value.toDate)
  let url=""
  
  if(this.searchForm.value.city && !this.searchForm.value.fromDate && !this.searchForm.value.toDate){
   url="api/v1/admin/listJobs?city="+this.searchForm.value.city+"&page="+this.currentPage+"&limit="+this.itemsPerPage+"&applyType="+this.activeTab
   
  }
  else if(!this.searchForm.value.city && this.searchForm.value.fromDate && !this.searchForm.value.toDate){
    url = "api/v1/admin/listJobs?page="+(this.currentPage - 1)+"&pageSize="+this.itemsPerPage+"&startDate="+fromDate+"&applyType="+this.activeTab
   
  }
  else if(!this.searchForm.value.city && this.searchForm.value.fromDate && this.searchForm.value.toDate){
    url = "api/v1/admin/listJobs?page="+(this.currentPage - 1)+"&pageSize="+this.itemsPerPage+"&startDate="+fromDate+"&endDate="+toDate+"&applyType="+this.activeTab
   
  }
  else if(this.searchForm.value.city && this.searchForm.value.fromDate && !this.searchForm.value.toDate){
    url = "api/v1/admin/listJobs?city="+this.searchForm.value.city+"&page="+(this.currentPage - 1)+"&pageSize="+this.itemsPerPage+"&startDate="+fromDate+"&applyType="+this.activeTab
   
  }
  else{
    url = "api/v1/admin/listJobs?city="+this.searchForm.value.city+"&page="+(this.currentPage - 1)+"&pageSize="+this.itemsPerPage+"startDate="+fromDate+"&endDate="+toDate+"&applyType="+this.activeTab
  
  }
 this.service.showSpinner();
  this.service.getApi(url, 1).subscribe(
    (res) => {
      if (res['statusCode'] == 200) {
        this.JobList = res['result']['rows']
        this.total = res['result']['count']
        this.service.hideSpinner();
        this.service.successToast(res['responseMessage']);
      }  
    },
    (err) => {
     this.JobList=[]
     this.total=0
     this.service.hideSpinner();
    }
  );
}

searchAllJob() {
  if(!this.searchForm.value.city && !this.searchForm.value.fromDate && !this.searchForm.value.toDate){
    return
  }
  if(this.currentPage != 1 && !this.isSearched){
    this.currentPage = 1
  }
  this.isSearched = true
  this.flip =  true  
  
  const fromDate = new Date(this.searchForm.value.fromDate)
  const toDate = new Date(this.searchForm.value.toDate)
  let url=""
  
  if(this.searchForm.value.city && !this.searchForm.value.fromDate && !this.searchForm.value.toDate){
   url="api/v1/admin/listJobs?city="+this.searchForm.value.city+"&page="+this.currentPage+"&limit="+this.itemsPerPage
   
  }
  else if(!this.searchForm.value.city && this.searchForm.value.fromDate && !this.searchForm.value.toDate){
    url = "api/v1/admin/listJobs?page="+(this.currentPage - 1)+"&pageSize="+this.itemsPerPage+"&startDate="+fromDate
   
  }
  else if(!this.searchForm.value.city && this.searchForm.value.fromDate && this.searchForm.value.toDate){
    url = "api/v1/admin/listJobs?page="+(this.currentPage - 1)+"&pageSize="+this.itemsPerPage+"&startDate="+fromDate+"&endDate="+toDate
   
  }
  else if(this.searchForm.value.city && this.searchForm.value.fromDate && !this.searchForm.value.toDate){
    url = "api/v1/admin/listJobs?city="+this.searchForm.value.city+"&page="+(this.currentPage - 1)+"&pageSize="+this.itemsPerPage+"&startDate="+fromDate
   
  }
  else{
    url = "api/v1/admin/listJobs?city="+this.searchForm.value.city+"&page="+(this.currentPage - 1)+"&pageSize="+this.itemsPerPage+"startDate="+fromDate+"&endDate="+toDate
  
  }
 this.service.showSpinner();
  this.service.getApi(url, 1).subscribe(
    (res) => {
      if (res['statusCode'] == 200) {
        this.JobList = res['result']['rows']
        this.total = res['result']['count']
        this.service.hideSpinner();
        this.service.successToast(res['responseMessage']);
      }  
    },
    (err) => {
     this.JobList=[]
     this.total=0
     this.service.hideSpinner();
    }
  );
}

//-----------------reset job ---------------//
reset() {
  if(!this.isSearched){
    return
  }
this.flip = false
this.searchForm.value.city=""
this.isSearched = false
this.getJobList()
this.searchForm.reset()

}

changeStat(){
  this.isSearched = false
 }

//------------------ Active/Block Job --------------//
getJobId(id, status) {
this.id = id;
this.status = status;
}
blockUnblockJob() {
let url = ""
if (this.status == "BLOCK") {
  url = "api/v1/admin/blockUnblockJob?id="+this.id
}else{
  url = "api/v1/admin/blockUnblockJob?id="+this.id
}
this.service.showSpinner()
this.service.putApi(url, {}, 1).subscribe((res) => {
  if (res["statusCode"] == 200) {
    this.getJobList()
    this.service.hideSpinner();
    this.service.successToast(res["responseMessage"]);
  } else {
    this.service.hideSpinner();
    this.service.errorToast(res["responseMessage"]);
  }
});
}

//-----------------pagination ---------------//
pagination(page) {
  this.currentPage = page;
  console.log(this.currentPage)
  this.getJobList()
}
  

//-----------------Export As Excel---------------//
  exportAsXLSX() {
  
    let dataArr = [];
    if(this.JobList.length == 0){
      let obj  = {}
      obj = {
        "S.No.": 0,
        "Job Name":  'N/A',
        "Apply Type":  'N/A',
        "Account Type": 'N/A',
        "City": 'N/A',
        "Number of applicant": 'N/A',
        "Created Date": 'N/A',

  
      }
      dataArr.push(obj)
      this.service.exportAsExcelFile(dataArr, 'Job management');
      return
    }
    this.JobList.forEach((element, ind) => {
      let obj = {};
      obj = {
        "S.No.": ind + 1,
        "Job Name" : element.jobType.categoryName ? element.jobType.categoryName : 'N/A',
        "Apply Type":element.applyType ? element.applyType : 'N/A',
        "Account Type":element.accountType ? element.accountType : 'N/A',
        "City": element.city ? element.city : 'N/A',
        "Number of applicant": element.quantity ? element.quantity  : 'N/A',
        "Created Date": element.createdAt ?this.datepipe.transform(element.createdAt ,'MM/dd/yyyy, hh:mm a')  : 'N/A',

      }
      dataArr.push(obj)
    })

    this.service.exportAsExcelFile(dataArr, 'Job Management');
  }

}