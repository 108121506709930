 <main class="middle-content">
     <!-----page title start---->
        <div class="page_title_block">
            <h1 class="page_title" style="color:black">View Advertisement</h1>
        </div>
    <!----- page title end---->   
    <!---- table responsive start---->
        <div class="content-section">
            <form >
                <div class="outer-box">
                    <div class="custom_tabs common-tabs">
                        <div class="tab-content card" style="padding: 2%; max-width: 850px; margin: auto;">
                         
                            <div class="myAlign">
                                <div class="form-group row">
                                    <label class="col-md-6 bold">Title</label>
                                    <span class="col-md-1">:</span>
                                    <span class="col-md-5">{{advertisementData?.title||'--'}}</span>
                                  
                                </div> 

                                <div class="form-group row">
                                    <label class="col-md-6 bold">Advertisement Image</label>
                                    <span class="col-md-1">:</span>
                                    <span class="col-md-5">
                                        <img [src]="advertisementData?.image" alt="img" style="width: 200px; height: 60px;">
                                    </span>
                                  
                                </div>

                                <div class="form-group row">
                                    <label class="col-md-6 bold">Status</label>
                                    <span class="col-md-1">:</span>
                                    <span class="col-md-5">{{advertisementData?.status||'--'}}</span>
                                  
                                </div>
    
                                <div class="form-group row">
                                    <label class="col-md-6 bold">Date and Time</label>
                                    <span class="col-md-1">:</span>
                                    <span class="col-md-5">{{advertisementData?.createdAt| date : 'MM/dd/yyyy, hh:mm a'||'--'}}</span>
                                  
                                </div>
    
                                <div class="form-group row">
                                    <label class="col-md-6 bold">Description</label>
                                    <span class="col-md-1">:</span>
                                    <span class="col-md-5" style="word-break: break-word;">{{advertisementData?.description||'--'}}</span>
                                  
                                </div>

                              
                                <div class="form-group row">
                                    <label class="col-md-6 bold">Radius 
        
                                    </label>
                                    <span class="col-md-1">:</span>
                                    <span class="col-md-5">{{advertisementData?.radius||'--'}}</span>
                                    
                                </div>

                                <div class="form-group row">
                                    <label class="col-md-6 bold">Longitude
        
                                    </label>
                                    <span class="col-md-1">:</span>
                                    <span class="col-md-5">{{advertisementData?.longitude||'--'}}</span>
                                    
                                </div>
    
                                <div class="form-group row">
                                    <label class="col-md-6 bold">Latitude 
        
                                    </label>
                                    <span class="col-md-1">:</span>
                                    <span class="col-md-5">{{advertisementData?.latitude||'--'}}</span>
                                    
                                </div>
    
    
                                
                            </div>
                            <div style="text-align: center;">
                                <button class="btn  btn-button" style="margin-top: -70; margin-left: 40px;" type="button" (click)="back()">Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
<!---- table responsive end---->
    </main> 
    