import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-view-category',
  templateUrl: './view-category.component.html',
  styleUrls: ['./view-category.component.scss']
})
export class ViewCategoryComponent implements OnInit {

  catId: any;
  categoryList: any=[{
    _id : 12,
    categoryName : 'E-V Charger',
    createdAt : '22/02/2021',
    status : 'ACTIVE',
    categoryPic : 'assets/Product image/chargerImage-1.jpeg'
  }];

  constructor(private activatedRouting:ActivatedRoute,public mainservice:CommonService) {
    this.activatedRouting.queryParams.subscribe((res:any)=>{
      this.catId=res.id
    })
   }

  ngOnInit() {
   
    this.getCategoryList()
  }
  getCategoryList() {
    let url  = "api/v1/admin/viewCategory?id="+this.catId
    this.mainservice.showSpinner()
    this.mainservice.getApi(url,1).subscribe((res)=>{
      if(res['statusCode'] == 200){
        this.categoryList=res['result']
        this.mainservice.hideSpinner()
        this.mainservice.successToast(res["responseMessage"])
       
       }
       else{
        this.mainservice.hideSpinner()
        this.mainservice.errorToast(res["responseMessage"])
       }
    })
  }

}
