<div class="wrapper">
    <main class="middle-content">
        <!----page Title Start---->
        <div class="page_title_block">
            <h1 class="page_title" style="color:black">My Profile</h1>
        </div>
        <!----page Title End--->

        <div class="content-section">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style">

                        <div class="user-profile">
                            <div class="image-box">
                                <img [src]="imgSrc ||'assets/img/avatar 1.png'" class="profile-pic" alt="">
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">First Name </label>
                            <div class="col-md-1"> :</div>
                            <div class="col-md-6">
                                <label>{{ProfileData?.firstName||'--'}}</label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">Last Name </label>
                            <div class="col-md-1"> :</div>
                            <div class="col-md-6">
                                <label> {{ProfileData?.lastName ||'--'}}</label>
                            </div>
                        </div>

                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">Email </label>
                            <div class="col-md-1"> :</div>
                            <div class="col-md-6">
                                <label>{{ProfileData?.email||'--'}}</label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">Mobile Number</label>
                            <div class="col-md-1"> :</div>
                            <div class="col-md-6">
                                <label>{{(ProfileData?.mobileNumber| mask: '(000) 000-0000')||'--'}}</label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-5">Country </label>
                            <div class="col-md-1"> :</div>
                            <div class="col-md-6">
                                <label>{{ProfileData?.country||'--'}}</label>
                            </div>
                        </div>


                        <div class="text-center mt40">
                            <a [routerLink]="['/edit-profile']" routerLinkActive="router-link-active" class="btn btn-large  max-WT-200 font-100 btn-green mr-5">Edit Profile</a>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>