<main class="middle-content">
    <!-- Page Title Start -->
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">Edit Job</h1>
    </div>
    <!-- Page Title End -->
    <div class="content-section">
        <form [formGroup]="myeditForm">
            <div class="outer-box">
                <div class="custom_tabs common-tabs">
                    <div class="tab-content card" style="padding: 2%; max-width: 850px; margin: auto;">
                     
                        <div class="myAlign">
                            <div class="form-group row">
                                <label class="col-md-5 bold">Company Name</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">
                                    <input type="text" class="form-control" name="" id="" placeholder="" formControlName="companyName" maxlength="100">
                                    <div
                                    *ngIf="myeditForm.controls['companyName'].hasError('required') && myeditForm.controls['companyName'].dirty">
                                    <span class="error">*Please enter company name.</span>
                                </div>
                                </span>
    
                            </div>
                            <div class="form-group row">
                                <label class="col-md-5 bold">Email
    
                                </label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">
                                    <input type="text" class="form-control" name="" id="" placeholder="" formControlName="email" maxlength="60">
                                    <div *ngIf="myeditForm.controls['email'].hasError('required') && myeditForm.controls['email'].dirty">
                                    <span class="error">*Please enter email.</span>
                                </div>
                               
                                    <div *ngIf="myeditForm.controls['email'].hasError('pattern') && myeditForm.controls['email'].dirty">
                                    <span class="error">*Please enter valid email.</span>
                            </div>
                                </span>
    
                            </div>
    
                            <div class="form-group row">
    
                                <label class="col-md-5 bold">Job Type
    
                                </label>
                                <span class="col-md-1">:</span>
    
                                <span class="col-md-6">
                                    <input type="text" class="form-control" name="" id="" placeholder="" formControlName="jobType" maxlength="50">
                                    <div
                                    *ngIf="myeditForm.controls['jobType'].hasError('required') && myeditForm.controls['jobType'].dirty">
                                    <span class="error">*Please enter jobtype.</span>
                                </div>
                                </span>
    
                            </div>
                            <div class="form-group row">
    
                                <label class="col-md-5 bold">Job SubType
    
    
                                </label>
                                <span class="col-md-1">:</span>
    
                                <span class="col-md-6">
                                   
                                    <input type="text" class="form-control" name="" id="" placeholder="" formControlName="jobSubType" maxlength="50">
                                    <div
                                    *ngIf="myeditForm.controls['jobSubType'].hasError('required') && myeditForm.controls['jobSubType'].dirty">
                                    <span class="error">*Please enter job subtype.</span>
                                </div>
                                </span>
    
                            </div>
                            <div class="form-group row">
                                <label class="col-md-5 bold">Apply Type
    
                                </label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">
                        <input type="text" class="form-control" name="" id="" placeholder="" formControlName="applyType" maxlength="50">
                        <div
                        *ngIf="myeditForm.controls['applyType'].hasError('required') && myeditForm.controls['applyType'].dirty">
                        <span class="error">*Please enter job apply type.</span>
                    </div>
                                </span>
    
                            </div>
                            <div class="form-group row">
                                <label class="col-md-5 bold">Duration Type
    
                                </label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">
                          <input type="text" class="form-control" name="" id="" placeholder="" formControlName="durationType" maxlength="50">
                          <div
                          *ngIf="myeditForm.controls['durationType'].hasError('required') && myeditForm.controls['durationType'].dirty">
                          <span class="error">*Please enter job duration type.</span>
                      </div>
                                </span>
    
                            </div>
    
                            <div class="form-group row">
    
                                <label class="col-md-5 bold">Duration
    
                                </label>
                                <span class="col-md-1">:</span>
    
                                <span class="col-md-6">
                         <input type="text" class="form-control" name="" id="" placeholder="" formControlName="duration" maxlength="10">
                         <div
                         *ngIf="myeditForm.controls['duration'].hasError('required') && myeditForm.controls['duration'].dirty">
                         <span class="error">*Please enter job duration.</span>
                     </div>
    
                                </span>
    
                            </div>
                            <div class="form-group row">
    
                                <label class="col-md-5 bold">Equipment Type
    
    
                                </label>
                                <span class="col-md-1">:</span>
    
                                <span class="col-md-6">
                    <input type="text" class="form-control" name="" id="" placeholder="" formControlName="equipmentType" maxlength="50">
                    <div
                    *ngIf="myeditForm.controls['equipmentType'].hasError('required') && myeditForm.controls['equipmentType'].dirty">
                    <span class="error">*Please enter equipment type.</span>
                </div>
                                </span>
    
                            </div>
    
                            
    
                            <div class="form-group row">
    
                                <label class="col-md-5 bold">Notes
    
    
                                </label>
                                <span class="col-md-1">:</span>
    
                                <span class="col-md-6">
                    <input type="text" class="form-control" name="" id="" placeholder="" formControlName="notes" maxlength="100">
                    <div
                    *ngIf="myeditForm.controls['notes'].hasError('required') && myeditForm.controls['notes'].dirty">
                    <span class="error">*Please enter notes.</span>
                </div>
                                </span>
    
                            </div>
    
                            <div class="form-group row">
    
                                <label class="col-md-5 bold">Owner Name
    
    
                                </label>
                                <span class="col-md-1">:</span>
    
                                <span class="col-md-6">
                    <input type="text" class="form-control" name="" id="" placeholder="" formControlName="ownerName" maxlength="100">
                    <div
                    *ngIf="myeditForm.controls['ownerName'].hasError('required') && myeditForm.controls['ownerName'].dirty">
                    <span class="error">*Please enter owner name.</span>
                </div>
                                </span>
    
                            </div>
    
                            <div class="form-group row">
    
                                <label class="col-md-5 bold">City
    
    
                                </label>
                                <span class="col-md-1">:</span>
    
                                <span class="col-md-6">
                    <input type="text" class="form-control" name="" id="" placeholder="" formControlName="city" maxlength="100">
                    <div
                    *ngIf="myeditForm.controls['city'].hasError('required') && myeditForm.controls['city'].dirty">
                    <span class="error">*Please enter city.</span>
                </div>
                                </span>
    
                            </div>
                            <div class="form-group row">
    
                                <label class="col-md-5 bold">Address
    
                                </label>
                                <span class="col-md-1">:</span>
    
                                <span class="col-md-6">
                    <input type="text" class="form-control" name="" id="" placeholder="" formControlName="address" maxlength="100">
                    <div
                    *ngIf="myeditForm.controls['address'].hasError('required') && myeditForm.controls['address'].dirty">
                    <span class="error">*Please enter address.</span>
                </div>
                                </span>
    
                            </div>
                            
                        </div>
                        <div style="text-align: center ;margin-top:20px;">
                            <button class="btn  btn-theme" type="button" (click)="back()" style="font-size: 12px;">Back</button>
                            <button class="btn  btn-theme ml-2" type="button" [disabled]="!myeditForm.valid" (click)="updateJob()" style="font-size: 12px;">Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <!-- Table Responsive End -->
    </div>
</main>