import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  loginSub = new BehaviorSubject(``);
  loginObs = this.loginSub.asObservable();
  public baseUrl = 'https://node-sitedevelopment.mobiloitte.com/'
  // public baseUrl = "http://172.16.1.238:3025/"

  // public baseUrl="http://172.16.1.90:3025/"
  invokeFirstComponentFunction = new EventEmitter();
  subsVar: Subscription;
  constructor(private spinner: NgxSpinnerService, public http: HttpClient, public routes: Router, private toastr: ToastrService) { }

  // ------------ post api -------------- //
  postApi(endPoint, data, isHeader) {
    var httpHeaders;
    if (isHeader == 0) {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    } else {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': `${localStorage.getItem('token')}`
        })
      }
    }
    return this.http.post(this.baseUrl + endPoint, data, httpHeaders);
  }
  getLocation(url){
    return this.http.get(url)
  }
  uploadImage(endPoint, data, isHeader) {
    var httpHeaders;
    if (isHeader == 0) {
      httpHeaders = {
        headers: new HttpHeaders({
          
        })
      }
    } else {
      httpHeaders = {
        headers: new HttpHeaders({
          
          'token': `${localStorage.getItem('token')}`
        })
      }
    }
    return this.http.post(this.baseUrl + endPoint, data, httpHeaders);
  }

  // ----------- get api ---------------- //
  getApi(endPoint, isHeader) {
    var httpHeaders;
    if (isHeader == 0) {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    } else {
      httpHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'token': ` ${localStorage.getItem('token')}`
        })
      }
    }
    return this.http.get(this.baseUrl + endPoint, httpHeaders);
  }

// ------------ put api -------------- //
putApi(endPoint, data, isHeader) {
  var httpHeaders;
  if (isHeader == 0) {
    httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  } else {
    httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': `${localStorage.getItem('token')}`
      })
    }
  }
  return this.http.put(this.baseUrl + endPoint, data, httpHeaders);
}

// ------------ put api -------------- //
putFormDataApi(endPoint, data, isHeader) {
  var httpHeaders;
  if (isHeader == 0) {
    httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  } else {
    httpHeaders = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json',
        'token': `${localStorage.getItem('token')}`
      })
    }
  }
  return this.http.put(this.baseUrl + endPoint, data, httpHeaders);
}

// ------------ put api -------------- //
postFormDataApi(endPoint, data, isHeader) {
  var httpHeaders;
  if (isHeader == 0) {
    httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  } else {
    httpHeaders = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json',
        'token': `${localStorage.getItem('token')}`
      })
    }
  }
  return this.http.post(this.baseUrl + endPoint, data, httpHeaders);
}
// ------------ delete api -------------- //
deleteApi(endPoint, data, isHeader) {
  var httpHeaders;
  if (isHeader == 0) {
    httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  } else {
    httpHeaders={
      headers: new HttpHeaders({
         "Content-Type": "application/json",
        "token": localStorage.getItem('token')
      }),
      body:data
    }
  }
  return this.http.delete(this.baseUrl + endPoint  , httpHeaders)
}
  // ------------ get local file data ---------- //
  getLocalData(url) {
    return this.http.get(url)
  }

  // ------------ get third party api ---------- //
  getThirdPartyApi(url) {
    return this.http.get(url, { observe: 'response' })
  }

  // ------------ Spinner ------------- //
  showSpinner() {
    this.spinner.show();
  }

  hideSpinner() {
    this.spinner.hide();
  }

  // uploadFile(endPointURL, data, httpHeaders): Observable<any> {

  //   return this.http.post<any>(this.baseUrl + endPointURL, data, httpHeaders)
  // }
 
  
  // ---------- toaster ----------------- //
  successToast(msg) {
    this.toastr.success(msg)
  }
  errorToast(msg) {
    this.toastr.error(msg)
  }
  infoToast(msg) {
    this.toastr.info(msg)
  }
  warningToast(msg) {
    this.toastr.warning(msg)
  }


  public isLoggedIn() : boolean {
    return localStorage.getItem('token') !== null;
  }

   // Header Managment
   changeLoginSub(msg) {
    this.loginSub.next(msg);
  }

   // Logout
   onLogout() {
    localStorage.removeItem('token');
    localStorage.removeItem('permission');
    localStorage.removeItem('usertype');
    this.routes.navigate(['/login']);
  }

  /** to prevent first space */
  preventSpace(event) {
    if ((event.charCode == 32 || event.charCode == 64) && !event.target.value) {
      event.preventDefault();
    }
  }

  /** Function to restrict space */
  restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }

  /** Function to restrict character */
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === 'Backspace')
      k = 8;
    if (k >= 48 && k <= 57 || k == 8 || k == 46)
      return true;
    else
      return false;
  }

  // remove empty key from object
  removeEmptyKey(obj) {
    Object.entries(obj).forEach(([key, val]) => val === '' && delete obj[key]);
    return obj
  }

  public today(){
    return new Date().toISOString().split('T')[0]
  }
  
// past date not acceptance validatiion
  getToday(): string {
    return new Date().toISOString().split('T')[0]
  }
  public getIPAddress()  
  {  
    return this. http.get("https://jsonip.com/?format=json");  
  } 

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);

    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }
  public onFirstComponentButtonClick() {
    this.invokeFirstComponentFunction.emit();
  }
}
