<main class="middle-content">
    <!-- Page Title Start -->
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">View Category</h1>
    </div>
    <!-- Page Title End -->
    <div class="content-section">
        <form >
            <div class="outer-box">
                <div class="custom_tabs common-tabs">
                    <div class="tab-content card" style="padding: 2%; max-width: 850px; margin: auto;">
                     
                        <div class="myAlign">
                            <div class="form-group row " >
                                <label class="col-md-5 bold">Category Id</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">{{categoryList?.id || '--'}}
                                </span>
                                
                            </div>
                            <div class="form-group row">
                                <label class="col-md-5 bold">Category Name</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">{{categoryList?.categoryName || '--'}}
                                </span>
                              
                            </div>
                          
                            <div class="form-group row">
                                <label class="col-md-5 bold">Created At
                                </label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">{{(categoryList?.createdAt | date : 'MM/dd/yyyy, hh:mm a' )|| '--'}}</span>
                                
                            </div>
                           
                            <div class="form-group row">
    
                                <label class="col-md-5 bold">Status

                                </label>  
                                <span class="col-md-1">:</span>
                              
                                <span class="col-md-6">{{categoryList?.status || '--'}}</span>
                              
                            </div>
                           
                            
                            
                        </div>
                      
                        <div style="margin-left:39% ;margin-top:20px ;">
                            <button class="btn  btn-button " type="button" routerLink="/list-category" >Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</main>
