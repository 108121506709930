<div class="wrapper">
    <main class="middle-content">
        <!----page title start--->  
        <div class="page_title_block">
            <h1 class="page_title" style="color:black">
                View Faq's</h1>
        </div>
      <!----page title end---->  
      <!----table responsive start--->
        <div class="content-section">
            <div class="order-view max-WT setting-page">
                <div class="mb40">
                    <div class="add-store-block input-style mb20 ">
                        <form>
                            <div class="form-group row align-items-baseline">
                                <div class="col-md-12 d-flex">
                                    <label class="col-md-3 bold">Question</label><span class="col-md-1">:</span>
                                    <p class="col-md-8" style="word-break: break-all;">{{faqView?.question}}</p>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <div class="col-md-12 d-flex">
                                    <label class="col-md-3 bold">Answer</label><span class="col-md-1">:</span>
                                    <p class="col-md-8" style="word-break: break-all;"> {{faqView?.answer}}</p>
                                 
                                </div>
                            </div>
                        </form>

                        <div style="text-align: center ;margin-top:20px">
                            <button class="btn  btn-button" type="button" routerLink="/faq-list">Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!----table responsive End--->
    </main>
</div>

