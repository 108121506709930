<main class="middle-content">
<!------page title start----->  
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">Add Subcategory</h1>
    </div>
<!------page title end----->  
<!------table responsive start----->  
  <div class="content-section">
        <form >
            <div class="outer-box">
                <div class="custom_tabs common-tabs">
                    <form [formGroup]="addForm">
                    <div class="tab-content card" style="padding: 2%; max-width: 850px; margin: auto;">
                     
                        <div class="myAlign">
                            <div class="form-group row " >
                                <label class="col-md-5 bold">Category Name</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">
                                    <select class="form-control form-select" formControlName="cat_id" >
                                        <option value="" selected>Select Category</option>
                                        <option [value]="category.id" *ngFor="let category of categoryData">{{category.categoryName}}</option>
                                        
                                    </select>
                                    
                                    <div
                                    *ngIf="addForm.controls['cat_id'].hasError('required') && addForm.controls['cat_id'].touched">
                                    <span class="error">*Please enter category name.</span>
                                </div> 
                                </span>
                                
                            </div>   

                            <div class="form-group row " >
                                <label class="col-md-5 bold">Subcategory Name</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">
                                    <input type="text" class="form-control " placeholder="Subcategory Name"
                                     formControlName="name" maxlength="100">
                                    <div
                                    *ngIf="addForm.controls['name'].hasError('required') && (addForm.controls['name'].touched ||addForm.controls['name'].dirty)">
                                    <span class="error">*Please enter subcategory name.</span>
                                </div>
                                </span>
                                
                            </div>   
                        </div>
                        <div style="text-align: center ;margin-top:20px; position: relative;right: 50px;">
                            <button class="btn  btn-button ml-10" type="button"  routerLink="/subCategory-list">Back</button>
                            <button class="btn  btn-button ml-4" type="button" [disabled]="addForm['invalid']" (click)="addNewCategory()" >Add</button>
                        </div>   
    
                    </div>
                </form>
                </div>
            </div>
        </form>
      
    </div>
<!------table responsive end----->  
</main>