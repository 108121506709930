<p>view-all-jobs works!</p>
<main class="middle-content">
    <!----Page Title Start---->
    <div *ngIf="activeTab=='viewjob'" class="page_title_block">
        <h1 class="page_title" style="color:black">View Job Management</h1>
    </div>
    <div *ngIf="activeTab=='Applicant'" class="page_title_block">
        <h1 class="page_title" style="color:black">View Job Management</h1>
    </div>

    <div class="row tab-container">
        <div class="col-md-4 change-content-tab" (click)="changeTab('viewjob')" [ngClass]="{'success': (activeTab == 'viewjob')}">
            <span>View Job</span>
        </div>
        <div class="col-md-4 change-content-tab" (click)="changeTab('Applicant')" [ngClass]="{'success': activeTab == 'Applicant' }">
            <span>View Applicants</span>
        </div>

    </div>

    <!----Page Title End----->

    <!----Table Responsive Start---->
    <div *ngIf="activeTab=='viewjob'" class="content-section" style="margin-top: 10px;">
        <form>
            <div class="outer-box">
                <div class="custom_tabs common-tabs">
                    <div class="tab-content card" style="padding: 2%; max-width: 850px; margin: auto;">

                        <div class="myAlign">
                            <div class="form-group row">
                                <label class="col-md-5 bold">Company Name</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">{{hirerView?.hirerDetails?.companyName||'--'}}</span>

                            </div>

                            <div class="form-group row">
                                <label class="col-md-5 bold">Email</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">{{hirerView?.hirerDetails?.email||'--'}}</span>

                            </div>

                            <div class="form-group row">
                                <label class="col-md-5 bold">Mobile Number
    
                                </label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">{{(hirerView?.hirerDetails?.mobileNumber| mask: '(000) 000-0000')||'--'}}</span>

                            </div>

                            <div class="form-group row">
                                <label class="col-md-5 bold">Apply Type
    
                                </label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">{{hirerView?.applyType||'--'}}</span>

                            </div>

                            <div class="form-group row">
                                <label class="col-md-5 bold">Job Status
    
                                </label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">{{hirerView?.jobStatus||'--'}}</span>

                            </div>



                            <div class="form-group row">

                                <label class="col-md-5 bold">Status
    
    
                                </label>
                                <span class="col-md-1">:</span>

                                <span class="col-md-6">{{hirerView?.status||'--'}}</span>

                            </div>



                            <div class="form-group row">
                                <label class="col-md-5 bold">Address
    
                                </label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">{{hirerView?.city||'--'}}</span>

                            </div>
                           
                        </div>
                        <div style="text-align: center">
                            <button class="btn  btn-button" type="button" (click)="back()">Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <!----Table Responsive End---->

    <div *ngIf="activeTab=='Applicant'" class="content-section" style="margin-top: 50px;">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <form [formGroup]="editForm">
                        <div class="tab-pane1">

                            <div class="sec_head_new sec_head_new_after">
                                <div class="row align-items-center">


                                    <div class="col-lg-3 col-md-3 pr-0">
                                        <select class="form-control form-select" formControlName="applyStatus" style="margin-top: 10px; border: 1px solid #ccc;">
                                    <option value="ALL" [selected]=true>ALL</option>
                                    <option value="APPROVED">APPROVED</option>
                                    <option value="DENIED">DENIED</option>
                                    <option value="PENDING">PENDING</option>
                
                                </select>

                                    </div>

                                    <div class="col-md-8 col-lg-4 user-pl" style="margin-bottom:20px;">
                                        <div class="user-frmbtn" style="display: flex;">
                                            <button type="submit" (click)="search()" class="btn btn-button">Search</button>
                                            <button type="submit" (click)="reset()" class="btn btn-button ml-2">Reset</button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="trade table">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th id="">S.No.</th>
                                        <th id="">Email Address</th>
                                        <th id="">Contact Number</th>
                                        <th id="">Apply Status</th>
                                        <th id="">Created Date</th>
                                        <th id="">Status</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of dataList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: total} ;let i = index">
                                        <td class="content">{{itemsPerPage * (currentPage - 1) + i+1}}</td>
                                        <td class="content">{{data?.applicantDetails?.email||'--'}}</td>
                                        <td class="content">{{(data?.applicantDetails?.mobileNumber | mask: '(000) 000 0000')||'--'}}</td>
                                        <td class="content">{{data?.applyStatus||'--'}}</td>
                                        <td class="content">{{data?.createdAt| date : 'MM/dd/yyyy, hh:mm a'||'--'}}</td>
                                        <td class="content">{{data?.status||'--'}}</td>

                                    </tr>
                                    <tr *ngIf="total==0">
                                        <td colspan="11" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5>No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="custom-pagination mt20 text-center" style="float: right;" *ngIf="total>itemsPerPage">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</main>