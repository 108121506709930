import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
import { AddAdvertisementComponent } from './pages/advertisement-management/add-advertisement/add-advertisement.component';
import { AdvertisementListComponent } from './pages/advertisement-management/advertisement-list/advertisement-list.component';
import { ViewAdvertisementComponent } from './pages/advertisement-management/view-advertisement/view-advertisement.component';
import { ViewApplicantComponent } from './pages/applicant/view-applicant/view-applicant.component';
import { AddCategoryComponent } from './pages/Category-Management/add-category/add-category.component';
import { CategoryManagementComponent } from './pages/Category-Management/category-management/category-management.component';
import { EditCategoryComponent } from './pages/Category-Management/edit-category/edit-category.component';
import { AddSubcategoryComponent } from './pages/Category-Management/SubCategory-Management/add-subcategory/add-subcategory.component';
import { EditSubcategoryComponent } from './pages/Category-Management/SubCategory-Management/edit-subcategory/edit-subcategory.component';
import { SucategoryListComponent } from './pages/Category-Management/SubCategory-Management/sucategory-list/sucategory-list.component';
import { ViewSubcategoryComponent } from './pages/Category-Management/SubCategory-Management/view-subcategory/view-subcategory.component';
import { ViewCategoryComponent } from './pages/Category-Management/view-category/view-category.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { CompanyManagementComponent } from './pages/company-management/company-management/company-management.component';
import { ViewCompanyComponent } from './pages/company-management/view-company/view-company.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AddFaqComponent } from './pages/faq/add-faq/add-faq.component';
import { EditFaqComponent } from './pages/faq/edit-faq/edit-faq.component';
import { FaqListComponent } from './pages/faq/faq-list/faq-list.component';
import { ViewFaqComponent } from './pages/faq/view-faq/view-faq.component';
import { FooterComponent } from './pages/footer/footer.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { HelpListComponent } from './pages/help-management/help-list/help-list.component';
import { HelpReplyComponent } from './pages/help-management/help-reply/help-reply.component';
import { ViewHelpComponent } from './pages/help-management/view-help/view-help.component';
import { ViewHirerComponent } from './pages/hirer-management/view-hirer/view-hirer.component';
import { EditJobComponent } from './pages/job-management/edit-job/edit-job.component';
import { JobManagementComponent } from './pages/job-management/job-management/job-management.component';
import { ViewJobThirdPartyComponent } from './pages/job-management/view-job-third-party/view-job-third-party.component';
import { ViewJobComponent } from './pages/job-management/view-job/view-job.component';
import { EditJobSeekerComponent } from './pages/job-seeker/edit-job-seeker/edit-job-seeker.component';
import { JobSeekerManagementComponent } from './pages/job-seeker/job-seeker-management/job-seeker-management.component';
import { ViewJobSeekerComponent } from './pages/job-seeker/view-job-seeker/view-job-seeker.component';
import { LoginComponent } from './pages/login/login.component';
import { AddLogoComponent } from './pages/logo-management/add-logo/add-logo.component';
import { LogoListComponent } from './pages/logo-management/logo-list/logo-list.component';
import { ViewLogoComponent } from './pages/logo-management/view-logo/view-logo.component';
import { EditProfileComponent } from './pages/profile/edit-profile/edit-profile.component';
import { MyProfileComponent } from './pages/profile/my-profile/my-profile.component';
import { ReportedJobComponent } from './pages/reported-job/reported-job.component';
import { ViewReportedJobComponent } from './pages/reported-job/view-reported-job/view-reported-job.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { AddSocialNetworkComponent } from './pages/static-content-management/add-social-network/add-social-network.component';

import { EditStaticContentComponent } from './pages/static-content-management/edit-static-content/edit-static-content.component';
import { StaticContentComponent } from './pages/static-content-management/static-content/static-content.component';
import { ViewSocialNetworkComponent } from './pages/static-content-management/view-social-network/view-social-network.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },

  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password/:email', component: ResetPasswordComponent },
  { path: 'footer', component: FooterComponent ,canActivate : [AuthGuard]},

  //dashboard
  { path: 'dashboard', component: DashboardComponent,canActivate : [AuthGuard]},

  //My-profile
  {path:'my-profile', component:MyProfileComponent,canActivate : [AuthGuard]},
  {path:'edit-profile',component:EditProfileComponent,canActivate : [AuthGuard]},
  { path: 'change-password', component: ChangePasswordComponent,canActivate : [AuthGuard] },

  //user-management
  {path:'company-management', component:CompanyManagementComponent,canActivate : [AuthGuard]},
  {path:'view-company', component:ViewCompanyComponent,canActivate : [AuthGuard]},

  //Job-management
  {path:'job-management', component:JobManagementComponent,canActivate : [AuthGuard]},
  {path:'view-job-third-party',component:ViewJobThirdPartyComponent,canActivate:[AuthGuard]},
  {path:'edit-job', component:EditJobComponent,canActivate : [AuthGuard]},
  {path:'view-job', component:ViewJobComponent,canActivate : [AuthGuard]},

  //Job-seeker-management 
  {path:'job-seeker-management', component:JobSeekerManagementComponent,canActivate : [AuthGuard]},
  {path:'edit-job-seeker', component:EditJobSeekerComponent,canActivate : [AuthGuard]},
  {path:'view-seeker', component:ViewJobSeekerComponent,canActivate : [AuthGuard]},

  //Advertisement-management
  {path:'advertisement-list',component:AdvertisementListComponent,canActivate : [AuthGuard]},
  {path:'view-advertisement',component:ViewAdvertisementComponent,canActivate : [AuthGuard]},
  {path:'add-advertisement',component:AddAdvertisementComponent,canActivate : [AuthGuard]},

  //logo-management
  {path:'logo-list',component:LogoListComponent,canActivate : [AuthGuard]},
  {path:'add-logo',component:AddLogoComponent,canActivate : [AuthGuard]},
  {path:'view-logo',component:ViewLogoComponent,canActivate : [AuthGuard]},

  //static content management
  {path:'static-content',component:StaticContentComponent,canActivate : [AuthGuard]},
  {path:'edit-static-content',component:EditStaticContentComponent,canActivate : [AuthGuard]},
    
  //faq management
  {path:'faq-list',component:FaqListComponent,canActivate : [AuthGuard]},
  {path:'edit-faq',component:EditFaqComponent,canActivate : [AuthGuard]},
  {path:'view-faq',component:ViewFaqComponent,canActivate : [AuthGuard]},
  {path:'add-faq',component:AddFaqComponent,canActivate : [AuthGuard]},
 
  //applicant-management
  {path:'view-applicant',component:ViewApplicantComponent,canActivate : [AuthGuard]},


  //category-management
  {path : 'list-category',component : CategoryManagementComponent,canActivate : [AuthGuard]},
  {path : 'add-category',component : AddCategoryComponent,canActivate : [AuthGuard]},
  {path : 'view-category',component : ViewCategoryComponent,canActivate : [AuthGuard]},
  {path: 'edit-category',component:EditCategoryComponent,canActivate : [AuthGuard]},

  //hirer-management
  {path:'view-hirer',component:ViewHirerComponent,canActivate : [AuthGuard]},

 //help-management
  {path:'help-list',component:HelpListComponent,canActivate : [AuthGuard]},
  {path:'view-help',component:ViewHelpComponent,canActivate : [AuthGuard]},
  {path:'help-reply',component:HelpReplyComponent,canActivate : [AuthGuard]},

//subcategory-management
  {path :'subCategory-list',component : SucategoryListComponent,canActivate : [AuthGuard]},
  {path:"view-sub-category" , component:ViewSubcategoryComponent,canActivate : [AuthGuard]},
  {path:"add-sub-category" , component:AddSubcategoryComponent,canActivate : [AuthGuard]},
  {path:"edit-sub-category" , component:EditSubcategoryComponent,canActivate : [AuthGuard]},

//reported-job-management
  {path:'reported-job',component:ReportedJobComponent,canActivate:[AuthGuard]},
  {path:'view-reported-job',component:ViewReportedJobComponent,canActivate:[AuthGuard]},

  // social network view
 {path:'social-network',component:ViewSocialNetworkComponent},
 {path:'add-social-network',component:AddSocialNetworkComponent}

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
   
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
