import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { NgxPaginationModule} from 'ngx-pagination'; 
import { AppComponent } from './app.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginComponent } from './pages/login/login.component';
import { SidebarComponent } from './pages/sidebar/sidebar.component';
import { FooterComponent } from './pages/footer/footer.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { MyProfileComponent } from './pages/profile/my-profile/my-profile.component';
import { EditProfileComponent } from './pages/profile/edit-profile/edit-profile.component';
import { CompanyManagementComponent } from './pages/company-management/company-management/company-management.component';
import { ViewCompanyComponent } from './pages/company-management/view-company/view-company.component';
import { ViewJobSeekerComponent } from './pages/job-seeker/view-job-seeker/view-job-seeker.component';
import { EditJobSeekerComponent } from './pages/job-seeker/edit-job-seeker/edit-job-seeker.component';
import { ViewJobComponent } from './pages/job-management/view-job/view-job.component';
import { JobManagementComponent } from './pages/job-management/job-management/job-management.component';
import { StaticContentComponent } from './pages/static-content-management/static-content/static-content.component';
import { EditStaticContentComponent } from './pages/static-content-management/edit-static-content/edit-static-content.component';
import { CKEditorModule } from 'ngx-ckeditor';
import { FaqListComponent } from './pages/faq/faq-list/faq-list.component';
import { AddFaqComponent } from './pages/faq/add-faq/add-faq.component';
import { EditFaqComponent } from './pages/faq/edit-faq/edit-faq.component';
import { ViewFaqComponent } from './pages/faq/view-faq/view-faq.component';
import { JobSeekerManagementComponent } from './pages/job-seeker/job-seeker-management/job-seeker-management.component';
import { DatePipe } from '@angular/common';
import { EditJobComponent } from './pages/job-management/edit-job/edit-job.component';
import { CategoryManagementComponent } from './pages/Category-Management/category-management/category-management.component';
import { AddCategoryComponent } from './pages/Category-Management/add-category/add-category.component';
import { ViewCategoryComponent } from './pages/Category-Management/view-category/view-category.component';
import { SucategoryListComponent } from './pages/Category-Management/SubCategory-Management/sucategory-list/sucategory-list.component';
import { ViewSubcategoryComponent } from './pages/Category-Management/SubCategory-Management/view-subcategory/view-subcategory.component';
import { AddSubcategoryComponent } from './pages/Category-Management/SubCategory-Management/add-subcategory/add-subcategory.component';
import { EditSubcategoryComponent } from './pages/Category-Management/SubCategory-Management/edit-subcategory/edit-subcategory.component';
import { ErrorInterceptor } from './error-handler/error.interceptor';
import { EditCategoryComponent } from './pages/Category-Management/edit-category/edit-category.component';
import { ReportedJobComponent } from './pages/reported-job/reported-job.component';
import { ViewHirerComponent } from './pages/hirer-management/view-hirer/view-hirer.component';
import { HelpListComponent } from './pages/help-management/help-list/help-list.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ViewHelpComponent } from './pages/help-management/view-help/view-help.component';
import { HelpReplyComponent } from './pages/help-management/help-reply/help-reply.component';
import { ViewApplicantComponent } from './pages/applicant/view-applicant/view-applicant.component';
import { ViewJobThirdPartyComponent } from './pages/job-management/view-job-third-party/view-job-third-party.component';
import { AdvertisementListComponent } from './pages/advertisement-management/advertisement-list/advertisement-list.component';
import { ViewAdvertisementComponent } from './pages/advertisement-management/view-advertisement/view-advertisement.component';
import { AddAdvertisementComponent } from './pages/advertisement-management/add-advertisement/add-advertisement.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { LogoListComponent } from './pages/logo-management/logo-list/logo-list.component';
import { AddLogoComponent } from './pages/logo-management/add-logo/add-logo.component';
import { ViewLogoComponent } from './pages/logo-management/view-logo/view-logo.component';
import { ViewReportedJobComponent } from './pages/reported-job/view-reported-job/view-reported-job.component'
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { CustomPipePipe } from './pages/pipes/custom-pipe.pipe';
import { ViewAllJobsComponent } from './pages/job-management/view-all-jobs/view-all-jobs.component';
import { ViewSocialNetworkComponent } from './pages/static-content-management/view-social-network/view-social-network.component';
import { AddSocialNetworkComponent } from './pages/static-content-management/add-social-network/add-social-network.component';
import { SplitPipe } from './pipe/split.pipe';



@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    SidebarComponent,
    FooterComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    MyProfileComponent, 
    EditProfileComponent, 
    CompanyManagementComponent,
    ViewCompanyComponent,
    JobSeekerManagementComponent,  
    ViewJobSeekerComponent,
    EditJobSeekerComponent,
    EditJobComponent,
    ViewJobComponent,
    JobManagementComponent,
    StaticContentComponent,
    EditStaticContentComponent,
    FaqListComponent,
    AddFaqComponent,
    EditFaqComponent,
    ViewFaqComponent,
    CategoryManagementComponent,
    AddCategoryComponent,
    ViewCategoryComponent,
    SucategoryListComponent,
    ViewSubcategoryComponent,
    AddSubcategoryComponent,
    EditSubcategoryComponent,
    EditCategoryComponent,
    ReportedJobComponent,
    ViewHirerComponent,
    HelpListComponent,
    ViewHelpComponent,
    HelpReplyComponent,
    ViewApplicantComponent,
    ViewJobThirdPartyComponent,
   AdvertisementListComponent,
   ViewAdvertisementComponent,
   AddAdvertisementComponent,
   LogoListComponent,
   AddLogoComponent,
   ViewLogoComponent,
   ViewReportedJobComponent,
   CustomPipePipe,
   ViewAllJobsComponent,
   ViewSocialNetworkComponent,
   AddSocialNetworkComponent,
   SplitPipe
   
  
   ],
  imports: [
    BrowserModule,
    GoogleMapsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    HttpClientModule,
    CKEditorModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      maxOpened: 1,
      preventDuplicates: true
    }),
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
