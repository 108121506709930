import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-view-help',
  templateUrl: './view-help.component.html',
  styleUrls: ['./view-help.component.scss']
})
export class ViewHelpComponent implements OnInit {

  id:any
  helpData: any;
  searchForm:FormGroup
  reply: any;
  constructor(public service:CommonService, private router:Router,private activatedroute : ActivatedRoute) { 
    this.activatedroute.queryParams.subscribe((res) => {
      this.id = res.id;
    })
  }

  ngOnInit(): void {
    this.searchForm = new FormGroup({
      reply: new FormControl('',Validators.required),    
    });
    this.getViewHelp()
  }

  
//----------------- individual view help detail-------------------//
getViewHelp(){
  let url = "api/v1/admin/viewHelp?id="+this.id
  this.service.showSpinner()
  this.service.getApi(url,1).subscribe((res)=>{
    if(res['statusCode'] == 200){
      this.helpData = res['result']
      this.service.hideSpinner()
      this.service.successToast(res['responseMessage'])
    }
    else{
      this.service.hideSpinner()
      this.service.errorToast(res['responseMessage'])
    }
  })
 }

 replyBack(id){
  this.id = id
}

//------ reply for help functionality-----//
replyHelp(){
  let url = "api/v1/admin/replyHelp?id="+this.id+"&reply="+this.searchForm.value.reply
  this.service.showSpinner()
  const data = {
    "reply": this.searchForm.value.reply
  }
  this.service.postApi(url, data, 1).subscribe((res) => {
    if (res["statusCode"] == 200) {
      this.service.hideSpinner();
      this.service.successToast(res["responseMessage"]);
      this.router.navigate(['/help-list'])
    } else {
      this.service.hideSpinner();
      this.service.errorToast(res["responseMessage"]);
    }
  });
}



}