  <main class="middle-content">
      <!-----page title start--->
        <div class="page_title_block">
            <h1 class="page_title" style="color:black">Advertisement Management</h1>
        </div>
    <!-----page title end  ---->
    <!-----table responsive start---->
        <div class="content-section" style="margin-bottom: 50px;">
            <div class="add-new-header-btn-align">
                <button class="btn btn-large  max-WT-150 btn-theme" routerLink="/add-advertisement" style="font-size: 13px;">Add Advertisement</button>
            </div>
            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <div class="table-responsive">
                            <table class="table table-bordered"  aria-describedby="Advertisement management list">
                                <thead>
                                    <tr class="no_wrap_th text-center">
                                        <th scope="col">S.No.</th>
                                        <th scope="col" class="Sorting-img">Advertisement Image</th>
                                        <th scope="col" class="Sorting-img">Status</th>
                                        <th scope="col" class="Sorting-img">Start Date</th>
                                        <th scope="col" class="Sorting-img">End Date</th>
                                        <th scope="col" class="action_td_btn3 Sorting-img">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of  advertisementList | paginate: {itemsPerPage:itemsPerPage, currentPage: currentPage, totalItems: total}; let i = index" 
                                        class="text-center">
                                      <td>{{itemsPerPage * (currentPage -1)+i+1}}</td>
                                        <td><img [src]=item.image alt="img" style="height: 50px;width: 100%;"></td>
                                        <td>{{item?.status||"--"}}</td>
                                        <td>{{(item?.startDate| date : 'MM/dd/yyyy, h:mm a')||"--"}}</td>
                                        <td>{{(item?.endDate| date : 'MM/dd/yyyy, h:mm a')||"--"}}</td>

                                          <td class="action_td_btn3" style="cursor: pointer">
                                          <a><em class="fa fa-eye" (click)="viewAdvertisement(item.id)" title="View"></em></a>
                                          <a data-target="#deleteModal" data-toggle="modal"><em class="fa fa-trash"
                                                    (click)="openDeleteBlogModal(item.id)" title="Delete"></em></a> 


                                                    <label class="switch" style="top: 8px;">
                                                        <input type="checkbox" [value] = "isActive[i]" [(ngModel)]="isActive[i]">
                                                        <span class="slider round"
                                                            (click)="changeBannerStatus(!isActive[i],item?.id)"
                                                            [title]="(isActive[i]==true)?'Enabled':'Disabled'"></span>
                                                    </label>
                                            </td>
                                        
                                    </tr>
                                    <tr *ngIf="total ==0">
                                        <td colspan="11" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5>No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="custom-pagination mt20 text-center" style="float: right;"
                        *ngIf="total>itemsPerPage">
                        <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    <!-----table responsive end---->
    </main>



<!-- delete blog modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h3 class="modal-title d-inline-block">Delete Advertisement? </h3>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to delete this advertisement?</p>
                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-primary" data-dismiss="modal"
                                    (click)="deleteAdvertisement()">Yes</button>
                                <button type="button" class="btn btn-secondary ml-2"
                                    data-dismiss="modal">No</button>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!--- delete blog modal end -->
