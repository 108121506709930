import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { EventEmitterService } from 'src/app/provider/event-emitter.service';

declare var $;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  showLogo: boolean = false;

  currUrl: any
  userDetail: any
  profileData: any;
  imgUrl: any;
  constructor(public service: CommonService, private routes: Router, private eventEmitterService: EventEmitterService) {
    routes.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currUrl = event.url.split('/')[1];
        if (this.service.isLoggedIn()) {
          if ((this.currUrl == `login` || this.currUrl == `forgot-password` || this.currUrl == `reset-password` || this.currUrl == ``)) {
            this.routes.navigate([`/dashboard`])
          }
        } else {
          if (!(this.currUrl == `login` || this.currUrl == `forgot-password` || this.currUrl.includes(`reset-password`) || this.currUrl == ``)) {
            this.routes.navigate([`/login`])
          }
        }
      }
    })

  }

  ngOnInit(): void {
    this.ready()
    this.getProfile()
    if (this.eventEmitterService.subsVar == undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.
        invokeFirstComponentFunction.subscribe((res) => {
          this.getProfile();
        });
    }

  }

  // get profile
  getProfile() {
    let url = "api/v1/admin/getAdminProfile"
    this.service.showSpinner()
    this.service.getApi(url, 1).subscribe((res: any) => {
      if (res['statusCode'] == 200) {
        this.profileData = res['result']
        this.service.hideSpinner();
      } else {
        this.service.hideSpinner();
        this.service.errorToast(res['resonseMessage'])
      }
    })
  }

  // open logout modal
  logoutModalOpen() {
    $('#signout_modal').modal('show');
  }

  // logout  modal
  onLogout() {
    this.service.changeLoginSub('logout');
    this.service.onLogout()
  }

  adminProfile() {
    this.routes.navigate(['my-profile'])
  }

  ready() {
    $("#toggle_Menu").click(function () {
      $("body").toggleClass("toggle-wrapper")
    });
  }

}
