<main class="middle-content">
    <!-- Page Title Start -->
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">Static Content Management</h1>
    </div>
    <!-- Page Title End -->

    <!-- Table Responsive Start -->
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap" aria-describedby="static content list" style="text-align:center">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th id="">S.No.</th>
                                        <th id="">Page Name </th>
                                        <th id="" class="action_td_btn3">Action</th>
                                    </tr>

                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of staticData ; let i = index">
                                        <td>{{i+1}}</td>
                                        <td>
                                            {{pageName[i] | titlecase}}
                                        </td>
                                        <td class="action_td_btn3">

                                            <a routerLinkActive="router-link-active" (click)="viewStaticData(data?.id,pageName[i])" routerLinkActive="router-link-active">
                                                <em class="fa fa-eye" title="View"></em>
                                            </a>
                                        </td>

                                    </tr>

                                    <tr>
                                        <td>5</td>
                                        <td>

                                            Social Media Setting
                                        </td>
                                        <td class="action_td_btn3">

                                            <a (click)="navigateToSocialNetwork()">
                                                <em class="fa fa-eye" title="View"></em>
                                            </a>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
    <!-- Table Responsive End -->
</main>