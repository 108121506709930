<main class="middle-content">
    <!----page title start---->
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">View Applicant</h1>
    </div>
    <!----page title end---->
    <!----table responsive start---->
    <div class="content-section">
        <form>
            <div class="outer-box">
                <div class="custom_tabs common-tabs">
                    <div class="tab-content card" style="padding: 2%; max-width: 850px; margin: auto;">

                        <div class="myAlign">

                            <div class="form-group row">
                                <label class="col-md-5 bold">First Name</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">{{hirerView?.firstName||'--'}}</span>

                            </div>

                            <div class="form-group row">
                                <label class="col-md-5 bold">Last Name</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">{{hirerView?.lastName||'--'}}</span>

                            </div>

                            <div class="form-group row">
                                <label class="col-md-5 bold">Email</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">{{hirerView?.email||'--'}}</span>

                            </div>

                            <div class="form-group row">
                                <label class="col-md-5 bold">Mobile Number
    
                                </label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">{{(hirerView?.mobileNumber | mask: '(000) 000 0000')||'--'}}</span>

                            </div>

                            <div class="form-group row">
                                <label class="col-md-5 bold">Account Type
    
                                </label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">{{hirerView?.account_Types?.accountName||'--'}}</span>

                            </div>

                            <div class="form-group row">
                                <label class="col-md-5 bold">Job Field
    
                                </label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">{{hirerView?.jobField||'--'}}</span>

                            </div>

                            <div class="form-group row">
                                <label class="col-md-5 bold">Radius Search
    
                                </label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">{{hirerView?.RadiusSearch||'--'}}</span>

                            </div>

                            <div class="form-group row">
                                <label class="col-md-5 bold">Year Of Experience
    
                                </label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">{{hirerView?.yearsOfExperience||'--'}}</span>

                            </div>

                            <div class="form-group row">

                                <label class="col-md-5 bold">Expertise
                                </label>
                                <span class="col-md-1">:</span>

                                <span class="col-md-6">{{hirerView?.expertise||'--'}}</span>

                            </div>
                            <div class="form-group row">

                                <label class="col-md-5 bold">Status
    
    
                                </label>
                                <span class="col-md-1">:</span>

                                <span class="col-md-6">{{hirerView?.status||'--'}}</span>

                            </div>
                            <div class="form-group row">

                                <label class="col-md-5 bold">Profile Complete
    
    
                                </label>
                                <span class="col-md-1">:</span>

                                <span class="col-md-6" *ngIf="hirerView?.profileComplete==true">Completed</span>
                                <span class="col-md-6" *ngIf="hirerView?.profileComplete==false">Pending</span>

                            </div>

                            <div class="form-group row">
                                <label class="col-md-5 bold">Zip Code
    
                                </label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">{{hirerView?.zipCode||'--'}}</span>

                            </div>
                        </div>
                        <div style="text-align: center;">
                            <button class="btn  btn-button" style="margin-top: -30px" type="button" (click)="back()">Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <!----table responsive end---->
</main>