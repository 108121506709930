import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

declare var $

@Component({
  selector: 'app-company-management',
  templateUrl: './company-management.component.html',
  styleUrls: ['./company-management.component.scss']
})
export class CompanyManagementComponent implements OnInit {
  
  itemsPerPage: number = 10;
  currentPage: number = 1
  totalItems: number;
  countryList: any = [];
  companyList: any;
  id: string;
  status: any;
  searchForm:FormGroup
  flip : boolean = false
  isSearched : boolean = false
  Applicant:boolean= false
  Hirer:boolean=false
  Thirdpartycontractor:boolean=false
  activeTab :any ="Applicant"
  hirerList: any;
  total: any;
  
  resetPage : boolean= false
   showmodal :boolean = false
  applicantName: string;
  constructor(private router: Router, public service: CommonService,private datepipe : DatePipe) { }

  ngOnInit() {
    this.searchFormValidation()
    this.changeTab(this.activeTab)
  if(localStorage.getItem("tab")){
    this.changeTab(localStorage.getItem("tab"))
    localStorage.removeItem("tab")
  }
  
  }

  //-----validation-------//
  searchFormValidation() {
    this.searchForm = new FormGroup({
      email: new FormControl(''),
      fromDate: new FormControl(""),
      toDate: new FormControl(""),
    });
  }
 
  //-----change tab functionality----//
  changeTab(tab){
  this.activeTab='Applicant';
  this.currentPage= 1
  this.activeTab= tab
  this.getHirerList()
}

//------get list of applicant, hirer and third party contractor -----//
 getHirerList(){
   let url=""
  if (this.activeTab == 'Applicant') {
    url = "api/v1/admin/listApplicants?page="+this.currentPage+"&limit="+this.itemsPerPage+"&accountName="+this.activeTab
  }
  else if(this.activeTab == 'Hirer'){
    url = "api/v1/admin/listApplicants?page="+this.currentPage+"&limit="+this.itemsPerPage+"&accountName="+this.activeTab
  }
  else
  {
    url = "api/v1/admin/listApplicants?page="+this.currentPage+"&limit="+this.itemsPerPage+"&accountName="+this.activeTab
  }
  this.service.showSpinner();
  this.service.getApi(url,1).subscribe((res) => {
    if (res['statusCode'] == 200) {
      this.hirerList = res['result']['rows']
      this.total = res['result']['count']
      this.service.hideSpinner();
      this.service.successToast(res["responseMessage"])
    } else {
      this.service.hideSpinner()
      this.service.errorToast(res["responseMessage"])
    }
  }, err => {
    this.hirerList = []
    this.totalItems = 0
    this.service.hideSpinner();
   
  })
 }
    
 pagination(event) {
  this.currentPage = event;
  if (this.flip) {
    this.search();
  } else {
    this.getHirerList()
  }
}

//----navigate view third party contractor with id-----//
viewThirdParty(id){
  this.router.navigate(['/view-company'], { queryParams: { id : id } })
}

//----navigate view hirer with id-----//
viewHirer(id){
  this.router.navigate(['/view-hirer'], { queryParams: { id : id } })
}

//----navigate view applicant with id-----//
viewApplicant(id){
  this.router.navigate(['/view-applicant'], { queryParams: { id : id } })
}

//-----------------delete user ---------------//
deleteModal(id){
  this.id = id
}
deletehirer(){
  let url = "api/v1/admin/deleteApplicant?id="+this.id
  this.service.showSpinner()
  this.service.deleteApi(url, {}, 1).subscribe((res) => {
    if (res["statusCode"] == 200) {
      this.getHirerList()
      this.service.hideSpinner();
      this.service.successToast(res["responseMessage"]);
     

    } else {
      this.service.hideSpinner();
      this.service.errorToast(res["responseMessage"]);
    }
  });
}
//------------------ Active/Block user --------------//
getApplicantId(id, status) {
this.id = id;
this.status = status;
}
blockUnblockJob() {
let url = ""
if (this.status == "BLOCK") {
  url = "api/v1/admin/blockUnblockApplicant?id="+this.id
}else{
  url = "api/v1/admin/blockUnblockApplicant?id="+this.id
}
this.service.showSpinner()
this.service.putApi(url, {}, 1).subscribe((res) => {
  if (res["statusCode"] == 200) {
    this.getHirerList()
    this.service.hideSpinner();
    this.service.successToast(res["responseMessage"]);
  } else {
    this.service.hideSpinner();
    this.service.errorToast(res["responseMessage"]);
  }
});
}


//------------------ search filter for user --------------//
search() {
  if(!this.searchForm.value.email && !this.searchForm.value.fromDate && !this.searchForm.value.toDate){
    return
  }
  if(this.currentPage != 1 && !this.isSearched){
    this.currentPage = 1
  }
  this.isSearched = true
  this.flip =  true  
  const fromDate = new Date(this.searchForm.value.fromDate)
  const toDate = new Date(this.searchForm.value.toDate)
  let url=""
  if(this.searchForm.value.email && !this.searchForm.value.fromDate && !this.searchForm.value.toDate){
   url="api/v1/admin/listApplicants?email="+this.searchForm.value.email+"&page="+this.currentPage+"&limit="+this.itemsPerPage+"&accountName="+this.activeTab
   
  }
  else if(!this.searchForm.value.email && this.searchForm.value.fromDate && !this.searchForm.value.toDate){
    url = "api/v1/admin/listApplicants?page="+this.currentPage+"&pageSize="+this.itemsPerPage+"&startDate="+fromDate+"&accountName="+this.activeTab
   
  }
  else if(!this.searchForm.value.email && this.searchForm.value.fromDate && this.searchForm.value.toDate){
    url = "api/v1/admin/listApplicants?page="+this.currentPage+"&pageSize="+this.itemsPerPage+"&startDate="+fromDate+"&endDate="+toDate+"&accountName="+this.activeTab
   
  }
  else if(this.searchForm.value.email && this.searchForm.value.fromDate && !this.searchForm.value.toDate){
    url = "api/v1/admin/listApplicants?email="+this.searchForm.value.email+"&page="+this.currentPage+"&pageSize="+this.itemsPerPage+"&startDate="+fromDate+"&accountName="+this.activeTab
   
  }
  else{
    url = "api/v1/admin/listApplicants?email="+this.searchForm.value.email+"&page="+this.currentPage+"&pageSize="+this.itemsPerPage+"startDate="+fromDate+"&endDate="+toDate+"&accountName="+this.activeTab
  
  }
  this.service.showSpinner()
  this.service.getApi(url, 1).subscribe(
    (res) => {
      if (res['statusCode'] == 200) {
        this.hirerList = res['result']['rows']
        this.total = res['result']['count']
        this.service.hideSpinner();
        this.service.successToast(res['responseMessage']);
        
      } 
      else {
        this.service.hideSpinner(); 
        this.service.errorToast(res['responseMessage']);
      }
    },
    (err) => {
      this.hirerList=[]
      this.total=0
      this.service.hideSpinner();
    }
  );
}

//------------------ reset for user --------------//
reset() {
  if(!this.isSearched){
    return
  }
this.flip = false
this.isSearched = false
this. getHirerList()
this.searchForm.reset()
this.searchForm.value.email=""

}

//------------------ export as excel for download file --------------//
exportAsXLSX() { 
  let dataArr = [];
  if(this.hirerList.length == 0){
    let obj  = {}
    obj = {
      "S.No.": 0,
      "Email Address":  'N/A',
      "Contact Number":  'N/A',
      "Status": 'N/A',
      "Created Date": 'N/A',

    }
    dataArr.push(obj)
    this.service.exportAsExcelFile(dataArr, 'User Management');
    return
  }
  this.hirerList.forEach((element, ind) => {
    let obj = {};
    obj = {
      "S.No.": ind + 1,
      "Email Address": element.email ? element.email : 'N/A',
      "Contact Number": element.mobileNumber ? element.mobileNumber : 'N/A',
      "Status": element.status ? element.status  : 'N/A',
      "Created Date": element.createdAt ?this.datepipe.transform(element.createdAt ,'MM/dd/yyyy, hh:mm a')  : 'N/A',

    }
    dataArr.push(obj)
  })
  this.service.exportAsExcelFile(dataArr, 'User Management');
}
changeStat(){
 this.isSearched = false
}
  }

