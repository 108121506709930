import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-add-advertisement',
  templateUrl: './add-advertisement.component.html',
  styleUrls: ['./add-advertisement.component.scss']
})
export class AddAdvertisementComponent implements OnInit {
  addForm: FormGroup
  advertisementList: any;
  proctImg: any
  marker: any = []
  public editorValue: string = '';
  imageUrl: any
  imgSrc: any = []
  productImageDto: any = []
  latitude: any = 38.223758
  longitude: any = -103.444847
  center: any
  @ViewChild('map') mapElement: any;
  map: google.maps.Map;
  image: string;
  imageuploaded: boolean = false;
  uploadedImage: any;
  constructor(private router: Router, public mainService: CommonService) { }

  ngOnInit() {

    this.getLocation()
    this.addBannerFormValidation();
  }

  //----- validation -------//
  addBannerFormValidation() {
    this.addForm = new FormGroup({
      title: new FormControl("", [Validators.required]),
      radius: new FormControl("", [Validators.required, Validators.pattern(/^[0-9]/)]),
      description: new FormControl("", [Validators.required]),
      image: new FormControl(""),
      latitude: new FormControl(""),
      longitude: new FormControl(""),
      startDate: new FormControl("", [Validators.required]),
      endDate: new FormControl("", [Validators.required]),
    })

  }

  //------ add google map for location with latitude and longitude-----//
  getLocation() {
    navigator.geolocation.getCurrentPosition((res) => {
      // this.latitude = res.coords.latitude
      // this.longitude = res.coords.longitude
      this.getCenterPostion(this.latitude, this.longitude)
      this.getLatLong()
    }, (err) => {
      this.getIp()
    })
  }
  getCenterPostion(lat, long) {
    this.center = new google.maps.LatLng(lat, long)
    this.marker = new google.maps.Marker({
      position: new google.maps.LatLng(lat, long),
      map: this.map,
      icon:// 'https://icon-library.com/images/current-location-icon/current-location-icon-8.jpg',
      {
        url: 'assets/img/map-mark.png',
        size: new google.maps.Size(36, 50),
        scaledSize: new google.maps.Size(36, 50),
        anchor: new google.maps.Point(0, 50)
      }
    })
    this.marker.setMap()
  }
  getIp() {
    var ip
    this.mainService.getIPAddress().subscribe((res) => {
      this.getLocationCurrent(res["ip"])
    })

  }

  getLocationCurrent(ip) {
    this.mainService.getLocation("http://www.geoplugin.net/json.gp?ip=" + ip).subscribe((res) => {
      this.latitude = res["geoplugin_latitude"]
      this.longitude = res["geoplugin_longitude"]
      this.getCenterPostion(this.latitude, this.longitude)
    })
  }

  getLatLong() {
    let latitude;
    let longitude;
    var mapOptions = {
      center: new google.maps.LatLng(this.latitude, this.longitude),
      zoom: 4,
    };
    this.marker.setMap()
    var infoWindow = new google.maps.InfoWindow();
    var latlngbounds = new google.maps.LatLngBounds();
    var map = new google.maps.Map(document.getElementById("dvMap"), mapOptions);
    google.maps.event.addListener(map, 'click', function (e) {
      this.latitude = e.latLng.lat()
      this.longitude = e.latLng.lng()
      if (this.marker && this.marker.setMap) {
        this.marker.setMap(null);
      }
      this.marker = new google.maps.Marker({
        position: new google.maps.LatLng(this.latitude, this.longitude),
        animation: google.maps.Animation.BOUNCE,

        map: map,
        icon:
        {
          // url: 'https://icon-library.com/images/current-location-icon/current-location-icon-8.jpg', 
          url: 'assets/img/map-mark.png',
          size: new google.maps.Size(36, 50),
          scaledSize: new google.maps.Size(36, 50),
          anchor: new google.maps.Point(0, 50)
        }

      });
      latitude = this.latitude
      longitude = this.longitude
      callme()
    });
    var callme = () => {
      this.setLatLang(latitude, longitude)
    }
  }

  setLatLang(latitude, longitude) {
    this.addForm.patchValue({
      latitude: latitude,
      longitude: longitude
    })

  }

  //-------- add  advertisement --------//
  addAdvertisement() {
    let url = "api/v1/admin/addAdvertisment"
    const data = {
      "title": this.addForm.value.title,
      "description": this.addForm.value.description,
      "latitude": this.addForm.value.latitude,
      "longitude": this.addForm.value.longitude,
      "radius": this.addForm.value.radius,
      "startDate": this.addForm.value.startDate,
      "endDate": this.addForm.value.endDate,
      "image": this.imgSrc
    }
    console.log(data)
    this.mainService.showSpinner();
    this.mainService.postApi(url, data, 1).subscribe((res) => {
      if (res["statusCode"] == 200) {
        this.mainService.hideSpinner();
        this.mainService.successToast(res["responseMessage"]);
        this.router.navigate(['/advertisement-list'])
      } else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res["responseMessage"]);
      }
    });
  }

  //--------- upload image functionality-------//
  uploadImg(event): void {
    this.imageuploaded = true;
    if (event.target.files && event.target.files[0]) {
      const max_height = 200;
      const max_width = 200;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          console.log(img_height);
          if (img_height <= max_height ) {
      this.uploadProfilePic(event.target.files[0]);
          } else {            
            this.image = ''
            this.mainService.errorToast('Please upload image of size max-height 200px only.')
          }
        };
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }


  uploadProfilePic(img) {
    var form = new FormData()
    form.append('uploaded_file', img)
    this.imgSrc[0] = "assets/img/loaderadvertisement.gif"
    let apiReqUrl = "api/v1/user/uploadFile"
    this.mainService.uploadImage(apiReqUrl, form, 1).subscribe((res: any) => {
      this.mainService.showSpinner();
      if (res["statusCode"] == 200) {
        this.imgSrc[0] = res["result"]
        this.uploadedImage = this.imgSrc[0]
        this.mainService.hideSpinner();
        this.mainService.successToast(res["responseMessage"]);
      }
      else {
        this.mainService.hideSpinner();
        this.mainService.errorToast(res["responseMessage"]);
      }
    })
  }

  back() {
    this.router.navigate(['/advertisement-list'])
  }

}

