<main class="middle-content">
<!----- Page Title Start----->
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">View Logo</h1>
    </div>
<!----- Page Title End----->

    <div class="content-section">
        <form>
            <div class="outer-box">
                <div class="custom_tabs common-tabs">
                    <div class="tab-content card" style="padding: 2%; max-width: 850px; margin: auto;">
                     
                        <div class="myAlign">
                            <div class="form-group row">
                                <label class="col-md-5 bold">Title</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">
                                <span class="col-md-6">{{logoData?.title||'--'}}</span>
                            </span>
                              
                            </div> 

                            <div class="form-group row">
                                <label class="col-md-5 bold">Logo Image</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">
                                    <img [src]="logoData?.image" alt="img" style="width: 125px; height: 45px;">
                                </span>
                              
                            </div>

                            <div class="form-group row">
                                <label class="col-md-5 bold">Status</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">{{(logoData?.status|titlecase)||'--'}}</span>
                              
                            </div>

                            <div class="form-group row">
                                <label class="col-md-5 bold">Date and Time</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">{{(logoData?.createdAt| date : 'MM/dd/yyyy, hh:mm a')||'--'}}</span>
                              
                            </div>
                           
                        </div>
                        <div style="text-align: center;">
                            <button class="btn  btn-button" style="margin-top: -20; margin-right:90px;" type="button" (click)="back()">Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</main> 

