import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss']
})
export class AddCategoryComponent implements OnInit {

  addForm: FormGroup
  imgSrc : any
  
  
    constructor(private route: Router, public mainservice: CommonService) { }
  
    ngOnInit() {
      this.addFormvalidation()
    }
    addFormvalidation() {
      this.addForm = new FormGroup({
        cat_id:new FormControl("" , Validators.required), 
      })
    }
  


 //------ add category -------// 
    addNewCategory(){
     let url = "api/v1/admin/addCategory"
     const data = {
      "categoryName": this.addForm.value.cat_id
    }
     this.mainservice.showSpinner()
     this.mainservice.postApi(url,data,1).subscribe((res)=>{
       if(res['statusCode'] == 200){
        this.mainservice.hideSpinner()
        this.mainservice.successToast(res["responseMessage"])
        this.route.navigate(['/list-category'])

       }
       else{
        this.mainservice.hideSpinner()
        this.mainservice.errorToast(res["responseMessage"])
       }
     })

    }
   
    
}
