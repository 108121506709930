import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-view-subcategory',
  templateUrl: './view-subcategory.component.html',
  styleUrls: ['./view-subcategory.component.scss']
})
export class ViewSubcategoryComponent implements OnInit {

  catId: any;
  categoryList: any=[{
    _id : 12,
    categoryName : 'E-V Charger',
    createdAt : '22/02/2021',
    status : 'ACTIVE',
    categoryPic : 'assets/Product image/chargerImage-1.jpeg'
  }];
  viewSubCategoryData: ArrayBuffer;

  constructor(private activatedRouting:ActivatedRoute, public mainservice:CommonService) {
    this.activatedRouting.queryParams.subscribe((res:any)=>{
      this.catId=res.id
    })
   }

  ngOnInit() {
   
    this.getCatId()
  }
  getCatId() {
    let url  = "api/v1/admin/viewsubCategory?id="+this.catId;
    this.mainservice.showSpinner()
    this.mainservice.getApi(url,1).subscribe((res)=>{
      this.categoryList=res['result']
      console.log(this.categoryList);
      
      if(res['statusCode'] == 200){
        this.mainservice.hideSpinner()
        this.mainservice.successToast(res["responseMessage"])
       
       }
       else{
        this.mainservice.hideSpinner()
        this.mainservice.errorToast(res["responseMessage"])
       }
    })
  }

}

