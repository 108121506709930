<div class="wrapper">
    <main class="middle-content">
<!------Page Title Start------>
        <div class="page_title_block">
            <h1 class="page_title" style="color: black">Edit Profile</h1>
        </div>
<!------Page Title End------>

        <div class="content-section">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style">
                        <form [formGroup]="editForm">
                            <div class="user-profile" >
                                <div class="image-box">
                                    <img [src]="imgSrc ||'assets/img/avatar 1.png'" class="profile-pic" alt="" >
                                    <label class="upload-label">
                                       
                                        <input type="file" class="hidden" formControlName="image" name="image"
                                        accept="image/jpg,image/jpeg,image/png"
                                           
                                            (change)="uploadImg($event)">
                                        <i class="fas fa-camera" aria-hidden="true"></i>
                                    </label>
                                </div>
                            </div>
                            <div class="form-group row align-items-center">
                                <div class="col-md-4">
                                    <label class="textaligncenter bold">First Name </label>
                                </div>
                                <div class="col-md-1">:</div>
                                <div class="col-md-7">
                                    <input type="text" class="form-control form-control2" formControlName="firstname"
                                        maxlength="60" (keypress)="preventSpace($event)" />

                                    <span class="error">
                                        <p *ngIf="editForm.get('firstname').hasError('required') && editForm.get('firstname').dirty"
                                            class="error" padding>*Please enter first name.</p>
                                        <p *ngIf="editForm.get('firstname').hasError('pattern') && editForm.get('firstname').dirty"
                                            class="error" padding>*Please enter valid first name.</p>
                                    </span>
                                </div>
                            </div>

                            <div class="form-group row align-items-center">
                                <div class="col-md-4">
                                    <label class="textaligncenter bold">Last Name </label>
                                </div>
                                <div class="col-md-1">:</div>
                                <div class="col-md-7">
                                    <input type="text" class="form-control form-control2" formControlName="lastname"
                                        maxlength="60" (keypress)="preventSpace($event)" />
                                    <span class="error">
                                        <p *ngIf="editForm.get('lastname').hasError('required') && editForm.get('lastname').dirty"
                                            class="error" padding>*Please enter last name.</p>
                                        <p *ngIf="editForm.get('lastname').hasError('pattern') && editForm.get('lastname').dirty"
                                            class="error" padding>*Please enter valid last name.</p>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row align-items-center">
                                <div class="col-md-4">
                                    <label class="textaligncenter bold">Email </label>
                                </div>
                                
                                <div class="col-md-1">:</div>
                                <div class="col-md-7">
                                    <input type="email" class="form-control form-control2" formControlName="email"
                                        maxlength="60"  disabled/>
                                    <span class="error">
                                        <p *ngIf="editForm.get('email').hasError('required') && editForm.get('email').dirty"
                                        class="error" padding>*Please enter email.</p>
                                        <p *ngIf="editForm.get('email').hasError('pattern') && editForm.get('email').dirty"
                                            class="error" padding aria-disabled="true">*Please enter valid email.</p>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row align-items-center">
                                <div class="col-md-4">
                                    <label class="textaligncenter bold">Mobile Number </label>
                                </div>
                                
                                <div class="col-md-1">:</div>
                                <div class="col-md-7">
                                    <input type="text" mask="(000) 000-0000" class="form-control form-control2" formControlName="phone"
                                        maxlength="18" />
                                    <span class="error">
                                        <p *ngIf="editForm.get('phone').hasError('required') && editForm.get('phone').dirty"
                                        class="error" padding>*Please enter mobile number.</p>
                                        <p *ngIf="editForm.get('phone').hasError('pattern') && editForm.get('phone').dirty"
                                            class="error" padding>*Please enter valid mobile number.</p>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row align-items-center">
                                <div class="col-md-4">
                                    <label class="textaligncenter bold">Country </label>
                                </div>
                               
                                <div class="col-md-1">:</div>
                                <div class="col-md-7">
                                    <input type="text" class="form-control form-control2" formControlName="country"
                                        maxlength="60" (keypress)="preventSpace($event)" />
                                    <span class="error">
                                        <p *ngIf="editForm.get('country').hasError('required') && editForm.get('country').dirty"
                                        class="error" padding>*Please enter country.</p>
                                        <p *ngIf="editForm.get('country').hasError('pattern') && editForm.get('country').dirty"
                                            class="error" padding>*Please enter valid country name.</p>
                                    </span>
                                </div>
                            </div>

                            <div class="text-center mt40" style="position:relative;right:70px">
                                <button class="btn btn-large  max-WT-200 font-100 btn-green mr-2"
                                [disabled]="editForm['invalid']"  (click)="updateProfile()">Update Profile</button>
                                
                                <button  class="btn btn-large  max-WT-200 font-100 btn-green ml5"
                                    [routerLink]="['/my-profile']">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>

