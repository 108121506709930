import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPassword: FormGroup;

  constructor( private router: Router,public service: CommonService) { }

  ngOnInit() {  
    this.forgotPassword = new FormGroup({

      'email': new FormControl('', Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/i)),
    })
  }

  onForgot() {
    var url = "api/v1/admin/adminForgotPassword" 
    const data={
       email:this.forgotPassword.value.email
    }
    this.service.showSpinner();
    this.service.postApi(url, data,1).subscribe(res => {
      if (res['statusCode'] == 200) {
        this.service.hideSpinner();
        this.service.successToast(res["responseMessage"])
      }
     
    }, err => {
      this.service.hideSpinner();
      if (err['statusCode'] == '401') {
        // this.service.errorToast(err)
      } else {
        this.service.errorToast('Email address is not registered.');
      }
    }
    );
  }


  onResend() {
    if (!this.forgotPassword.value.email) {
      return this.service.errorToast('Enter email address.');
    }
    else if (this.forgotPassword.invalid) {
      this.service.errorToast('Enter valid email address.');
    }
    else {
      this.onForgot();
    }
  }
}
