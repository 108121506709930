<main class="middle-content">
    <!-- Page Title Start -->
     <div class="page_title_block">
        <h1 class="page_title" style="color:black">Edit
            {{type | titlecase}}
        </h1>
    </div>
    <!-- Page Title End -->

 <!-- Table Responsive Start -->
     <div class="content-section">
         <div class="order-view mt30 max-WT-700 mrgn-0-auto">
             <div class="main-block-innner mb40 mt40">
                 <div class=" input-style">
                     <div class="form-group row ">
                         <label class="col-md-4 bold">Page Name
                        </label>
                         <div class="col-md-8">
                             <textarea  class="form-control common-textarea"  rows="2" disabled >{{type |titlecase}}
                                </textarea>
                         </div>
                     </div>
                     <div class="form-group row ">
                         <label class="col-md-4 bold">Description
                        </label>
                     </div>
                     <div class="row">
                         <div class="col-md-12" *ngIf="editorValue" >    
                             <ck-editor  name="editor1" [(ngModel)]="editorValue" skin="moono-lisa" language="en" [fullPage]="true"></ck-editor>
                         </div>
                     </div>
                     
                     <div style="text-align: center ;margin-top:40px ;  position: relative; bottom: 22px;">
                        <button class="btn  btn-button" type="button" [routerLink]="['/static-content']" >Cancel</button>
                        <button class="btn  btn-button ml-4" type="button"   (click)=" updateStatic()" >Update</button>
                    </div>

                   
                 </div>
             </div>
         </div>
     </div>
 <!-- Table Responsive End -->
</main>




