<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title" style="color:black">Dashboard</h1>
        </div>
        <div class="content-section dashboard-block">     
            <ul class="dash_list d-flex w-100 flex-wrap  justify-content-center text-center">

                <li class="d-inline-flex align-items-center justify-content-center bg-dashboard-content">
                    <div class="w-100" [routerLink]="['/company-management']" >
                        <div class="dash_icn">
                            <i class="fa fa-users fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Users</h2>
                        <h4>{{totalUser}}</h4>
                    </div>
                </li>

                <li class="d-inline-flex align-items-center justify-content-center bg-dashboard-content">
                    <div class="w-100" [routerLink]="['/company-management']" >
                        <div class="dash_icn">
                            <i class="fa fa-user fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Active Users</h2>
                        <h4>{{totalActiveUser}}</h4>
                    </div>
                </li>

                <li class="d-inline-flex align-items-center justify-content-center bg-dashboard-content">
                    <div class="w-100" [routerLink]="['/company-management']" >
                        <div class="dash_icn">
                            <i class='fas fa-user-alt-slash' aria-hidden="true" style="font-size: 36px;"></i>
                        </div>
                        <h2>Total Deactive Users</h2>
                        <h4>{{totalDeactiveUser}}</h4>
                    </div>
                </li>

                <li class="d-inline-flex align-items-center justify-content-center bg-dashboard-content">
                    <div class="w-100" [routerLink]="['/job-management']">
                        <div class="dash_icn">
                            <i class="fa fa-briefcase  fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Jobs</h2>
                        <h4>{{totalJob}}</h4>
                    </div>
                </li>

                <li class="d-inline-flex align-items-center justify-content-center bg-dashboard-content">
                    <div class="w-100" [routerLink]="['/job-management']">
                        <div class="dash_icn">
                            <img src="assets/img/activejob.png" alt="icon" style="height: 45px;width: 45px; "/>                      
                        </div>
                        <h2>Total Active Jobs</h2>
                        <h4>{{totalActiveJob}}</h4>
                    </div>
                </li>

                <li class="d-inline-flex align-items-center justify-content-center bg-dashboard-content">
                    <div class="w-100" [routerLink]="['/job-management']">
                        <div class="dash_icn">
                            <img src="assets/img/deactivejob.png" alt="icon" style="height: 45px;width: 45px; "/>
                        </div>
                        <h2>Total Deactive Jobs</h2>
                        <h4>{{totalDeactiveJob}}</h4>
                    </div>
                </li>

              
                <li class="d-inline-flex align-items-center justify-content-center bg-dashboard-content">
                    <div class="w-100" [routerLink]="['/company-management']">
                        <div class="dash_icn">
                            <i class="fas fa-user-ninja fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Employer</h2>
                        <h4>{{totalHirer}}</h4>
                    </div>
                </li>

                <li class="d-inline-flex align-items-center justify-content-center bg-dashboard-content">
                    <div class="w-100" [routerLink]="['/job-seeker-management']">
                        <div class="dash_icn">
                            <img src="assets/img/Background.png" alt="icon" style="height: 45px;width: 45px; "/>
                        </div>
                        <h2>Total Applicants</h2>
                        <h4>{{totalApplicant}}</h4>
                    </div>
                </li>

                <li class="d-inline-flex align-items-center justify-content-center bg-dashboard-content">
                    <div class="w-100" [routerLink]="['/list-category']">
                        <div class="dash_icn">
                            <i class="fa fa-list-alt fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Category</h2>
                        <h4>{{totalCategory}}</h4>
                    </div>
                </li>
         </ul>
         </div>
    </main>
</div>
