<main class="middle-content">
<!-----page title start---->      
        <div class="page_title_block">
            <h1 class="page_title" style="color:black">Edit Faq's</h1>
        </div>
 <!-----page title end----> 

<!-----Table responsive start---->         
        <div class="content-section">
          <div class="order-view max-WT setting-page">
            <form [formGroup]="editForm" (input)="inputFunct()">
              <div class="mb40">
                <div class="input-style mb20 ">
                  <div class="form-group row  view-label-align">
                    <label class="col-md-3 bold ">Question
                      </label>
                      <span  class="col-md-1">:</span>

                    <div class="col-md-7">
                      <input type="text" class="form-control" maxlength="100" formControlName="question">
                      <div
                        *ngIf="editForm.get('question').invalid && (editForm.get('question').dirty || editForm.get('question').touched)"
                        class="text-danger">
                        <span *ngIf="editForm.get('question').hasError('required')"> *Please enter question.</span>
                        <span *ngIf="editForm.get('question').hasError('pattern')"> *Please enter valid question.</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row align-items-baseline view-label-align">
                    <label class="col-md-3 bold ">Answer
                     </label>
                      <span  class="col-md-1">:</span>

                    <div class="col-md-7">
                      <textarea class="form-control" formControlName="answer" name="" maxlength="350" id="" cols="30" rows="5"
                        (keypress)="mainService.preventSpace($event)"></textarea>
    
    
                      <div *ngIf="editForm.get('answer').invalid && (editForm.get('answer').dirty || editForm.get('answer').touched)"
                        class="text-danger">
    
                        <span *ngIf="editForm.get('answer').hasError('required')"> *Please enter answer.</span>
                      </div>
    
    
                    </div>
                  </div>
                </div>
               

                <div style="text-align: center ;margin-top:20px">
                  <button class="btn  btn-button" type="button" routerLink="/faq-list">Back</button>
                  <button class="btn  btn-button ml-4" type="button" [disabled]="!updateButton"
                  (click)="updateFaq()">Update</button>

              </div>
                
              </div>
            </form>
          </div>
        </div>
<!-----Table responsive end---->         
</main>
    
  
