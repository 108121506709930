import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-category-management',
  templateUrl: './category-management.component.html',
  styleUrls: ['./category-management.component.scss']
})
export class CategoryManagementComponent implements OnInit {

  itemPerPage: any = 10;
  currentPage: any = 1;
  total: any;
  categoryStatus: any;
  categoryList: any = [];
  flip: boolean = false;
  isSearched : boolean = false
  catId: any;
  index : any
  searchText : any 
  editCategoryName : any = []
  categoryName:any = []
  categoryId :any
 addForm:FormGroup
 resetPage : boolean= false
 id: any;
 status: any;
 data: any;

  constructor(public service: CommonService, private router: Router) {}

  ngOnInit() {
    this.searchFormValidation() 
    this.getAllCategoryList();
    
  }

  searchFormValidation() {
    this.addForm= new FormGroup({
      name: new FormControl(''),
      fromDate: new FormControl(""),
      toDate: new FormControl(""),
    });
  }

  //------get list of category-----//
  getAllCategoryList() {
    let url = "api/v1/admin/categoryList?page="+this.currentPage+"&limit="+this.itemPerPage
    this.service.showSpinner()
    this.service.getApi(url,1).subscribe((res)=>{
      if(res["statusCode"] == 200){
        this.categoryList = res["result"]['rows']
        this.total=res["result"]['count']
        this.service.hideSpinner()
        this.service.successToast(res["responseMessage"])
      }
      else{
        this.service.hideSpinner()
        this.service.errorToast(res["responseMessage"])
      }
    },(err)=>{
      this.categoryList=[]
      this.total=0
      this.service.hideSpinner()

    })
  }

  pagination(event) {
    this.currentPage = event;
    if (this.flip) {
      this.search();
    } else {
      this.getAllCategoryList();
    }
  }

  //
  search() {
    if(!this.addForm.value.name && !this.addForm.value.fromDate && !this.addForm.value.toDate){
      return
    }
    if(this.currentPage != 1 && !this.isSearched){
      this.currentPage = 1
    }
    this.isSearched = true
    this.flip =  true  
    
    const fromDate = new Date(this.addForm.value.fromDate)
    const toDate = new Date(this.addForm.value.toDate)
    let url=""
    if(this.addForm.value.name && !this.addForm.value.fromDate && !this.addForm.value.toDate){
     url="api/v1/admin/categoryList?categoryName="+this.addForm.value.name+"&page="+this.currentPage+"&limit="+this.itemPerPage
     
    }
    else if(!this.addForm.value.name && this.addForm.value.fromDate && !this.addForm.value.toDate){
      url = "api/v1/admin/categoryList?page="+this.currentPage+"&pageSize="+this.itemPerPage+"&startDate="+fromDate
     
    }
    else if(!this.addForm.value.name && this.addForm.value.fromDate && this.addForm.value.toDate){
      url = "api/v1/admin/categoryList?page="+this.currentPage+"&pageSize="+this.itemPerPage+"&startDate="+fromDate+"&endDate="+toDate
     
    }
    else if(this.addForm.value.name && this.addForm.value.fromDate && !this.addForm.value.toDate){
      url = "api/v1/admin/categoryList?categoryName="+this.addForm.value.name+"&page="+this.currentPage+"&pageSize="+this.itemPerPage+"&startDate="+fromDate
     
    }
    else{
      url = "api/v1/admin/categoryList?categoryName="+this.addForm.value.name+"&page="+this.currentPage+"&pageSize="+this.itemPerPage+"startDate="+fromDate+"&endDate="+toDate
    
    }
    this.service.showSpinner();
     this.service.getApi(url, 1).subscribe(
       (res) => {
         if (res['statusCode'] == 200) {
           this.categoryList = res['result']['rows']
           this.total = res['result']['count']
           this.service.hideSpinner();
           this.service.successToast(res['responseMessage']);
         } 
       },
       (err) => {
        this.categoryList = []
        this.total = 0
         this.service.hideSpinner();
       }
     );
  }
  
  reset() {
    if(!this.isSearched){
      return
    }
this.flip = false
this.addForm.value.name=""
this.isSearched = false
this. getAllCategoryList()
this.addForm.reset()
  }

  changeStat(){
    this.isSearched = false
   }

  delete(id) {
    this.catId = id;
  }

  
// block unblock category
getCategoryId(id, status) {
  this.id = id;
  this.status = status;
  }

blockUnblockCategory() {
  let url = ""
  if (this.status == "BLOCK") {
    url = "api/v1/admin/blockUnblockCategory?id="+this.id
  }else{
    url = "api/v1/admin/blockUnblockCategory?id="+this.id
  }
  this.service.showSpinner()
  this.service.putApi(url, {}, 1).subscribe((res) => {
    if (res["statusCode"] == 200) {
      this.getAllCategoryList()
      this.service.hideSpinner();
      this.service.successToast(res["responseMessage"]);
    } else {
      this.service.hideSpinner();
      this.service.errorToast(res["responseMessage"]);
    }
  });
  }

  viewCategory(id) {
    this.router.navigate(['/view-category'], { queryParams: { id: id } })
  }
 

}
