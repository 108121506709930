import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  userDetail: any;
  ProfileData: any;
  imgSrc: any;

  constructor(public service: CommonService, private router:Router) { }

  ngOnInit(): void {
    this.myProfile()
  }
  
  // get profile
  myProfile() {
    var url = 'api/v1/admin/getAdminProfile';
    this.service.showSpinner();
    this.service.getApi(url,1).subscribe(res => {
      if (res['statusCode'] == 200) {
        this.ProfileData = res['result']
        this.imgSrc=res['result']['profilePic']
        this.service.hideSpinner();
        this.service.successToast(res['responseMessage']);

      } else {
        this.service.hideSpinner();
        this.service.errorToast(res['responseMessage']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['statusCode'] == '401') {
        this.service.onLogout();
        this.service.errorToast('Unauthorized Access');
      } else {
        this.service.errorToast('Something Went Wrong');
      }
    })
  }

  changePassword() {
    this.router.navigate(['/change-password']);
  }
}
