<main class="middle-content">
<!----page title start ---->    
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">FAQs</h1>
    </div>
<!----page title End ---->  

<!----Table responsive start ---->    
    <div class="content-section" style="margin-bottom: 50px;">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                        <div>
                            <form>
                                <div class="row d-flex justify-content-end mb20">
                                    <div class="col-md-4">
                                        <div class="admin-btn justify-content-end " >
                                            <button type="button" class="btn  btn-button ml-2 Go_btn"
                                                (click)="addFaq()" >Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap">
                            <caption></caption>
                            <thead>
                                <tr class="no_wrap_th text-center">
                                    <th scope="col" class="Sorting-img"> S.No.</th>
                                    <th scope="col" class="Sorting-img">Question</th>
                                    <th scope="col" class="Sorting-img">Answer</th>
                                    <th scope="col" class="action_td_btn3 Sorting-img">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                    <tr *ngFor="let data of faqResult| paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems:total} ;let i = index" class="text-center">
                                        <td>{{itemsPerPage * (currentPage - 1) + i+1}}</td>
                                    <td class="content">{{data?.question}}</td>
                                    <td class="content" style="white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    max-width: 30em;">{{data?.answer}}</td>
                                    <td class="action_td_btn3" style="cursor: pointer">
                                       
                                            <a><em class="fa fa-eye"  (click)="viewUser(data?.id)" title="View"></em></a>
                                            <a><em class="fa fa-edit" (click)="editFaq(data?.id)" title="Edit"></em></a>
                                            <a><em class="fa fa-trash" (click)="deleteFaqModal(data?.id)"  data-target="#deleteModal" data-toggle="modal" title="Delete"></em></a>
                                    </td>
                                </tr>
                                
                                <tr *ngIf="total==0">
                                    <td colspan="11" vertical-align="middle">
                                        <div class="no-record">
                                            <div class="no-recordin">
                                                <H5>No record found</H5>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="custom-pagination mt20 text-center" style="float: right;" *ngIf="total>itemsPerPage">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
<!----Table responsive End---->    
</main>


<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
<div class="modal-dialog max-WT-500">
    <form class="change_password">
        <div class="modal-content">
            <div class="modal-header d-block text-center modal-header-custm">
                <h5 class="modal-title d-inline-block">Delete FAQs?</h5>
            </div>
            <div class="modal-inner-content">
                <div class="modal-body">
                    <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                        <div class="w-100">
                            <p>Are you sure you want to delete this faq?</p>
                            <div class="modal-btn-box">
                                <button type="submit" class="btn btn-button" data-dismiss="modal" (click)="deleteFaq()">Yes</button>
                                <button type="button" class="btn  btn-button ml-2" data-dismiss="modal" >No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
</div>
<!-- delete_modal End -->
