<main class="middle-content">
    <!-- Page Title Start -->
    <div *ngIf="Applicant" class="page_title_block">
        <h1 class="page_title" style="color:black">User Management </h1>
    </div>
    <div *ngIf="Hirer" class="page_title_block">
        <h1 class="page_title" style="color:black">User Management </h1>
    </div>
    <div *ngIf="3rd_Party_Contractor" class="page_title_block">
        <h1 class="page_title" style="color:black">User Management </h1>
    </div>
    <!-- Page Title end -->

    <!-- Tab Start -->
    <div class="row tab-container">
        <div class="col-md-3 change-content-tab" (click)="changeTab('Applicant')" [ngClass]="{'success': (activeTab == 'Applicant')}">
            <span>Applicants</span>
        </div>
        <div class="col-md-3 change-content-tab" (click)="changeTab('Hirer')" [ngClass]="{'success': activeTab == 'Hirer' }">
            <span>Employer/Contractor</span>
        </div>
        <div class="col-md-3 change-content-tab" (click)="changeTab('3rd_Party_Contractor')" [ngClass]="{'success': activeTab == '3rd_Party_Contractor' }">
            <span>Sub-Contractor</span>
        </div>
    </div>
    <!-- Tab end -->

    <!-- Applicant section start from here -->
    <div *ngIf="activeTab=='Applicant'" class="content-section" style="margin-bottom: 50px;">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <form [formGroup]="searchForm" (change)="changeStat()">

                        <div class="tab-pane1">

                            <div class="sec_head_new sec_head_new_after">
                                <div class="row align-items-center">
                                    <div class="col-md-4 col-lg-3 pr-0">
                                        <div class="filter_search mb20 width100">
                                            <span class="d-flex align-items-center bold">Search:</span>
                                            <div class="input-group filter_search_group">
                                                <input type="text" placeholder="Search by email" class="form-control" (keypress)="service.restrictSpace($event)" formControlName="email" style="height: 37px;">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-2 col-md-4 pr-0">
                                        <div class="filter_search mb20 width100">
                                            <span class="d-flex align-items-center bold">From Date:</span>
                                            <mat-form-field appearance="outline">
                                                <input matInput [matDatepicker]="picker" placeholder="mm/dd/yyyy" autocomplete="off" readonly formControlName="fromDate" [max]="service.today()">
                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>

                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-4 pr-0">
                                        <div class="filter_search mb20 width100">
                                            <span class="d-flex align-items-center bold">To Date:</span>
                                            <mat-form-field appearance="outline">
                                                <input matInput [matDatepicker]="picker1" placeholder="mm/dd/yyyy" formControlName="toDate" autocomplete="off" [disabled]="!searchForm.value.fromDate" [min]="searchForm.value.fromDate" readonly>
                                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                                <mat-datepicker #picker1></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="col-md-8 col-lg-4 user-pl" style="margin-bottom:20px;">
                                        <div class="user-frmbtn" style="display: flex;">
                                            <button type="submit" (click)="search()" class="btn  btn-button ">Search</button>
                                            <button type="submit" (click)="reset()" class="btn  btn-button ml-2">Reset</button>
                                            <button type="submit" (click)="exportAsXLSX()" class="btn  btn-button mr-2 col-md-6 col-lg-6" style="margin-left: 2%; font-size: 4px;">Export Excel</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="trade table">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th id="">S.No.</th>
                                        <th id="">Email Address</th>
                                        <th id="">Contact Number</th>
                                        <th id="">Status</th>
                                        <th id="">Created Date</th>
                                        <th id="">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of hirerList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: total} ;let i = index">
                                        <td class="content">{{itemsPerPage * (currentPage - 1) + i+1}}</td>
                                        <td class="content">{{data?.email||'--'}}</td>
                                        <td class="content">{{(data?.mobileNumber | mask: '(000) 000 0000')||'--'}}</td>
                                        <td class="content">{{data?.status||'--'}}</td>
                                        <td class="content">{{data?.createdAt| date : 'MM/dd/yyyy, h:mm a'||'--'}}</td>

                                        <td class="action_td_btn3" style="cursor: pointer">

                                            <a><em class="fa fa-eye" (click)="viewApplicant(data?.id)" title="View" ></em></a>

                                            <a *ngIf="data?.status == 'ACTIVE'" data-toggle="modal" data-target="#BlockModal" (click)="getApplicantId(data?.id,'BLOCK')" title="Active">
                                                <em class="fa fa-ban" aria-hidden="true" style="color: green;"></em>
                                            </a>
                                            <a *ngIf="data?.status == 'BLOCK'" data-toggle="modal" data-target="#BlockModal" (click)="getApplicantId(data?.id,'ACTIVE')" title="Block">
                                                <em class="fa fa-ban" aria-hidden="true" style="color: red;"></em>
                                            </a>
                                            <a><em class="fa fa-trash" (click)="deleteModal(data?.id)" title="Delete" data-toggle="modal" data-target="#deleteModal"></em></a>
                                        </td>
                                    </tr>
                                    <tr *ngIf="total==0">
                                        <td colspan="11" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5>No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="custom-pagination mt20 text-center" style="float: right;" *ngIf="total > itemsPerPage">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>
                    </form>
                </div>


            </div>
        </div>

    </div>

    <!-- Applicant section end from here -->

    <!-- Hirer section start from here -->
    <div *ngIf="activeTab=='Hirer'" class="content-section" style="margin-bottom: 50px;">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <form [formGroup]="searchForm" (change)="changeStat()">

                        <div class="tab-pane1">

                            <div class="sec_head_new sec_head_new_after">
                                <div class="row align-items-center">
                                    <div class="col-md-4 col-lg-3 pr-0">
                                        <div class="filter_search mb20 width100">

                                            <span class="d-flex align-items-center bold">Search:</span>
                                            <div class="input-group filter_search_group">

                                                <input type="text" placeholder="search by email" class="form-control" (keypress)="service.restrictSpace($event)" formControlName="email" style="height: 37px;">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-2 col-md-4 pr-0">
                                        <div class="filter_search mb20 width100">
                                            <span class="d-flex align-items-center bold">From Date:</span>
                                            <mat-form-field appearance="outline">
                                                <input matInput [matDatepicker]="picker" placeholder="mm/dd/yyyy" autocomplete="off" readonly formControlName="fromDate" [max]="service.today()">
                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-4 pr-0">
                                        <div class="filter_search mb20 width100">
                                            <span class="d-flex align-items-center bold">To Date:</span>
                                            <mat-form-field appearance="outline">
                                                <input matInput [matDatepicker]="picker1" placeholder="mm/dd/yyyy" formControlName="toDate" autocomplete="off" [disabled]="!searchForm.value.fromDate" [min]="searchForm.value.fromDate" readonly>
                                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                                <mat-datepicker #picker1></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="col-md-8 col-lg-4 user-pl" style="margin-bottom:20px;">
                                        <div class="user-frmbtn" style="display: flex;">
                                            <button type="submit" (click)="search()" class="btn  btn-button ">Search</button>
                                            <button type="submit" (click)="reset()" class="btn  btn-button ml-2">Reset</button>
                                            <button type="submit" (click)="exportAsXLSX()" class="btn  btn-button mr-2 col-md-6 col-lg-6" style="margin-left: 2%; font-size: 4px;">Export Excel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="trade table">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th id="">S.No.</th>
                                        <th id="">Email Address</th>

                                        <th id="">Contact Number</th>
                                        <th id="">Status</th>
                                        <th id="">Created Date</th>

                                        <th id="">Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of hirerList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: total} ;let i = index">
                                        <td class="content">{{itemsPerPage * (currentPage - 1) + i+1}}</td>
                                        <td class="content">{{data?.email||'--'}}</td>
                                        <td class="content">{{(data?.mobileNumber | mask: '(000) 000 0000')||'--'}}</td>
                                        <td class="content">{{data?.status||'--'}}</td>
                                        <td class="content">{{data?.createdAt| date : 'MM/dd/yyyy, hh:mm a'||'--'}}</td>

                                        <td class="action_td_btn3" style="cursor: pointer">

                                            <a><em class="fa fa-eye" (click)="viewHirer(data?.id)" ></em></a>

                                            <a *ngIf="data?.status == 'ACTIVE'" data-toggle="modal" data-target="#BlockModal" (click)="getApplicantId(data?.id,'BLOCK')" title="Active">
                                                <em class="fa fa-ban" aria-hidden="true" style="color: green;"></em>
                                            </a>
                                            <a *ngIf="data?.status == 'BLOCK'" data-toggle="modal" data-target="#BlockModal" (click)="getApplicantId(data?.id,'ACTIVE')" title="Block">
                                                <em class="fa fa-ban" aria-hidden="true" style="color: red;"></em>
                                            </a>
                                            <a><em class="fa fa-trash" (click)="deleteModal(data?.id)"  data-toggle="modal" data-target="#deleteModal"></em></a>
                                        </td>
                                    </tr>
                                    <tr *ngIf="total==0">
                                        <td colspan="11" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5>No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="custom-pagination mt20 text-center" style="float: right;" *ngIf="total > itemsPerPage">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>

                    </form>
                </div>
            </div>

        </div>
    </div>
    <!-- Hirer section end from here -->

    <!-- Third party contractor section start from here -->
    <div *ngIf="activeTab=='3rd_Party_Contractor'" class="content-section" style="margin-bottom: 50px;">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <form [formGroup]="searchForm" (change)="changeStat()">

                        <div class="tab-pane1">

                            <div class="sec_head_new sec_head_new_after">
                                <div class="row align-items-center">
                                    <div class="col-md-4 col-lg-3 pr-0">
                                        <div class="filter_search mb20 width100">

                                            <span class="d-flex align-items-center bold">Search:</span>
                                            <div class="input-group filter_search_group">
                                                <input type="text" placeholder="search by email" class="form-control" (keypress)="service.restrictSpace($event)" formControlName="email" style="height: 37px;">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-2 col-md-4 pr-0">
                                        <div class="filter_search mb20 width100">
                                            <span class="d-flex align-items-center bold">From Date:</span>
                                            <mat-form-field appearance="outline">
                                                <input matInput [matDatepicker]="picker" placeholder="mm/dd/yyyy" autocomplete="off" readonly formControlName="fromDate" [max]="service.today()">
                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>

                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-4 pr-0">
                                        <div class="filter_search mb20 width100">
                                            <span class="d-flex align-items-center bold">To Date:</span>
                                            <mat-form-field appearance="outline">
                                                <input matInput [matDatepicker]="picker1" placeholder="mm/dd/yyyy" formControlName="toDate" autocomplete="off" [disabled]="!searchForm.value.fromDate" [min]="searchForm.value.fromDate" readonly>
                                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                                <mat-datepicker #picker1></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>


                                    <div class="col-md-8 col-lg-4 user-pl" style="margin-bottom:20px;">
                                        <div class="user-frmbtn" style="display: flex;">
                                            <button type="submit" (click)="search()" class="btn  btn-button ">Search</button>
                                            <button type="submit" (click)="reset()" class="btn  btn-button ml-2">Reset</button>
                                            <button type="submit" (click)="exportAsXLSX()" class="btn  btn-button mr-2 col-md-6 col-lg-6" style="margin-left: 2%; font-size: 4px;">Export Excel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="trade table">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th id="">S.No.</th>
                                        <th id="">Email Address</th>

                                        <th id="">Contact Number</th>
                                        <th id="">Status</th>
                                        <th id="">Created Date</th>

                                        <th id="">Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of hirerList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: total} ;let i = index">
                                        <td class="content">{{itemsPerPage * (currentPage - 1) + i+1}}</td>
                                        <td class="content">{{data?.email||'--'}}</td>
                                        <td class="content">{{(data?.mobileNumber | mask: '(000) 000 0000')||'--'}}</td>
                                        <td class="content">{{data?.status||'--'}}</td>
                                        <td class="content">{{data?.createdAt| date : 'MM/dd/yyyy, hh:mm a'||'--'}}</td>

                                        <td class="action_td_btn3" style="cursor: pointer">

                                            <a><em class="fa fa-eye" (click)="viewThirdParty(data?.id)" ></em></a>

                                            <a *ngIf="data?.status == 'ACTIVE'" data-toggle="modal" data-target="#BlockModal" (click)="getApplicantId(data?.id,'BLOCK')" title="Active">
                                                <em class="fa fa-ban" aria-hidden="true" style="color: green;"></em>
                                            </a>
                                            <a *ngIf="data?.status == 'BLOCK'" data-toggle="modal" data-target="#BlockModal" (click)="getApplicantId(data?.id,'ACTIVE')" title="Block">
                                                <em class="fa fa-ban" aria-hidden="true" style="color: red;"></em>
                                            </a>
                                            <a><em class="fa fa-trash" (click)="deleteModal(data?.id)"  data-toggle="modal" data-target="#deleteModal"></em></a>
                                        </td>
                                    </tr>
                                    <tr *ngIf="total==0">
                                        <td colspan="11" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5>No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="custom-pagination mt20 text-center" style="float: right;" *ngIf="total > itemsPerPage">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>

                    </form>
                </div>
            </div>

        </div>
    </div>
</main>
<!-- Third party contractor section end from here -->


<!-- block_modal Start -->
<div class="modal fade global-modal reset-modal" id="BlockModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">

                    <h5 class="modal-title d-inline-block" style="text-transform: capitalize;">{{status | lowercase}}</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">

                                <p *ngIf="activeTab=='Applicant'">Are you sure you want to {{status | lowercase}} this applicant?</p>
                                <p *ngIf="activeTab=='Hirer'">Are you sure you want to {{status | lowercase}} this Employer/Contractor?</p>
                                <p *ngIf="activeTab=='3rd_Party_Contractor'">Are you sure you want to {{status | lowercase}} this Sub-Contractor?</p>

                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-primary" (click)="blockUnblockJob()" data-dismiss="modal">Yes</button>
                                    <button type="button" class="btn btn-secondary  ml-2" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- block_modal end -->

<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">Delete</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p *ngIf="activeTab=='Applicant'">Are you sure you want to delete this applicant?</p>
                                <p *ngIf="activeTab=='Hirer'">Are you sure you want to delete this Employer/Contractor?</p>
                                <p *ngIf="activeTab=='3rd_Party_Contractor'">Are you sure you want to delete this Sub-Contractor?</p>
                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-primary" data-dismiss="modal" (click)="deletehirer()">Yes</button>
                                    <button type="button" class="btn btn-secondary ml-2" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal end-->