<main class="middle-content">
    <!-- Page Title Start -->
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">Edit Category</h1>
    </div>
    <!-- Page Title End -->
<!-----table responsive start---->
    <div class="content-section">
        <form >
            <div class="outer-box">
                <div class="custom_tabs common-tabs">
                    <form [formGroup]="addForm">
                    <div class="tab-content card" style="padding: 2%; max-width: 850px; margin: auto;">
                     
                        <div class="myAlign">
                            <div class="form-group row " >
                                <label class="col-md-5 bold">Category Name</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">
                                    <input type="text" class="form-control"  placeholder="Category Name" formControlName="name" maxlength="100">
                                    <div
                                    *ngIf="addForm.controls['name'].hasError('required') && addForm.controls['name'].dirty">
                                    <span class="error">*Please enter category name.</span>
                                </div>
                                </span>
                                
                            </div>   
                        </div>
                        <div style="text-align: center ;margin-top:20px ;position: relative;right: 50px;">
                            <button class="btn  btn-button ml-10" type="button" routerLink="/list-category">Back</button>
                            <button class="btn  btn-button ml-4" type="button"  [disabled]="addForm['invalid']" (click)=" updateCategory()">Update</button>
                        </div>   
    
                    </div>
                </form>
                </div>
            </div>
        </form>
    </div>
<!-----table responsive end---->
</main>