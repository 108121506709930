import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  totalJob: any;
  totalApplicant: any;
  totalActiveJob: any;
  totalHirer: any;
  totalUser: any;
  totalCategory: any;
  totalDeactiveJob: any;
  totalActiveUser: any;
  totalDeactiveUser: any;

  constructor(public service:CommonService,private router: Router) { }

  ngOnInit(): void {
    this.dashboard()
  }
  dashboard() {
    this.service.showSpinner()
    this.gettotalJob()
    this.service.hideSpinner()
  }

  //-----getting data of dashboard----//
  gettotalJob() {
    let url = "api/v1/admin/adminDashboard"
    this.service.getApi(url, 1).subscribe((res) => {
      if (res['statusCode'] == 200) {
        this.totalJob = res['result']['totalJob']
        this.totalApplicant=res['result']['applicant']
        this.totalActiveJob=res['result']['activeJob']
        this.totalDeactiveJob=res['result']['totalBlockJob']+res['result']['totalDeleteJob']
        this.totalHirer=res['result']['hirer']
        this.totalUser=res['result']['totalUser']
        this.totalActiveUser=res['result']['totalActiveUser']
        this.totalDeactiveUser=res['result']['totalBlockUser']+res['result']['totalDeleteUser']
        this.totalCategory=res['result']['category']
        this.service.hideSpinner()
        this.service.successToast(res['responseMessage'])
      }
      else {
        this.service.hideSpinner()
        this.service.errorToast(res['responseMessage'])

      }
    })
  }
 
}
