import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
declare var $
@Component({
  selector: 'app-view-logo',
  templateUrl: './view-logo.component.html',
  styleUrls: ['./view-logo.component.scss']
})
export class ViewLogoComponent implements OnInit {

  logoList=[]
  logoData: any;
  id: any;
   
   constructor( private router: Router, public service: CommonService,private activatedRoute: ActivatedRoute) { 
    this.activatedRoute.queryParams.subscribe((res: any) => {
      this.id = res.logoId
    }
    )
   }
 
  ngOnInit(): void {
     this.getLogoList();
   }

  //-------get individual data of logo-------//
 getLogoList(){
  let url="api/v1/admin/getLogoParticular?id="+this.id
  this.service.getApi(url, 1).subscribe((res: any) => {
    this.service.showSpinner()
    if (res["statusCode"] == 200) {
      this.logoData = res["result"]
      this.service.hideSpinner();
      this.service.successToast("Success");
    } else {
      this.service.hideSpinner();
      this.service.errorToast("Something went wrong");
    }
  })
 }
 back() {
  this.router.navigate(['/logo-list'])
}
 }
