import { variable } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-help-reply',
  templateUrl: './help-reply.component.html',
  styleUrls: ['./help-reply.component.scss']
})
export class HelpReplyComponent implements OnInit {

  editForm: FormGroup ;
  faqList: any;
  id: any;
  updateButton : boolean = false
  constructor(private activatedroute : ActivatedRoute , private router : Router,public mainService: CommonService) { 
    this.activatedroute.queryParams.subscribe((res) => {
      this.id = res.id;
    })
  }

  ngOnInit(): void {
    this.editForm = new FormGroup({
      "name" : new FormControl('' ) ,
      "email" : new FormControl('') ,
      "subject" : new FormControl('') ,
      "reply" : new FormControl('',Validators.required) ,
    });
    this.getReplyHelp()
  }

  //-----reply for help functionality----//
  getReplyHelp(){
    let url = "api/v1/admin/viewHelp?id="+this.id
    this.mainService.showSpinner()
    this.mainService.getApi(url,1).subscribe((res)=>{
      console.log(res);    
      if(res['statusCode'] == 200){
        console.log(res)
        this.editForm.patchValue({
          name : res['result']['name'],
          email : res['result']['email'],
          subject : res['result']['subject'],
          reply:res['result']['reply']
        })
        this.mainService.hideSpinner()
        this.mainService.successToast(res['responseMessage'])
      }
      else{
        this.mainService.hideSpinner()
        this.mainService.errorToast(res['responseMessage'])
      }
    })
   }
  
   updateReply(){
    let url = "api/v1/admin/replyHelp?id"+this.id+"&reply="+this.editForm.value.reply
    console.log(this.editForm.touched,this.editForm.dirty);
  
    const data = {
      "reply": this.editForm.value.reply
    }
    this.mainService.showSpinner()
    this.mainService.postApi(url,data,1).subscribe((res)=>{      
      if(res['statusCode'] == 200){  
        this.mainService.hideSpinner()
        this.mainService.successToast(res['responseMessage'])
        this.router.navigate(['/help-list'])
      }
      else{
        this.mainService.hideSpinner()
        this.mainService.errorToast(res['responseMessage'])
      }
    })
   }
  
}
