import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
declare var $ :any
@Component({
  selector: 'app-sucategory-list',
  templateUrl: './sucategory-list.component.html',
  styleUrls: ['./sucategory-list.component.scss']
})
export class SucategoryListComponent implements OnInit {

  total: any
  itemPerPage = 10
  currentPage = 1
  flip : boolean=false
  isSearched : boolean =false
 name:any
  subCategoryData: any;
  catId: any;
  searchForm:FormGroup
  resetPage : boolean= false
  id: any;
 status: any;
 data: any;


  constructor(public service: CommonService, private router: Router) { }

  ngOnInit() {
    this.searchFormValidation()
    this.getSubcategoryList() 
  }
  
  searchFormValidation() {
    this.searchForm= new FormGroup({
      name: new FormControl(''),
      fromDate: new FormControl(""),
      toDate: new FormControl(""),
    });
  }

//------- get list of sub category ------//
  getSubcategoryList() {
    let url = "api/v1/admin/subCategoryList?page="+this.currentPage+"&limit="+this.itemPerPage
    this.service.showSpinner()
    this.service.getApi(url,1).subscribe((res)=>{
     if(res['statusCode'] == 200){
        this.subCategoryData = res["result"]["rows"]
         this.total = res["result"]["count"]
         this.service.hideSpinner()
       this.service.successToast(res["responseMessage"])

      }else{
        this.service.hideSpinner()
        this.service.successToast(res["responseMessage"])
      }
    },(err)=>{
      this.subCategoryData=[]
      this.total=0
      this.service.hideSpinner()
    })
   
  }

//------- search functionality----------//
  search() {
    if(!this.searchForm.value.name && !this.searchForm.value.fromDate && !this.searchForm.value.toDate){
      return
    }
    if(this.currentPage != 1 && !this.isSearched){
      this.currentPage = 1
    }
    this.isSearched = true
    this.flip =  true  
    
    const fromDate = new Date(this.searchForm.value.fromDate)
    const toDate = new Date(this.searchForm.value.toDate)
    let url=""
    if(this.searchForm.value.name && !this.searchForm.value.fromDate && !this.searchForm.value.toDate){
     url="api/v1/admin/subCategoryList?subCatName="+this.searchForm.value.name+"&page="+this.currentPage+"&limit="+this.itemPerPage
     
    }
    else if(!this.searchForm.value.name && this.searchForm.value.fromDate && !this.searchForm.value.toDate){
      url = "api/v1/admin/subCategoryList?page="+this.currentPage+"&pageSize="+this.itemPerPage+"&startDate="+fromDate
     
    }
    else if(!this.searchForm.value.name && this.searchForm.value.fromDate && this.searchForm.value.toDate){
      url = "api/v1/admin/subCategoryList?page="+this.currentPage+"&pageSize="+this.itemPerPage+"&startDate="+fromDate+"&endDate="+toDate
     
    }
    else if(this.searchForm.value.name && this.searchForm.value.fromDate && !this.searchForm.value.toDate){
      url = "api/v1/admin/subCategoryList?subCatName="+this.searchForm.value.name+"&page="+this.currentPage+"&pageSize="+this.itemPerPage+"&startDate="+fromDate
     
    }
    else{
      url = "api/v1/admin/subCategoryList?subCatName="+this.searchForm.value.name+"&page="+this.currentPage+"&pageSize="+this.itemPerPage+"startDate="+fromDate+"&endDate="+toDate
    
    }
    this.service.showSpinner();
     this.service.getApi(url, 1).subscribe(
       (res) => {
         if (res['statusCode'] == 200) {
           this.subCategoryData = res['result']['rows']
           this.total = res['result']['count']
           this.service.hideSpinner();
           this.service.successToast(res['responseMessage']);
         } 
         
       },
       (err) => {
        this.subCategoryData = []
        this.total = 0
         this.service.hideSpinner();
       }
     );
  }

//------- reset functionality----------//
  reset() {
    if(!this.isSearched){
      return
    }
this.flip = false
this.searchForm.value.name=""
this.isSearched = false
this.getSubcategoryList() 
this.searchForm.reset()
  }

  changeStat(){
    this.isSearched = false
   }

  //------- delete subcategory ------//
  deleteFunction(id) {
   this.catId = id
  }
  deteleCategory() {
    let url = "api/v1/admin/deleteSubcategory?id="+this.catId
    this.service.showSpinner()
    this.service.deleteApi(url,{},1).subscribe((res)=>{
      if(res["statusCode"] == 200){
        this.getSubcategoryList() ;
        this.service.hideSpinner()
        this.service.successToast(res["responseMessage"])

      }
      else{
        this.service.hideSpinner()
        this.service.errorToast(res["responseMessage"])
      }
    })
  }

  // block unblock sub category
getSubCategoryId(id, status) {
  this.id = id;
  this.status = status;

  }
blockUnblockSubCategory() {
  let url = ""
  if (this.status == "BLOCK") {
    url = "api/v1/admin/blockUnblockSubCategory?id="+this.id
  }else{
    url = "api/v1/admin/blockUnblockSubCategory?id="+this.id
  }
  this.service.showSpinner()
  this.service.putApi(url, {}, 1).subscribe((res) => {
    if (res["statusCode"] == 200) {
      this.getSubcategoryList()
      this.service.hideSpinner();
      this.service.successToast(res["responseMessage"]);
    } else {
      this.service.hideSpinner();
      this.service.errorToast(res["responseMessage"]);
    }
  });
  }
//-------pagination ---------//
  pagination(event) {
    this.currentPage = event;
    if (this.flip) {
      this.search();
    } else {
      this.getSubcategoryList() 
    }
  }

  viewsubCategory(id) {
    this.router.navigate(['/view-sub-category'], { queryParams: { id: id } })
  }
  // editCategory(id) {
  //   this.router.navigate(['/edit-sub-category'], { queryParams: { id: id } })
  // }

}
