import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm:FormGroup
  ipAddress : any
  userAgent : any
  currLat : any
  currLng : any
  oldPasswordType:any="password"

  constructor(private fb: FormBuilder, private router:Router, public service: CommonService) { }

  ngOnInit(): void {
    console.log(this.service.isLoggedIn());
    
    this.checkInput()
    if(this.service.isLoggedIn()){
      this.router.navigate(['/dashboard'])
    }
   
    this. service.getIPAddress().subscribe((res:any)=>{  
      this.ipAddress=res.ip;  
     
    })
   this.userAgent = this.getBrowserName()
  }
 

  //----------------- Login form validation -----------------//
  checkInput() {
    let remData = JSON.parse(localStorage.getItem('loginData')) ? JSON.parse(localStorage.getItem('loginData')) : '';
    let pass = ''
    if (remData != '') {
      pass = window.atob(remData.password)
    }

    this.loginForm = this.fb.group({
      email: new FormControl(remData.email, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,9}|[0-9]{1,3})(\]?)$/i)),
      password: new FormControl(pass, [Validators.required]),
      rememberMe: new FormControl(remData.rememberMe)
    })
  }

  // ------------------ login ------------------ //
  onLogin() {
  const body = {
    "email":this.loginForm.value.email,
    "password":this.loginForm.value.password 
  }
  console.log(body);
  let url="api/v1/admin/login"
  this.service.showSpinner()
  this.service.postApi(url,body,0).subscribe((res)=>{
    console.log(res)
    if(res["statusCode"] == 200){
      localStorage.setItem("token",res["result"].token)
      this.service.successToast(res["responseMessage"])
      if(this.loginForm.value.rememberMe){
        var loginData = {
          email : this.loginForm.value.email,
          password : btoa(this.loginForm.value.password),
          rememberMe : this.loginForm.value.rememberMe
        }
        localStorage.setItem('loginData',JSON.stringify(loginData))
      }else{
        localStorage.removeItem('loginData')
      }
      this.service.hideSpinner()
      this.router.navigate(['/dashboard'])
    }
    else{
      this.service.hideSpinner()
      this.service.successToast(res["responseMessage"])
    }
  })
}

public getBrowserName() {
  const agent = window.navigator.userAgent.toLowerCase()
  switch (true) {
    case agent.indexOf('edge') > -1:
      return 'edge';
    case agent.indexOf('opr') > -1 && !!(<any>window).opr:
      return 'opera';
    case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
      return 'chrome';
    case agent.indexOf('trident') > -1:
      return 'ie';
    case agent.indexOf('firefox') > -1:
      return 'firefox';
    case agent.indexOf('safari') > -1:
      return 'safari';
    default:
      return 'other';
  }
}

viewOld(val){
  if(val)
  {
    this.oldPasswordType="text"
  }
  else{
    this.oldPasswordType="password"
  }
}
}
