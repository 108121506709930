import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-edit-subcategory',
  templateUrl:'./edit-subcategory.component.html',
  styleUrls: ['./edit-subcategory.component.scss']
})
export class EditSubcategoryComponent implements OnInit {

  addForm: FormGroup
  imgSrc : any
  catId: string;
  subCategoryName: any;
  categoryData: any;
  
  
    constructor(private route: Router, public service: CommonService, public activatedRouting:ActivatedRoute) { 
      this.activatedRouting.queryParams.subscribe((res:any)=>{
        this.catId=res.id
      })
    }
  
    ngOnInit() {
      this.addFormvalidation()
      this. getSubcategory()
      this.getCategoryData()
    }

    addFormvalidation() {
      this.addForm = new FormGroup({
        'categoryname': new FormControl("", [Validators.required]),
        'name': new FormControl("", [Validators.required]),

      })
    }

    //-----get list of subcategory -----//
    getSubcategory(){
      let url ="api/v1/admin/viewsubCategory?id="+this.catId;
      this.service.showSpinner()
      this.service.getApi(url,1).subscribe((res)=>{
        if (res['statusCode'] ==200) {
          this.subCategoryName=res['result']['subCatName']
          this.addForm.patchValue({
            name:this.subCategoryName
          })
          this.service.hideSpinner()
          this.service.successToast(res['responseMessage'])
        } else {
          this.service.hideSpinner()
          this.service.errorToast(res['responseMessage'])
        }
      })
    }
    
    //-----update subcategory -----//
    updateSubcategory(){
      let url = "api/v1/admin/editsubCategory?id="+this.catId
      const data = {
        "subCatName"  :this.addForm.value.name, 
        "cat_id":this.catId
      }
    
      this.service.putApi(url,data,1).subscribe((res)=>{
        if (res['statusCode'] ==200) {
          this.service.hideSpinner()
          this.service.successToast(res['responseMessage'])
          this.route.navigate(['/subCategory-list'])

        } else {
          this.service.hideSpinner()
          this.service.errorToast(res['responseMessage'])
        }
      },
      (err)=>{
        this.service.hideSpinner()
        this.service.errorToast("This subcategory already exists.")
        
      })      
    }
  
   
//------get category list ------// 
    getCategoryData(){
      let url = "api/v1/jobs/categoryList"
      this.service.showSpinner()
      this.service.getApi(url,0).subscribe((res)=>{
        if(res['statusCode'] == 200){
          this.categoryData=res['result']
         this.service.hideSpinner()
         this.service.successToast(res["responseMessage"])
        
        }
        else{
         this.service.hideSpinner()
         this.service.errorToast(res["responseMessage"])
        }
      })
 
     }  
   

}