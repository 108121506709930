import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm:FormGroup
  email:any
  constructor(public service:CommonService ,private router:Router,private activate : ActivatedRoute) {
   this.email=this.activate.snapshot.paramMap.get('email')
   console.log(this.email);
   }

  ngOnInit(): void {
    this.resetPasswordForm = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}')]),
      confirmPassword: new FormControl('', Validators.required)
    })
  }

  // Reset Password Functionality
  resetPasswordFunc() {
    const data={
      "newPassword":this.resetPasswordForm.value. password,
      "confirmPassword":this.resetPasswordForm.value.confirmPassword
   }
    let url="api/v1/admin/resetPassword/"+this.email
    this.service.showSpinner();
    this.service.putApi(url,data,1).subscribe(res => {
      this.service.hideSpinner();
      if (res['statusCode'] == 200) {
        this.service.successToast('Password set successfully.');
        this.router.navigate(['/login'])
      }
    }, err => {
      this.service.hideSpinner();
      if (err['statusCode'] == '401') {
        this.service.errorToast('Unauthorized access.');
      } else {
        this.service.errorToast('Something went wrong.');
      }
    })
  }

}


