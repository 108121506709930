import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { EventEmitterService } from 'src/app/provider/event-emitter.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  editForm:FormGroup
  editImage:any
  imgSrc:any
  src : any
  profileData: any;
  constructor(public service:CommonService, private router:Router,private eventEmitterService: EventEmitterService) { }

  ngOnInit(): void {
    this.searchFormValidation()
    this.getProfile()
   
  }

  searchFormValidation(){
    this.editForm = new FormGroup({
      'firstname': new FormControl('',[Validators.required, Validators.pattern(/^[a-zA-Z ]*$/i)]),
      'lastname': new FormControl('', [Validators.required,Validators.pattern(/^[a-zA-Z ]*$/i)]),
      'country': new FormControl('',[Validators.required, Validators.pattern(/^[a-zA-Z ]*$/i)]),
      'email': new FormControl('',[Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,9}|[0-9]{1,3})(\]?)$/i)]),
      'phone': new FormControl('',[Validators.required, Validators.pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)]),
      'image':new FormControl('')
    })
  }

  //---- get  profile data------//
  getProfile(){
    let url="api/v1/admin/getAdminProfile"
    this.service.getApi(url,1).subscribe((res)=>{
      this.service.showSpinner()
      if(res["statusCode"]==200){
        this.profileData=res["result"]
        this.editForm.patchValue({
          'firstname': this.profileData.firstName,
          'lastname':this.profileData.lastName,
          'email': this.profileData.email,
          'phone': this.profileData.mobileNumber,
          'country': this.profileData.country,
          })
        this.imgSrc = this.profileData.profilePic
        this.service.hideSpinner()
        this.service.successToast(res["responseMessage"])
      }
      else{
        this.service.hideSpinner()
        this.service.errorToast(res["responseMessage"])
      }
    })
  }

 
  //---- update profile------//
  updateProfile() {
    var form = new FormData()
    form.append('country',this.editForm.value.country)
    form.append('firstName',this.editForm.value.firstname)
    form.append('lastName',this.editForm.value.lastname)
    form.append('mobileNumber',this.editForm.value.phone)
    form.append('email',this.editForm.value.email)
    form.append('profilePic',this.imgSrc? this.imgSrc : this.profileData.profilePic)
    this.service.showSpinner();
    this.service.putFormDataApi('api/v1/admin/editAdminProfile', form,1).subscribe(res => {
      this.service.hideSpinner();
      if (res['statusCode'] == 200) {
        this.getProfile();
        this.service.successToast('Profile updated successfully.');
        this.eventEmitterService.onFirstComponentButtonClick();

        this.service.changeLoginSub('login')
        this.router.navigate(['my-profile']);
      } else {
        this.service.errorToast(res['responseMessage']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['statusCode'] == '401') {
        this.service.onLogout();
        this.service.errorToast('Unauthorized access.');
      } else {
        this.service.errorToast('Something went wrong.');
      }
    })
  }

  //---- upload image functionality------//
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var form = new FormData();
    form.append('file', img)
    this.imgSrc = "assets/img/1488.gif"
  
    this.service.uploadImage('api/v1/user/uploadFile',form,1).subscribe(res => {
      if (res['statusCode'] == '200') {
        this.imgSrc = res['result'];
        this.service.hideSpinner();
        this.service.successToast(res["responseMessage"])
      }
      else{
        this.service.hideSpinner()
        this.service.errorToast(res['responseMessage'])
      }
    }, err => {
      this.service.hideSpinner();
      if (err['statusCode'] == '401') {
        this.service.onLogout();
        this.service.errorToast('Unauthorized Access');
      } else {
        this.service.errorToast('Something Went Wrong');
      }
    })
    
  }

  preventSpace(event) {
    if (event.charCode == 32 && !event.target.value) {
      event.preventDefault()
    } else {
    }
  }


}
