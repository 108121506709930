<main class="middle-content">
    <!-- Page Title Start -->
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">Edit Job Seeker</h1>
    </div>
    <!-- Page Title End -->

    <!--Table Responsive Start-->
    <div class="content-section">
        <form>
            <div class="outer-box">
                <div class="custom_tabs common-tabs">
                    <form [formGroup]="addJobseekerForm">
                        <div class="tab-content card" style="padding: 2%; max-width: 850px; margin: auto;">

                            <div class="myAlign">
                                <div class="form-group row ">
                                    <label class="col-md-5 bold">First Name</label>
                                    <span class="col-md-1">:</span>
                                    <span class="col-md-6">
                                        <input type="text" class="form-control" name="" id="" placeholder=""
                                            formControlName="firstName" maxlength="100">
                                        <div
                                            *ngIf="addJobseekerForm.controls['firstName'].hasError('required') && addJobseekerForm.controls['firstName'].dirty">
                                            <span class="error">*please enter first name.</span>
                                        </div>
                                    </span>

                                </div>
                                <div class="form-group row ">
                                    <label class="col-md-5 bold">Last Name</label>
                                    <span class="col-md-1">:</span>
                                    <span class="col-md-6">
                                        <input type="text" class="form-control" name="" id="" placeholder=""
                                            formControlName="lastName" maxlength="100">
                                        <div
                                            *ngIf="addJobseekerForm.controls['lastName'].hasError('required') && addJobseekerForm.controls['lastName'].dirty">
                                            <span class="error">*please enter last name.</span>
                                        </div>
                                    </span>

                                </div>
                                <div class="form-group row ">
                                    <label class="col-md-5 bold">Email</label>
                                    <span class="col-md-1">:</span>
                                    <span class="col-md-6">
                                        <input type="text" class="form-control" name="" id="" placeholder=""
                                            formControlName="email" maxlength="60">
                                        <div
                                            *ngIf="addJobseekerForm.controls['email'].hasError('required') && addJobseekerForm.controls['email'].dirty">
                                            <span class="error">*please enter email.</span>
                                        </div>
                                        <div
                                            *ngIf="addJobseekerForm.controls['email'].hasError('pattern') && addJobseekerForm.controls['email'].dirty">
                                            <span class="error">*Please enter valid email.</span>
                                        </div>

                                    </span>

                                </div>

                                <div class="form-group row ">
                                    <label class="col-md-5 bold">Job Field</label>
                                    <span class="col-md-1">:</span>
                                    <span class="col-md-6">
                                        <input type="text" class="form-control" name="" id="" placeholder=""
                                            formControlName="jobField" maxlength="50">
                                        <div
                                            *ngIf="addJobseekerForm.controls['jobField'].hasError('required') && addJobseekerForm.controls['jobField'].dirty">
                                            <span class="error">*please enter job field.</span>
                                        </div>
                                    </span>

                                </div>

                                <div class="form-group row ">
                                    <label class="col-md-5 bold">Zip Code</label>
                                    <span class="col-md-1">:</span>
                                    <span class="col-md-6">
                                        <input type="text" class="form-control" name="" id="" placeholder=""
                                            formControlName="zipCode" maxlength="30">
                                        <div
                                            *ngIf="addJobseekerForm.controls['zipCode'].hasError('required') && addJobseekerForm.controls['zipCode'].dirty">
                                            <span class="error">*please enter zip code.</span>
                                        </div>
                                    </span>

                                </div>

                                <div class="form-group row ">
                                    <label class="col-md-5 bold">Years Of Experience</label>
                                    <span class="col-md-1">:</span>
                                    <span class="col-md-6">
                                        <input type="text" class="form-control" name="" id="" placeholder=""
                                            formControlName="yearsOfExperience" maxlength="2">
                                        <div
                                            *ngIf="addJobseekerForm.controls['yearsOfExperience'].hasError('required') && addJobseekerForm.controls['yearsOfExperience'].dirty">
                                            <span class="error">*please enter year of experience.</span>
                                        </div>
                                    </span>

                                </div>

                                <div class="form-group row ">
                                    <label class="col-md-5 bold">Expertise</label>
                                    <span class="col-md-1">:</span>
                                    <span class="col-md-6">
                                        <input type="text" class="form-control" name="" id="" placeholder=""
                                            formControlName="expertise" maxlength="100">
                                        <div
                                            *ngIf="addJobseekerForm.controls['expertise'].hasError('required') && addJobseekerForm.controls['expertise'].dirty">
                                            <span class="error">*please enter expertise.</span>
                                        </div>
                                    </span>

                                </div>
                                <div class="form-group row ">
                                    <label class="col-md-5 bold">Radius Search</label>
                                    <span class="col-md-1">:</span>
                                    <span class="col-md-6">
                                        <input type="text" class="form-control" name="" id="" placeholder=""
                                            formControlName="RadiusSearch" maxlength="10">
                                        <div
                                            *ngIf="addJobseekerForm.controls['RadiusSearch'].hasError('required') && addJobseekerForm.controls['RadiusSearch'].dirty">
                                            <span class="error">*please enter radius search.</span>
                                        </div>
                                    </span>

                                </div>


                                <div class="form-group row">

                                    <label class="col-md-5 bold">Country


                                    </label>
                                    <span class="col-md-1">:</span>

                                    <span class="col-md-6">
                                        <select class="form-control form-select" formControlName="country">
                                            <option value="" selected>Select Country</option>
                                            <option value={{name.country}} *ngFor="let name of country.countries">
                                                {{name.country}}</option>
                                        </select>
                                        <div
                                            *ngIf="addJobseekerForm.controls['country'].hasError('required') && addJobseekerForm.controls['country'].dirty">
                                            <span class="error">*please select country.</span>
                                        </div>
                                    </span>

                                </div>


                            </div>

                            <div style="text-align: center ;margin-top:20px">
                                <button class="btn  btn-button" type="button" (click)="back()"
                                    style="font-size: 12px;">Back</button>
                                <button class="btn  btn-button" type="button" [disabled]="addJobseekerForm['invalid']"
                                    (click)="updateJob()" style="font-size: 12px;">Update</button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </form>
        <!-- Table Responsive End -->
    </div>
</main>