import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
@Component({
  selector: 'app-view-hirer',
  templateUrl: './view-hirer.component.html',
  styleUrls: ['./view-hirer.component.scss']
})
export class ViewHirerComponent implements OnInit {


individual:boolean= true
company:boolean=false
activeTab :any
id:any
hirerView: any;
  type: any;
  tab: string;
constructor(public service:CommonService, private router:Router,private activatedroute : ActivatedRoute) { 
  this.activatedroute.queryParams.subscribe((res) => {
    this.id = res.id;
    this.type=res.type;
    console.log(res);

  })
}

ngOnInit(): void {
  this.getViewHirer()
}

//----- view individual data of hirer ----//
getViewHirer(){
let url = "api/v1/admin/viewHirer?id="+this.id
this.service.showSpinner()
this.service.getApi(url,1).subscribe((res)=>{
  if(res['statusCode'] == 200){
    this.hirerView = res['result']
    this.service.hideSpinner()
    this.service.successToast(res['responseMessage'])
  }
  else{
    this.service.hideSpinner()
    this.service.errorToast(res['responseMessage'])
  }
})
}

back(){
 localStorage.setItem("tab","Hirer") 
  this.router.navigate(['/company-management'])

}
}
