import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
declare var $
@Component({
  selector: 'app-logo-list',
  templateUrl: './logo-list.component.html',
  styleUrls: ['./logo-list.component.scss']
})
export class LogoListComponent implements OnInit {

 
  logoList=[]
  itemsPerPage=10
  currentPage=1
  totalItems:any
  total: any;
  id: any;
  status: any;
  isActive : any = []

  
  constructor( private router: Router, public service: CommonService) { }

  ngOnInit(): void {
    this.getLogoList();
  }
  pagination(event) {
    this.currentPage = event
    
  }

  //-------get list of logo data------//
  getLogoList() {
    let i=0
    let url = "api/v1/admin/getLogoList?page="+this.currentPage+"&limit="+this.itemsPerPage
    this.service.showSpinner()
    this.service.getApi(url,1).subscribe((res)=>{
      if(res["statusCode"] == 200){
        this.logoList = res["result"]['rows']
        
        for (let item of res["result"]['rows']) {
          
          if (item.status == "ACTIVE") {
            this.isActive[i] = true
          } 
          if (item.status == "BLOCK")
          {
            this.isActive[i] = false
          }
         i++
        }
        this.total=res["result"]['count']
        this.service.hideSpinner()
        this.service.successToast(res["responseMessage"])
      }
      else{
        this.service.hideSpinner()
        this.service.errorToast(res["responseMessage"])
      }
    },(err)=>{
      this.logoList=[]
      this.total=0
      this.service.hideSpinner()

    })
  }

  //-------View of logo data------//
  viewLogo(logoId) {
    console.log('id', logoId);
    this.router.navigate(['/view-logo'], { queryParams: { logoId: logoId } })
  }

  //-------delete of logo data------//
  openDeleteBlogModal(logoId) {
    this.id = logoId
  }
  deleteLogo() {
    let url = "api/v1/admin/deleteLogo?id="+this.id
    this.service.showSpinner();
    this.service.deleteApi(url, {}, 1).subscribe((res: any) => {
      console.log("delete response ==>", res)
      if (res["statusCode"] == 200) {
        this.getLogoList()
        this.service.successToast(res["responseMessage"]);
      } else {
        this.service.hideSpinner();
        this.service.errorToast(res["responseMessage"])
      }
    })
  }
  
  //------ Active and block advertisement-----//
  changeBannerStatus(status,id){
    let advertisementStatus = status
    if(status){
      advertisementStatus = "ACTIVE"
      this.getActive(advertisementStatus,id)
    }
    else{
      advertisementStatus = "BLOCK"
      this. getBlock(advertisementStatus,id)
    }
  }
  getActive(advertisementStatus,id){
    let url = "api/v1/admin/blockUnblockLogo?id="+id
      const data = {
         "id": id,
          "status" : advertisementStatus,   
      }
      this.service.putApi(url,data,1).subscribe((res)=>{
        if (res["statusCode"] ==200) {
          this.service.hideSpinner()
          this.service.successToast(res["responseMessage"])
        } else {
          this.service.hideSpinner()
          this.service.errorToast(res["responseMessage"])
        }
      })
    }
    
    getBlock(advertisementStatus,id){
        let url = "api/v1/admin/blockUnblockLogo?id="+id
        const data = {
          "id": id,
          "status" : advertisementStatus,  
        }
        this.service.putApi(url,data,1).subscribe((res)=>{
          if (res["statusCode"]==200) {
            this.service.hideSpinner()
            this.service.successToast(res["responseMessage"])
          } else {
            this.service.hideSpinner()
            this.service.errorToast(res["responseMessage"])
          }
        })
      } 
  
}


