import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-view-faq',
  templateUrl: './view-faq.component.html',
  styleUrls: ['./view-faq.component.scss']
})
export class ViewFaqComponent implements OnInit {

  faqId: any; 
  faqView : any   
  constructor(private router : Router,public service: CommonService,private activatedroute : ActivatedRoute ) { 
    this.activatedroute.queryParams.subscribe((res) => {
      this.faqId = res.id;
    })
  }

  ngOnInit(): void {
    this.getFaq()
  }

  //----- get individual faq data ----//
  getFaq(){
    let url = "api/v1/static/viewfaq/"+this.faqId
    this.service.showSpinner()
    this.service.getApi(url,1).subscribe((res)=>{
      
      if(res['statusCode'] == 200){
        this.faqView = res['result']
        this.service.hideSpinner()
        this.service.successToast(res['responseMessage'])
      }
      else{
        this.service.hideSpinner()
        this.service.errorToast(res['responseMessage'])
      }
    })
    }

}
