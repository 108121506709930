<main class="middle-content">
<!----page title start---->        
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">Help Us</h1>
    </div>
<!----page title end---->        
<!----table responsive start---->        
    <div class="content-section">
      <div class="order-view max-WT setting-page">
        <form [formGroup]="editForm">
          <div class="mb40">
            <div class="input-style mb20 ">

              <div class="form-group row  view-label-align">
                <label class="col-md-3 bold ">Name
                  </label>
                  <span  class="col-md-1">:</span>

                <div class="col-md-3">
                  <input type="text" class="form-control" maxlength="100" formControlName="name" disabled>
                  
                </div>
              </div>

              <div class="form-group row  view-label-align">
                <label class="col-md-3 bold ">Email
                  </label>
                  <span  class="col-md-1">:</span>

                <div class="col-md-3">
                  <input type="text" class="form-control" maxlength="100" formControlName="email" disabled>
                  
                </div>
              </div>

              <div class="form-group row  view-label-align">
                <label class="col-md-3 bold ">Subject
                  </label>
                  <span  class="col-md-1">:</span>

                <div class="col-md-3">
                  <input type="text" class="form-control" maxlength="100" formControlName="subject" disabled>
                  
                </div>
              </div>


              <div class="form-group row align-items-baseline view-label-align">
                <label class="col-md-3 bold ">Reply
                 </label>
                  <span  class="col-md-1">:</span>

                <div class="col-md-7">
                  <textarea class="form-control" formControlName="reply" name="" maxlength="520" id="" cols="30" rows="5"
                    (keypress)="mainService.preventSpace($event)"></textarea>


                  <div *ngIf="editForm.get('reply').invalid && (editForm.get('reply').dirty || editForm.get('reply').touched)"
                    class="text-danger">

                    <span *ngIf="editForm.get('reply').hasError('required')"> *Please enter reply.</span>
                  </div>


                </div>
              </div>
            </div>
           

            <div style="text-align: center ;margin-top:20px">
              <button class="btn  btn-button" type="button" routerLink="/faq-list">Back</button>
              <button class="btn  btn-button" type="button" [disabled]="!updateButton"
              (click)="updateReply()">Reply</button>

          </div>
            
          </div>
        </form>
      </div>
    </div>
<!----table responsive End---->        
  </main>


