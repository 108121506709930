<body class="afterlogin">
    <div class="login-wrapper">
        <div class="container-common">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <form class="login_box_outer" [formGroup]="resetPasswordForm">
                        <div class="login-box max-WT-520">
                            <div class="login-right-block">
                                <div class="login-heading">
                                    <h4>RESET PASSWORD</h4>
                                </div>
                                <div class="login-box-body">

                                    <div class="form-group">
                                        <input type="password" class="form-control" placeholder="New Password"
                                            formControlName="password" maxlength="60" />
                                        <div
                                            *ngIf="resetPasswordForm.controls['password'].hasError('required') && resetPasswordForm.controls['password'].touched">
                                            <span class="error">*New password is required.</span>
                                        </div>
                                        <div
                                            *ngIf="resetPasswordForm.get('password').hasError('pattern') && (resetPasswordForm.get('password').dirty)">
                                            <span class="error"> Password should have minimum 8 characters, at least 1
                                                uppercase letter, 1 lowercase letter, 1 number and 1 special
                                                character.</span>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <input type="password" class="form-control" placeholder="Confirm New Password"
                                            formControlName="confirmPassword" maxlength="60" />
                                        <span
                                            *ngIf="resetPasswordForm.controls['confirmPassword'].hasError('required') && resetPasswordForm.controls['confirmPassword'].touched"
                                            class="error">*Confirm new password is required.</span>
                                        <span class="error"
                                            *ngIf="resetPasswordForm.controls['confirmPassword'].value && (resetPasswordForm.controls['password'].value != resetPasswordForm.controls['confirmPassword'].value && resetPasswordForm.controls['confirmPassword'].dirty)">Confirm
                                            password should be same as new password.</span>
                                    </div>

                                    <div class="text-center form-group mt40">
                                        <button type="submit" class="btn btn-login btn-large  width100 font-100"
                                            [disabled]="!resetPasswordForm.valid || (resetPasswordForm.controls['password'].value != resetPasswordForm.controls['confirmPassword'].value)"
                                            (click)="resetPasswordFunc()">SUBMIT</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>