<main class="middle-content">
    <!---Page Title Start-->
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">
            USER INFORMATION</h1>
    </div>
    <!-- Page Title End -->

    <!-- Table Responsive Start -->
    <div class="content-section">
        <form>
            <div class="outer-box">
                <div class="custom_tabs common-tabs">
                    <div class="row user_info">

                        <!-- <div class="myAlign"> -->
                        <div class="col-md-4">
                            <div class="form-group row ">
                                <label class="col-md-5 bold">First Name</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">{{jobSeekerlist?.firstName ||'--'}}
                                    </span>

                            </div>
                            <div class="form-group row">
                                <label class="col-md-5 bold">Last Name
    
                                    </label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">{{jobSeekerlist?.lastName ||'--'}}
    
                                    </span>

                            </div>

                            <div class="form-group row">
                                <label class="col-md-5 bold">Email
                                    </label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">{{jobSeekerlist?.email ||'--'}}</span>

                            </div>
                            <div class="form-group row">

                                <label class="col-md-5 bold">Mobile Number
    
                                    </label>
                                <span class="col-md-1">:</span>

                                <span class="col-md-6">{{(jobSeekerlist?.mobileNumber | mask: '(000) 000 0000')||'--'}}</span>

                            </div>
                        </div>

                        <div class="col-md-4">

                            <div class="form-group row">

                                <label class="col-md-5 bold">Job Field
                                    </label>
                                <span class="col-md-1">:</span>

                                <span class="col-md-6">{{jobSeekerlist?.jobField ||'--'}}</span>

                            </div>

                            <div class="form-group row">

                                <label class="col-md-5 bold">Year Of Experience
                                    </label>
                                <span class="col-md-1">:</span>

                                <span class="col-md-6">{{jobSeekerlist?.yearsOfExperience ||'--'}}</span>

                            </div>
                            <div class="form-group row">

                                <label class="col-md-5 bold">Created At
                                    </label>
                                <span class="col-md-1">:</span>

                                <span class="col-md-6">{{jobSeekerlist?.createdAt | date : 'MM/dd/yyyy, hh:mm a'||'--'}}</span>

                            </div>
                            <div class="form-group row">

                                <label class="col-md-5 bold">Status
                                    </label>
                                <span class="col-md-1">:</span>

                                <span class="col-md-6">{{jobSeekerlist?.status ||'--'}}</span>

                            </div>
                        </div>

                        <div class="col-md-4">

                            <div class="form-group row">

                                <label class="col-md-5 bold">Account Verify
    
                                    </label>
                                <span class="col-md-1">:</span>

                                <span class="col-md-6" *ngIf="jobSeekerlist?.accountVerify==true">Verified</span>
                                <span class="col-md-6" *ngIf="jobSeekerlist?.accountVerify==false">Unverified</span>

                            </div>
                            <div class="form-group row">

                                <label class="col-md-5 bold">Profile Completed
                                    </label>
                                <span class="col-md-1">:</span>

                                <span class="col-md-6" *ngIf="jobSeekerlist?.profileComplete==true">Completed</span>
                                <span class="col-md-6" *ngIf="jobSeekerlist?.profileComplete==false">Pending</span>
                            </div>

                            <div class="form-group row">

                                <label class="col-md-5 bold">Zip Code
                                    </label>
                                <span class="col-md-1">:</span>

                                <span class="col-md-6">{{jobSeekerlist?.zipCode ||'--'}}</span>

                            </div>
                            <div class="form-group row">

                                <label class="col-md-5 bold">Expertise
                                    </label>
                                <span class="col-md-1">:</span>

                                <span class="col-md-6">{{jobSeekerlist?.expertise ||'--'}}</span>

                            </div>
                        </div>



                        <!-- </div> -->
                        <!-- <div style="text-align: center; position: relative; left:40px;">
                            <button class="btn btn-button" type="button" (click)="back()" style="margin-bottom: 40px;">Back</button>
                        </div> -->
                    </div>
                </div>
            </div>
        </form>
        <!-- </div> -->
        <!-- <div class="head_flt_select" style="min-width: 125px;">
            <div class="head_flt_select align-items-center d-flex h-150">
                <select [(ngModel)]="status" (change)="statusUser($event.target.value)" class="form-control">
                    <option value="" disabled>Status</option>
                    <option value="ACTIVE">Verified</option>
                    <option value="UNVERIFIED">Unverified</option>
                    <option value="BLOCK">Blocked</option>
                </select>
            </div>
        </div> -->
        <div class="page_title_block">
            <h1 class="page_title details_of_job_his" style="color:black">
                USER JOB HISTORY</h1>
        </div>
        <form [formGroup]="searchForm">

            <div class="tab-pane1">

                <div class="sec_head_new sec_head_new_after">
                    <span class="d-flex align-items-center bold" style="position: absolute;">Job Status</span>
                    <div class="row align-items-center">
                        <div class="head_flt_select" style="min-width: 125px;">

                            <div class="head_flt_select align-items-center d-flex h-150">
                                <select formControlName="applyStatus" class="form-control">
                    <option value="" disabled>Status</option>
                    <option value="PENDING">PENDING</option>
                    <option value="HIRED">HIRED</option>
                    <option value="DENIED">DENIED</option>
                </select>
                            </div>
                        </div>

                        <div class="col-lg-2 col-md-4 pr-0">
                            <div class="filter_search mb20 width100">
                                <span class="d-flex align-items-center bold">From Date:</span>
                                <mat-form-field appearance="outline">
                                    <input matInput [matDatepicker]="picker" placeholder="mm/dd/yyyy" autocomplete="off" readonly formControlName="fromDate" [max]="service.today()">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>

                            </div>
                        </div>
                        <div class="col-lg-2 col-md-4 pr-0">
                            <div class="filter_search mb20 width100">
                                <span class="d-flex align-items-center bold">To Date:</span>
                                <mat-form-field appearance="outline">
                                    <input matInput [matDatepicker]="picker1" placeholder="mm/dd/yyyy" formControlName="toDate" autocomplete="off" [disabled]="!searchForm.value.fromDate" [min]="searchForm.value.fromDate" readonly>
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>


                        <div class="col-md-8 col-lg-4 user-pl" style="margin-bottom:20px;">
                            <div class="user-frmbtn" style="display: flex;">
                                <button type="submit" (click)="search()" class="btn  btn-button ">Search</button>
                                <button type="submit" (click)="reset()" class="btn  btn-button ml-2">Reset</button>
                                <button type="submit" (click)="exportAsXLSX()" class="btn  btn-button mr-2 col-md-6 col-lg-6" style="margin-left: 2%; font-size: 4px;">Export Excel</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div class="table-responsive">
            <table class="table table-bordered" aria-describedby="trade table">
                <thead>
                    <tr class="no_wrap_th success">
                        <th>S.No.</th>
                        <th id="">Job ID</th>
                        <th id="">Company Name</th>

                        <th id="">Job Type</th>
                        <th id="">Equipment Type</th>
                        <th id="">Job Status</th>
                        <th id="">Application Date</th>


                        <!-- <th id="">Action</th> -->

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of jobSeekerDetails | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: jobListCount} ;let i = index">
                        <!-- <tr> -->
                        <!--
                             <td class="content">{{itemsPerPage * (currentPage - 1) + i+1}}</td>
                    <td class="content">{{data?.email||'--'}}</td>
                    <td class="content">{{(data?.mobileNumber | mask: '(000) 000 0000')||'--'}}</td>
                    <td class="content">{{data?.status||'--'}}</td>
                    <td class="content">{{data?.createdAt| date : 'MM/dd/yyyy, hh:mm a'||'--'}}</td> -->
                        <td class="content">{{itemsPerPage * (currentPage - 1) + i+1}}</td>
                        <td class="content">{{data?.id}}</td>
                        <td class="content">{{data?.jobDetails?.hirerDetails?.companyName}}</td>
                        <td class="content">{{data?.jobDetails?.jobType?.categoryName}}</td>
                        <td class="content">{{data?.jobDetails?.subJobType?.subCatName}}</td>
                        <td class="content">{{data?.applyStatus}}</td>
                        <td class="content">{{data?.createdAt | date : 'MM/dd/yyyy, hh:mm a'||'--'}}</td>
                        <!-- <td class="content">{{data?.}}</td> -->



                        <!-- <td class="action_td_btn3" style="cursor: pointer">

                        <a><em class="fa fa-eye" (click)="viewThirdParty(data?.id)" ></em></a>

                        <a *ngIf="data?.status == 'ACTIVE'" data-toggle="modal" data-target="#BlockModal" (click)="getApplicantId(data?.id,'BLOCK')" title="Active">
                            <em class="fa fa-ban" aria-hidden="true" style="color: green;"></em>
                        </a>
                        <a *ngIf="data?.status == 'BLOCK'" data-toggle="modal" data-target="#BlockModal" (click)="getApplicantId(data?.id,'ACTIVE')" title="Block">
                            <em class="fa fa-ban" aria-hidden="true" style="color: red;"></em>
                        </a>
                        <a><em class="fa fa-trash" (click)="deleteModal(data?.id)"  data-toggle="modal" data-target="#deleteModal"></em></a>
                    </td> -->
                    </tr>
                    <tr *ngIf="jobListCount==0">
                        <!-- <tr> -->
                        <td colspan="11" vertical-align="middle">
                            <div class="no-record">
                                <div class="no-recordin text-center">
                                    <h5>No record found</h5>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!-- Table Responsive End -->
    <div style="text-align: center;">
        <button class="btn btn-button" type="button" (click)="back()" style="margin-bottom: 40px;">Back</button>
    </div>
</main>