<main class="middle-content">
<!-- Page Title Start -->
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">View Help</h1>
    </div>
 <!-- Page Title End -->

 <!----table responsive start---->        
    <div class="content-section">
        <form [formGroup]="searchForm">
            <div class="outer-box">
                <div class="custom_tabs common-tabs">
                    <div class="tab-content card" style="padding: 7%; max-width: 850px; margin: auto;">
                     
                        <div class="myAlign" style="width: min-width">
                            <div class="form-group row " >
                                <label class="col-md-4 bold">Name</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-7">{{helpData?.name||'--'}}</span>
                                
                            </div>
                            <div class="form-group row">
                                <label class="col-md-4 bold">Email</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-7">{{helpData?.email||'--'}}</span>
                              
                            </div>
                          
                            <div class="form-group row">
                                <label class="col-md-4 bold">Subject
    
                                </label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-7">{{helpData?.subject||'--'}}</span>
                                
                            </div>


                            <div class="form-group row">
                                <label class="col-md-4 bold">Description
    
                                </label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-7" style="word-break: break-word;max-width: 54.333333%;">{{helpData?.description||'--'}}</span>
                                
                            </div>

                            <div class="form-group row">
                                <label class="col-md-4 bold">Reply
    
                                </label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-7" style="word-break: break-word;  max-width: 51.333333%;">{{helpData?.reply||'--'}}</span>
                                
                            </div>
                        </div>
                        <div style="text-align: center;  position: relative; right: 50px;">
                            <button class="btn  btn-button" type="button" (click)="replyBack(helpData?.id)" data-target="#replyModal" data-toggle="modal">Reply</button>
                            <button class="btn  btn-button ml-4" type="button"  routerLink="/help-list">Back</button>
                            
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <!-- Table Responsive End -->
    </div>
</main>

<!----modal for reply start---->
<div class="modal fade global-modal reset-modal" id="replyModal">
    <div class="modal-dialog max-WT-500">
        <form [formGroup]="searchForm">
            <div class="modal-content" style=" height: 385px;  width: 582px;">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h3 class="modal-title d-inline-block">Reply</h3>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                           

                    <div class="form-group row align-items-baseline view-label-align">
                        
        
                        <div class="col-md-12">
                          <textarea class="form-control" formControlName="reply" name="" maxlength="520" id="" cols="50" rows="8"
                            (keypress)="service.preventSpace($event)"></textarea>
                            <div *ngIf="searchForm.get('reply').invalid && (searchForm.get('reply').dirty || searchForm.get('reply').touched)"
                            class="text-danger">
        
                            <span *ngIf="searchForm.get('reply').hasError('required')" style="float: left;"> *Please enter message.</span>
                           
                          </div>
                        </div>
                    
                            
                    
                        
                          <div class="modal-btn-box " style="margin: auto;">
                            <button type="submit" class="btn btn-button"  (click)="replyHelp()" style="margin-top: 20px;" [disabled]="searchForm.invalid"  data-dismiss="modal">Submit</button>
                            <button type="button" class="btn btn-button  ml-2"  style="margin-top: 20px;" data-dismiss="modal">Cancel</button>
                        </div> 
                          
                    </div>
                    
                    </div>
                </div>
                    
                </div>
            </div>
        </form>
    </div>
</div>
<!----modal for reply End---->
