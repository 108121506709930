import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-help-list',
  templateUrl: './help-list.component.html',
  styleUrls: ['./help-list.component.scss']
})
export class HelpListComponent implements OnInit {

  total:any
  itemsPerPage: number = 10;
  currentPage: number = 1
  totalItems: number;
  id: any;
  searchForm:FormGroup
  flip: boolean=false;
  isSearched: boolean=false;
  name: string;
  resetPage: boolean=false;
  helplist: any;
  status: any;
  constructor(private router: Router, public service: CommonService) { }

  ngOnInit() {
    this. searchFormValidation()
   this.getHelpList()
  }
 
  //------validation-----//
  searchFormValidation() {
    this.searchForm = new FormGroup({
      name: new FormControl(''),
      fromDate: new FormControl(""),
      toDate: new FormControl(""),
    });
  }
  
  //------get list of help-----// 
 getHelpList(){
  let url="api/v1/admin/helpList?page="+this.currentPage+"&limit="+this.itemsPerPage
  this.service.showSpinner();
  this.service.getApi(url,1).subscribe((res) => {
    this.helplist = res['result']['rows']
    this.service.hideSpinner();
    if (res['statusCode'] == 200) {
      this.helplist = res['result']['rows']
      this.total = res['result']['count']
      this.service.successToast(res["responseMessage"])
    } else {
     this.service.hideSpinner()
     this.service.errorToast(res["responseMessage"])
    }
  }, err => {
    this.helplist = []
    this.total = 0
    this.service.hideSpinner();
  })
 }

 
  //------search filter for help-----// 
search() {  
  if(!this.searchForm.value.name && !this.searchForm.value.fromDate && !this.searchForm.value.toDate){
    return
  }
  if(this.currentPage != 1 && !this.isSearched){
    this.currentPage = 1
  }
  this.isSearched = true
  this.flip =  true  
  
  const fromDate = new Date(this.searchForm.value.fromDate)
  const toDate = new Date(this.searchForm.value.toDate)
  let url=""
  if(this.searchForm.value.name && !this.searchForm.value.fromDate && !this.searchForm.value.toDate){
   url="api/v1/admin/helpList?name="+this.searchForm.value.name+"&page="+this.currentPage+"&limit="+this.itemsPerPage
   
  }
  else if(!this.searchForm.value.name && this.searchForm.value.fromDate && !this.searchForm.value.toDate){
    url = "api/v1/admin/helpList?page="+this.currentPage+"&pageSize="+this.itemsPerPage+"&startDate="+fromDate
   
  }
  else if(!this.searchForm.value.name && this.searchForm.value.fromDate && this.searchForm.value.toDate){
    url = "api/v1/admin/helpList?page="+this.currentPage+"&pageSize="+this.itemsPerPage+"&startDate="+fromDate+"&endDate="+toDate
   
  }
  else if(this.searchForm.value.name && this.searchForm.value.fromDate && !this.searchForm.value.toDate){
    url = "api/v1/admin/helpList?name="+this.searchForm.value.name+"&page="+this.currentPage+"&pageSize="+this.itemsPerPage+"&startDate="+fromDate
   
  }
  else{
    url = "api/v1/admin/helpList?name="+this.searchForm.value.name+"&page="+this.currentPage+"&pageSize="+this.itemsPerPage+"startDate="+fromDate+"&endDate="+toDate
  
  }
 this.service.showSpinner();
  this.service.getApi(url, 1).subscribe(
    (res) => {
      if (res['statusCode'] == 200) {
        this.helplist = res['result']['rows']
        this.total = res['result']['count']
        this.service.hideSpinner();
        this.service.successToast(res['responseMessage']);
      } 
      else {
        this.service.hideSpinner(); 
        this.service.errorToast(res['responseMessage']);
      }
    },
    (err) => {
      this.helplist=[]
      this.total=0
      this.service.hideSpinner();
    }
  );
}

viewHelp(id){
  this.router.navigate(['/view-help'], { queryParams: { id : id } })
}

//-----------------reset help ---------------//
reset() {
  if(!this.isSearched){
    return
  }
this.flip = false
this.searchForm.value.email=""
this.isSearched = false
this.getHelpList()
this.searchForm.reset()

}

changeStat(){
  this.isSearched = false
 }

//-----------------pagination ---------------//
pagination(page) {
  this.currentPage = page;
  this.getHelpList()
}  
}