import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-view-job-seeker',
  templateUrl: './view-job-seeker.component.html',
  styleUrls: ['./view-job-seeker.component.scss']
})
export class ViewJobSeekerComponent implements OnInit {
  jobSeekerlist: any;
  id: string;
  jobSeekerDetails:any
  jobListCount: any
  itemsPerPage: number = 10;
  currentPage: number = 1
  totalItems: number;
  searchForm:FormGroup
  constructor(public service:CommonService, private router:Router,private activated : ActivatedRoute,private datepipe : DatePipe) {
    this.activated.queryParams.subscribe((res)=>{
      this.id = res.id
      console.log(this.id)
    })
   }

  ngOnInit(): void {
    this.searchFormValidation()
    this.getViewJobseeker()
  }
back(){
  this.router.navigate(['/job-seeker-management'])
}

//-----validation-------//
searchFormValidation() {
  this.searchForm = new FormGroup({
    applyStatus: new FormControl(''),
    fromDate: new FormControl(""),
    toDate: new FormControl(""),
  });
}
//-------view individual data of job seeker--------//
getViewJobseeker(){
  let url = "api/v1/admin/findJobsOfUser?id="+this.id+"&page="+this.currentPage+"&limit="+this.itemsPerPage

  this.service.showSpinner()
  this.service.postApi(url,{},1).subscribe((res)=>{
    if(res['statusCode']== 200){
      this.jobSeekerDetails = res['result']['joblist']['rows']
      this.jobSeekerlist = res['result']['user']
      this.jobListCount = res['result']['joblist']['count']
     this.service.successToast(res['responseMessage'])

     this.service.hideSpinner()
    }else{
     this.service.errorToast(res['responseMessage'])
     this.service.hideSpinner()
    }
  }, err => {
    this.jobSeekerDetails = []
    this.totalItems = 0
    this.service.hideSpinner();
   
  })
}


// -------view individual data of job seeker--------
search(){
  
  let url = "api/v1/admin/findJobsOfUser?id="+this.id+"&applyStatus="+this.searchForm.value.applyStatus+"&page="+this.currentPage+"&limit="+this.itemsPerPage+"&startDate="+this.searchForm.value.fromDate+"&endDate="+this.searchForm.value.toDate
  this.service.showSpinner()
  this.service.postApi(url,{},1).subscribe((res)=>{
    if(res['statusCode']== 200){
      this.jobSeekerDetails = res['result']['joblist']['rows']
      this.jobSeekerlist = res['result']['user']
      this.jobListCount = res['result']['joblist']['count']
     this.service.successToast(res['responseMessage'])

     this.service.hideSpinner()
    }else{
     this.service.errorToast(res['responseMessage'])
     this.service.hideSpinner()
    }
  }, err => {
    this.jobSeekerDetails = []
    this.totalItems = 0
    this.service.hideSpinner();
   
  })
}

reset(){
  this.searchForm.value.applyStatus = '',
  this.searchForm.value.fromDate = '',
  this.searchForm.value.toDate = ''
  this.searchForm.reset()
  this.getViewJobseeker()
}
//------------------ export as excel for download file --------------//
exportAsXLSX() { 
  let dataArr = [];
  if(this.jobSeekerDetails.length == 0){
    let obj  = {}
    obj = {
      "S.No.": 0,
      "Job ID":  'N/A',
      "Company Name":  'N/A',
      "Job Type": 'N/A',
      "Equipment Type": 'N/A',
      "Job Status" : 'N/A',
      "Application Date" : 'N/A'
    }
    dataArr.push(obj)
    this.service.exportAsExcelFile(dataArr, 'User Management');
    return
  }
  this.jobSeekerDetails.forEach((element, ind) => {
    let obj = {};
    obj = {
      "S.No.": ind + 1,
      "Job ID":  element.applicantId ? element.applicantId : 'N/A',
      "Company Name":  element.jobDetails?.hirerDetails?.companyName ? element.jobDetails?.hirerDetails?.companyName : 'N/A',
      "Job Type": element.jobDetails?.jobType?.categoryName ? element.jobDetails?.jobType?.categoryName : 'N/A',
      "Equipment Type":element.jobDetails?.subJobType?.subCatName ? element.jobDetails?.subJobType?.subCatName : 'N/A',
      "Job Status" : element.applyStatus ? element.applyStatus : 'N/A',
      "Application Date" : element.createdAt ?this.datepipe.transform(element.createdAt ,'MM/dd/yyyy, hh:mm a')  : 'N/A',
      
    }
    dataArr.push(obj)
  })
  this.service.exportAsExcelFile(dataArr, 'User Management');
}
}
