import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-edit-static-content',
  templateUrl: './edit-static-content.component.html',
  styleUrls: ['./edit-static-content.component.scss']
})
export class EditStaticContentComponent implements OnInit {
  id: any;
  type: any;
  form: FormGroup;
  public editorValue: string = '';
  staticData: any=[];
  pageName: any=[];

  constructor(private activatedroute: ActivatedRoute,public service: CommonService,private router:Router) {
    this.activatedroute.queryParams.subscribe((res)=>{
      this.id = res.id;
      this.type = res.type
      if(this.type=="terms Conditions")
      {
        this.type="terms & Conditions"
      }
    });
   }

  ngOnInit(): void {
    this.form = new FormGroup({
      "editorValue": new FormControl('', (Validators.required)),
       "Title": new FormControl('', (Validators.required))
    });
    this.viewStaticData()
  }
  
  //---------view static content data ----------//
  viewStaticData(){ 
    let url = "api/v1/static/viewStaticContent?id="+this.id
    console.log(url)
    this.service.showSpinner()
    this.service.getApi(url , 1).subscribe((res)=>{
      if(res['statusCode']== 200){
        this.staticData = res['result']
         this.editorValue = res['result']['description']
        this.service.hideSpinner()
        this.service.successToast(res['responseMessage'])
      }
       else{
         this.service.hideSpinner()
        this.service.errorToast(res['responseMessage'])
       }

    })
  }


  //---------Update static content data ----------//
  updateStatic(){
    let title = this.type
    title = this.type.replaceAll(" ","_")
    let url = "api/v1/static/editStaticContent?id="+ this.id
    const data = {
      "description": this.editorValue,
       "title": title
    }
    this.service.showSpinner()
     this.service.putApi(url ,data, 1).subscribe((res)=>{
       if(res['statusCode'] == 200){
 
         this.service.hideSpinner()
         this.service.successToast(res['responseMessage'])
         this.viewStaticData()
         this.router.navigate(['/static-content'])

       }
       else{
          this.service.hideSpinner()
         this.service.errorToast(res['responseMessage'])
       }
 
     })
  }
}
