<!-- <p>user-list works! {{item}}</p> -->
<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title">Social Network</h1>
        <!-- <button class="btn btn-gray ml-1 bth" style="padding: 2px 10px;" routerLink="/add-social-network">Add Social Network</button> -->
    </div>

    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="table-responsive" style="margin-bottom: 30px;">
                        <table class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap" aria-describedby="user management list">
                            <thead class="thead">
                                <tr class="no_wrap_th text-center">
                                    <th scope="col" class="Sorting-img heading1">S.No</th>
                                    <th scope="col" class="Sorting-img">Social Name</th>
                                    <th scope="col" class="Sorting-img">Social Link</th>
                                    <th scope="col" class="action_td_btn3 Sorting-img heading2">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="text-center" *ngFor="let item of userData ;let i = index">
                                    <td>{{i+1}}</td>
                                    <td class="content">{{(item.socialName | titlecase )||'--'}}</td>
                                    <td class="content" style="padding: 0px !important;">
                                        <span *ngIf="!isSocialLink[i]">{{item.socialLink }}</span>
                                        <input *ngIf="isSocialLink[i]" [(ngModel)]="socialLink[i]" [ngModelOptions]="{standalone : true}" type="text" class="form-control text-center">
                                    </td>
                                    <td class="justify-content-center d-flex">
                                        <button *ngIf="!isSocialLink[i]" class="btn btn-gray ml-1 bth" style="padding: 2px 10px;" title="edit" (click)="socialLinkEdit(i,socialLink[i],item.socialName ,item?.id)">
                                                <em class="fa fa-edit" aria-hidden="true"></em>
                                             </button>
                                        <button *ngIf="isSocialLink[i]" class="btn btn-gray ml-1 bth" style="padding: 2px 10px;" title="edit" (click)="updateSocialLink(socialLink[i])">
                                                <em class="fa fa-check" aria-hidden="true"></em>
                                             </button>
                                    </td>
                                </tr>
                                <tr class="text-center" *ngIf="!userData.length">
                                    <td colspan="6">No Record Found</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- <div class="custom-pagination mt-2 text-align-end" >
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div> -->
                </div>
            </div>
        </div>
    </div>
</main>