import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { DatePipe } from "@angular/common";

@Component({
  selector: 'app-job-seeker-management',
  templateUrl: './job-seeker-management.component.html',
  styleUrls: ['./job-seeker-management.component.scss']
})
export class JobSeekerManagementComponent implements OnInit {


  itemsPerPage: number = 10;
  currentPage: number = 1
  totalItems: number;
  countryList: any = [];
  jobSeekerData: any;
  total: any;
  searchForm: FormGroup;
  flip : boolean = false
  isSearched : boolean = false
  id: string;
  status: any;
  applicantType: any;

  constructor(private router: Router, public service: CommonService,private datepipe : DatePipe) { }

  ngOnInit() {
  this.searchFormValidation()
   this.getComapnyList()
  }

  searchFormValidation() {
    this.searchForm = new FormGroup({
      email: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl('')
    });
  }

  //---- get Job Seeker list------//
  getComapnyList() {
    let url = "api/v1/admin/listApplicants?page="+this.currentPage+"&limit="+this.itemsPerPage
    this.service.showSpinner()
    this.service.getApi(url, 1).subscribe((res) => {
      if (res['statusCode'] == 200) {
        this.jobSeekerData = res['result']['rows']
        this.total = res['result']['count']
        this.service.hideSpinner()
        this.service.successToast(res['responseMessage'])
      }
      else {
        this.service.hideSpinner()
        this.service.errorToast(res['responseMessage'])
      }

    },(err)=>{
      this.jobSeekerData=[]
      this.total=0
      this.service.hideSpinner()

    })
  }

  viewJobSeeker(id) {
    this.router.navigate(['/view-seeker'], { queryParams: { id : id } })
  }
  
    //---- Search Job Seeker list------//
  search() {
    if(!this.searchForm.value.email && !this.searchForm.value.fromDate && !this.searchForm.value.toDate){
      return
    }
    if(this.currentPage != 1 && !this.isSearched){
      this.currentPage = 1
    }
    this.isSearched = true
    this.flip =  true  
    
    const fromDate = new Date(this.searchForm.value.fromDate)
    const toDate = new Date(this.searchForm.value.toDate)
    let url=""
    if(this.searchForm.value.email && !this.searchForm.value.fromDate && !this.searchForm.value.toDate){
     url="api/v1/admin/listApplicants?email="+this.searchForm.value.email+"&page="+this.currentPage+"&limit="+this.itemsPerPage
     
    }
    else if(!this.searchForm.value.email && this.searchForm.value.fromDate && !this.searchForm.value.toDate){
      url = "api/v1/admin/listApplicants?page="+this.currentPage+"&pageSize="+this.itemsPerPage+"&startDate="+fromDate
     
    }
    else if(!this.searchForm.value.email && this.searchForm.value.fromDate && this.searchForm.value.toDate){
      url = "api/v1/admin/listApplicants?page="+this.currentPage+"&pageSize="+this.itemsPerPage+"&startDate="+fromDate+"&endDate="+toDate
     
    }
    else if(this.searchForm.value.email && this.searchForm.value.fromDate && !this.searchForm.value.toDate){
      url = "api/v1/admin/listApplicants?email="+this.searchForm.value.email+"&page="+this.currentPage+"&pageSize="+this.itemsPerPage+"&startDate="+fromDate
     
    }
    else{
      url = "api/v1/admin/listApplicants?email="+this.searchForm.value.email+"&page="+this.currentPage+"&pageSize="+this.itemsPerPage+"startDate="+fromDate+"&endDate="+toDate
    
    }
   this.service.showSpinner();
    this.service.getApi(url, 1).subscribe(
      (res) => {
        if (res['statusCode'] == 200) {
          this.jobSeekerData = res['result']['rows']
          this.total = res['result']['count']
          this.service.hideSpinner();
          this.service.successToast(res['responseMessage']);
        } 
        else {
          this.service.hideSpinner(); 
          this.service.errorToast(res['responseMessage']);
        }
      },
      (err) => {
        this.jobSeekerData=[]
        this.total=0
        this.service.hideSpinner();
      }
    );
  }
  
  
  //---- Reset Job Seeker list------//
  reset(){
    if(!this.isSearched){
      return
    }
    this.flip = false
    this.searchForm.value.email=""
    this.isSearched = false
    this.getComapnyList() 
    this.searchForm.reset()
  }

  changeStat(){
    this.isSearched = false
   }

  // pagination
  pagination(event) {
    this.currentPage = event;
    if(this.flip){
      this.search()
    }
    else{
      this.getComapnyList() 
    }
  }
 
    //---- delete Job Seeker list------//
    deleteModal(id){
      this.id = id
    }
   deleteJobSeeker(){
    let url = "api/v1/admin/deleteApplicant?id="+this.id
    this.service.showSpinner()
    this.service.deleteApi(url, {}, 1).subscribe((res) => {
      if (res["statusCode"] == 200) {
        this.getComapnyList()
        this.service.hideSpinner();
        this.service.successToast(res["responseMessage"]);
      } else {
        this.service.hideSpinner();
        this.service.errorToast(res["responseMessage"]);
      }
    });
  }


  //---- Export As Excel Job Seeker list------//
  exportAsXLSX() {
   let dataArr = [];
   if(this.jobSeekerData.length == 0){
    let obj  = {}
    obj = {
      "S.No.": 0,
      "Job Seeker Name":  'N/A',
      "Email Address":  'N/A',
      "Contact Number":  'N/A',
      "Applicant Type": 'N/A',
      "Status": 'N/A',
      "Created Date": 'N/A',


    }
    dataArr.push(obj)
    this.service.exportAsExcelFile(dataArr, 'Job Seeker Management');
    return
  }
    this.jobSeekerData.forEach((element, ind) => {
      let obj = {};
      obj = {
        "S.No.": ind + 1,
        "Job Seeker Name" : element.firstName ? element.firstName +" "+ element?.lastName: 'N/A',
        "Email Address": element.email ? element.email : 'N/A',
        "Contact Number": element.mobileNumber ? element.mobileNumber : 'N/A',
        "Applicant Type": element.account_Types?.accountName? element.account_Types?.accountName: 'N/A',
        "Status": element.status ? element.status  : 'N/A',
        "Created Date": element.createdAt ?this.datepipe.transform(element.createdAt ,'MM/dd/yyyy, hh:mm a')  : 'N/A',

      }
      dataArr.push(obj)
    })

    this.service.exportAsExcelFile(dataArr, 'Job Seeker Management');
  }

  //---- Active/Block Job Seeker list------//
  getJobId(id, status) {
    this.id = id;
    this.status = status;
  }
  blockUnblockJob() {
    let url = ""
    if (this.status == "BLOCK") {
      url = "api/v1/admin/blockUnblockApplicant?id="+this.id
    }else{
      url = "api/v1/admin/blockUnblockApplicant?id="+this.id
    }
    this.service.showSpinner()
    this.service.putApi(url, {}, 1).subscribe((res) => {
      if (res["statusCode"] == 200) {
        this.getComapnyList() 
        this.service.hideSpinner();
        this.service.successToast(res["responseMessage"]);
      } else {
        this.service.hideSpinner();
        this.service.errorToast(res["responseMessage"]);
      }
    });
  }
  
}