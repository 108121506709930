import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-add-logo',
  templateUrl: './add-logo.component.html',
  styleUrls: ['./add-logo.component.scss']
})
export class AddLogoComponent implements OnInit {
  addForm: FormGroup
  imageUrl: any;
  imgSrc: string;
  imageuploaded: boolean = false;
  image: string;
  uploadedImage:any
  constructor(private router:Router,public service:CommonService) { }

  ngOnInit(): void {
    this.addFormValidation()
  }
  addFormValidation() {
    this.addForm = new FormGroup({
      title: new FormControl('', [(Validators.required)]),
      image: new FormControl('', (Validators.required)),
     })
  
  }

  //-------- add logo functionality ----- //
  addLogo(){
    let url ="api/v1/admin/addLogo"
    const data ={
      "title":this.addForm.value.title,
      "image": this.imgSrc
    }
    this.service.showSpinner();
    this.service.postApi(url,data, 1).subscribe((res) => {
      if (res["statusCode"] == 200) {
       
        this.service.hideSpinner();
        this.service.successToast(res["responseMessage"]);
        this.router.navigate(['/logo-list'])
      } else {
        this.service.hideSpinner();
        this.service.errorToast(res["responseMessage"]);
      }
    });
  }
 
 //-------- upload image functionality ----- //
  uploadImg(event): void {
    this.imageuploaded = true;
    if (event.target.files && event.target.files[0]) {
                this.uploadProfilePic(event.target.files[0]);
    }
  }

  uploadProfilePic(img) {
    this.imageuploaded = true;
    var form = new FormData()
    form.append('uploaded_file', img)
    this.imgSrc = "assets/img/loaderadvertisement.gif";
    let apiReqUrl = "api/v1/user/uploadFile"
    this.service.uploadImage(apiReqUrl, form, 1).subscribe((res: any) => {
      this.service.showSpinner();
      if (res["statusCode"] == 200) {
        this.imgSrc = res["result"]
        this.uploadedImage=res["result"]
        this.service.hideSpinner();
        this.service.successToast(res["responseMessage"]);
      }
      else {
        this.service.hideSpinner();
        this.service.errorToast(res["responseMessage"]);
      }
    })
  }

  back() {
    this.router.navigate(['/logo-list'])
  }
}
