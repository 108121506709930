import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-add-social-network',
  templateUrl: './add-social-network.component.html',
  styleUrls: ['./add-social-network.component.scss']
})
export class AddSocialNetworkComponent implements OnInit {
  addSocialForm: FormGroup
  constructor(private route: Router, public mainservice: CommonService) { }

  ngOnInit(): void {
    this.addSocialFormvalidation()
  }
  addSocialFormvalidation() {
    this.addSocialForm = new FormGroup({
      socialName:new FormControl("" , Validators.required), 
      socialLink: new FormControl("", Validators.required)
    })
  }
   //------ add category -------// 
   addSocialLink(){
    let url = "api/v1/admin/addSocialLink"
    const data = {
     "socialName": this.addSocialForm.value.socialName,
     "socialLink": this.addSocialForm.value.socialLink
   }
    this.mainservice.showSpinner()
    this.mainservice.postApi(url,data,1).subscribe((res)=>{
      if(res['statusCode'] == 200){
       this.mainservice.hideSpinner()
       this.mainservice.successToast(res["responseMessage"])
       this.route.navigate(['/social-network'])

      }
      else{
       this.mainservice.hideSpinner()
       this.mainservice.errorToast(res["responseMessage"])
      }
    })

   }
}
