import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { CountryJsonService } from '../../service/country-json.service';

@Component({
  selector: 'app-edit-job-seeker',
  templateUrl: './edit-job-seeker.component.html',
  styleUrls: ['./edit-job-seeker.component.scss']
})
export class EditJobSeekerComponent implements OnInit {
  id: any;
  addJobseekerForm : FormGroup
  jobSeekerData: any;
  constructor(public service:CommonService, private router:Router,private activated : ActivatedRoute,public country : CountryJsonService) { 
    this.activated.queryParams.subscribe((res)=>{
      this.id = res.id
      console.log(this.id)
    })
  }

  ngOnInit(): void {
    this.getJobSeeker()
    this.addJobseekerForm = new FormGroup({
      'firstName': new FormControl('', [Validators.required]),
      "lastName": new FormControl('', Validators.required),
      "email": new FormControl('', Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/i)),
      "zipCode": new FormControl('', Validators.required),
     "jobField":new FormControl('', Validators.required),
     "yearsOfExperience":new FormControl('', Validators.required),
     "expertise":new FormControl('', Validators.required),
     "RadiusSearch":new FormControl('', Validators.required),
     "country":new FormControl('', Validators.required),

    });
  }

back(){
  this.router.navigate(['/job-seeker-management'])
}

//------View individual data of job-seeker------//
getJobSeeker(){
  let url ="api/v1/admin/viewApplicant?id="+this.id
  this.service.showSpinner()
  this.service.getApi(url,1).subscribe((res)=>{
    if (res['statusCode'] ==200) {
      this.jobSeekerData=res['result']
      this.addJobseekerForm.patchValue({
        firstName : res['result']['firstName'],
        lastName : res['result']['lastName'],
        email:res['result']['email'],
        zipCode:res['result']['zipCode'],
        jobField:res['result']['jobField'],
        yearsOfExperience:res['result']['yearsOfExperience'],
        expertise:res['result']['expertise'],
        RadiusSearch:res['result']['RadiusSearch'],
       country:res['result']['country']
      })
      this.service.hideSpinner()
      this.service.successToast(res['responseMessage'])
    } else {
      this.service.hideSpinner()
      this.service.errorToast(res['responseMessage'])
    }
  })
}

//------Update Individual Data Of Job-seeker------//
updateJob(){
  let url = "api/v1/admin/editApplicant?id="+this.id
  const data = {
     "firstName"  :this.addJobseekerForm.value.firstName,
    "lastName": this.addJobseekerForm.value.lastName,
    "email": this.addJobseekerForm.value.email,
    "jobField": this.addJobseekerForm.value.jobField,
    "zipCode":this.addJobseekerForm.value.zipCode,
    "yearsOfExperience":this.addJobseekerForm.value.yearsOfExperience,
    "expertise":this.addJobseekerForm.value.expertise,
    "RadiusSearch":this.addJobseekerForm.value.RadiusSearch,
    "country":this.addJobseekerForm.value.country,

  }
  this.service.putApi(url,data,1).subscribe((res)=>{
    if (res['statusCode'] ==200) {
      this.service.hideSpinner()
      this.service.successToast(res['responseMessage'])
      this.router.navigate(['/job-seeker-management'])
    } else {
      this.service.hideSpinner()
      this.service.errorToast(res['responseMessage'])
    }
  })
}
}
