<main class="middle-content">
    <!-----page title start----->
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">Add Advertisement</h1>
    </div>
    <!-----page title end----->
    <!------ table responsive start ----->
    <div class="content-section">
        <form [formGroup]="addForm">
            <div class="outer-box">
                <div class="custom_tabs common-tabs">
                    <div class="tab-content card" style="padding: 2%;  margin: auto;">

                        <div class="myAlign">
                            <div class="form-group row">
                                <label class="col-md-5 bold">Title</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-4">
                                    <input type="text" class="form-control" placeholder="Title" formControlName="title"
                                        maxlength="100">
                                    <div
                                        *ngIf="addForm.controls['title'].hasError('required') && (addForm.controls['title'].touched ||addForm.controls['title'].dirty)">
                                        <span class="error">*Please enter title.</span>
                                    </div>
                                </span>
                            </div>


               
                            <div class="form-group row">
                                <label class="col-md-5 bold">Upload Image</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-6">
                                    <input #file type="file" formControlName="image" accept='image/*'
                                        (change)="uploadImg($event)" />
                                    <div class="row">
                                        <div class="col-md-8 col-lg-8">
                                            <img [src]=" imgSrc[0]" alt=""
                                                [ngClass]="{advertizeImage: (imageuploaded == true)}"
                                                style="width: 100%; margin-top: 10px;" />
                                        </div>
                                        <span class="tool-tip-icon">
                                            <i class="fa fa-question" data-tooltip></i>
                                        </span>
                                    </div>
                                </span>
                            </div>

                            <div class="form-group row">
                                <label class="col-md-5 bold">Start Date</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-4">
                                    <input type="date" class="form-control mb20" formControlName="startDate" [max]="mainService.today()"> 

                                </span>
                            </div>

                            <div class="form-group row">
                                <label class="col-md-5 bold">End Date</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-4">
                                    <input type="date" formControlName="endDate" [readonly] = "!addForm.value.startDate"
                                    [min]="addForm.value.startDate"
                                    class="form-control mb20">  
                                </span>
                            </div>

                            

                            <div class="form-group row">
                                <label class="col-md-5 bold">Location</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-12">
                                    <div id="dvMap" style="width:100%; height:400px; margin: 10px;">
                                    </div>
                                </span>
                            </div>

                            <div class="form-group row">
                                <label class="col-md-5 bold">Latitude</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-4">
                                    <input type="text" class="form-control" placeholder="Latitude"
                                        formControlName="latitude" maxlength="10">
                                </span>

                            </div>

                            <div class="form-group row">
                                <label class="col-md-5 bold">Longitude</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-4">
                                    <input type="text" class="form-control" placeholder="Longitude"
                                        formControlName="longitude" maxlength="10">

                                </span>

                            </div>

                            <div class="form-group row">
                                <label class="col-md-5 bold">Radius</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-4">
                                    <input type="text" class="form-control" placeholder="Radius in miles"
                                        formControlName="radius" maxlength="4"
                                        (keypress)="mainService.restrictSpace($event)">
                                    <div
                                        *ngIf="addForm.controls['radius'].hasError('required') && (addForm.controls['radius'].touched ||addForm.controls['radius'].dirty)">
                                        <span class="error">*Please enter radius in miles.</span>
                                    </div>
                                    <div
                                        *ngIf="addForm.controls['radius'].hasError('pattern') && (addForm.controls['radius'].touched ||addForm.controls['radius'].dirty)">
                                        <span class="error">*Please enter valid radius.</span>
                                    </div>
                                </span>

                            </div>

                            <div class="form-group row">
                                <label class="col-md-5 bold">Description</label>
                                <span class="col-md-1">:</span>
                                <span class="col-md-4">
                                    <textarea class="form-control" formControlName="description" name="" id=""
                                        maxlength="350" cols="30" rows="5"></textarea>

                                    <div
                                        *ngIf="addForm.controls['description'].hasError('required') && (addForm.controls['description'].touched ||addForm.controls['description'].dirty)">
                                        <span class="error">*Please enter description.</span>
                                    </div>
                                </span>
                            </div>


                        </div>
                        <div style="text-align: center ;margin-top:20px ;position: relative;right: 50px;">
                            <button class="btn  btn-button ml-10" type="button" (click)="back()">Back</button>
                            <button class="btn  btn-button ml-4" type="button" [disabled]="addForm.invalid || !uploadedImage"
                                (click)="addAdvertisement()">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <!------ table responsive end ----->
</main>