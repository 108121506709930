import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-view-job-third-party',
  templateUrl: './view-job-third-party.component.html',
  styleUrls: ['./view-job-third-party.component.scss']
})
export class ViewJobThirdPartyComponent implements OnInit {

 

  id:any
  flip: boolean;
  editForm: FormGroup
  hirerView: any;
  activeTab:any ="viewjob"
  total:any
  itemsPerPage: number = 10;
  currentPage: number = 1
  totalItems: number;
  jobId: any;
  isSearched: boolean;
  resetPage: boolean = false;
  dataList: any;
  constructor(public service:CommonService, private router:Router,private activatedroute : ActivatedRoute) { 
    this.activatedroute.queryParams.subscribe((res) => {
      this.id = res.id;
 })
  }

  ngOnInit(): void {
    this.searchFormValidation()
    this.changeTab(this.activeTab)
    if(localStorage.getItem("tab")){
      this.changeTab(localStorage.getItem("tab"))
      localStorage.removeItem("tab")
    }
  }

  searchFormValidation() {
    this.editForm = new FormGroup({
      'applyStatus': new FormControl(''),
    })
  }
  
  //-----------------pagination ---------------//
  pagination(page) {
    this.currentPage = page;
    this.getViewHirer()
  } 

  changeTab(tab){

    this.activeTab='viewjob';
    this.currentPage= 1
    this.activeTab= tab
    this.getViewHirer()
  }
//-----------Individual data of View third party contractor ----------//
getViewHirer(){
  let url=""
  if (this.activeTab == 'viewjob') {
    let url = "api/v1/admin/viewJob?id="+this.id
    this.service.showSpinner()
    this.service.getApi(url,1).subscribe((res)=>{
      if(res['statusCode'] == 200){
        this.hirerView = res['result']
        this.service.hideSpinner()
        this.service.successToast(res['responseMessage'])
      }
      else{
        this.service.hideSpinner()
        this.service.errorToast(res['responseMessage'])
      }
    })
  }
  else if(this.activeTab == '3rd_Party_Contractor'){
     const data = {
        "jobId": this.id,
        "page": this.currentPage,
        "limit": this.itemsPerPage
      }

      url = "api/v1/admin/listJobApplicant"

      this.service.showSpinner();
      this.service.postApi(url, data, 1).subscribe((res) => {
        if (res['statusCode'] == 200) {
          this.dataList = res['result']['rows']
          this.total = res['result']['count']
          this.service.hideSpinner();
          this.service.successToast(res["responseMessage"])
        } else {
          this.service.hideSpinner()
          this.service.errorToast(res["responseMessage"])
        }
      }, err => {
        this.dataList = []
        this.total = 0
        this.service.hideSpinner();

      })
    }
}

 //------search job--------//
 search() {
  if (!this.editForm.value.applyStatus) {
    return
  }
  this.isSearched = true
  this.flip = true
  if (!this.resetPage) {
    this.currentPage = 1
  }

  const data = {
    "jobId": this.id,
    "page": this.currentPage,
    "limit": this.itemsPerPage,
    'applyStatus': this.editForm.value.applyStatus
  }
  let url = "api/v1/admin/listJobApplicant"
  this.service.showSpinner();
  this.service.postApi(url, data, 1).subscribe(
    (res) => {
      if (res['statusCode'] == 200) {
        this.dataList = res['result']['rows']
        this.total = res['result']['count']
        this.service.hideSpinner();
        this.service.successToast(res['responseMessage']);
      }
    },
    (err) => {
      this.dataList = []
      this.total = 0
      this.service.hideSpinner();
    }
  );
}

//-----------------reset job ---------------//
reset() {
  if (!this.isSearched) {
    return
  }
  this.flip = false
  this.isSearched = false
  this.getViewHirer()
  this.editForm.reset()
  this.editForm.patchValue({
    applyStatus : "ALL"
  })

}


 back(){
  localStorage.setItem("tab","3rd_Party_Contractor")
 this.router.navigate(['/job-management'])
}
}
